import { Stack, Typography } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import { graphql, Link as GLink, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import { PRIVATE_HOME } from "../constants/general";

const logoQuery = graphql`
  query {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, height: 41)
      }
    }
  }
`;

type SiteTitleProps = {
  signedIn?: boolean;
};

const SiteTitle: React.FC<SiteTitleProps> = ({ signedIn }) => {
  const { classes } = useStyles();
  const query = useStaticQuery(logoQuery);
  const logo = getImage(query.logo);

  return (
    <GLink
      to={signedIn ? PRIVATE_HOME : "/"}
      className={classes.title}
      aria-label="PlaceHires Home Link"
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <GatsbyImage image={logo} loading="eager" alt="" />
        <Typography display="inline" variant="h5" component="h1" color="primary">
          <span className={classes.firstTitleSpan}>Place</span>Hires
        </Typography>
      </Stack>
    </GLink>
  );
};

const useStyles = makeStyles()((theme) => ({
  title: {
    textDecoration: "none"
  },
  firstTitleSpan: {
    color: theme.palette.text.tertiary
  }
}));

export default SiteTitle;
