import { ArrowForwardIos } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Collapse,
  Container,
  GlobalStyles,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useTheme
} from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import classNames from "classnames";
import { Link as GLink } from "gatsby";
import { Squash as Hamburger } from "hamburger-react";
import React from "react";
import { PUBLIC_LINKS } from "../constants/general";
import { HEADER_COLOR, HEADER_ELEVATION, LAYOUT_PADDING } from "../constants/styles";
import { TOOLBAR_HEIGHT } from "../gatsby-theme-material-ui-top-layout/theme";
import SiteTitle from "./SiteTitle";

const OPTIONS = PUBLIC_LINKS.slice(1);

const DRAWER_OPTIONS = PUBLIC_LINKS.slice(1);

type PublicHeaderProps = {
  color?: string;
  sticky?: boolean;
  hideXl?: boolean;
};

const PublicHeader: React.FC<PublicHeaderProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const { classes } = useStyles();
  const theme = useTheme();

  const signInSignUpButtons = (
    <>
      <Button
        color="primary"
        component={GLink}
        to="/signup"
        variant="outlined"
        className={classes.signUp}
      >
        Sign up
      </Button>
      <Button variant="contained" color="primary" component={GLink} to="/login">
        Sign In
      </Button>
    </>
  );

  const mainHeaderSection = (
    <Toolbar disableGutters>
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          color: "text.primary",
          pl: LAYOUT_PADDING
        }}
      >
        <Box
          sx={{
            [theme.breakpoints.between("sm", "md")]: {
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)"
            }
          }}
        >
          <SiteTitle />
        </Box>
        <Box
          sx={{
            display: {
              md: "flex",
              xs: "none"
            },
            ml: 2,
            alignItems: "center",
            flex: "1 1 0",
            width: "100%"
          }}
        >
          {OPTIONS.map((option) => (
            <Link
              component={GLink}
              to={option.to}
              underline="none"
              variant="h6"
              key={option.to}
              sx={{
                px: 2,
                color: "text.tertiary",
                fontSize: "1rem"
              }}
            >
              {option.name}
            </Link>
          ))}
        </Box>
        <Box
          sx={{
            display: {
              sm: "flex",
              xs: "none"
            },
            order: {
              md: "inherit",
              sm: 3
            },
            alignItems: "center"
          }}
        >
          {signInSignUpButtons}
        </Box>
        <Box
          sx={{
            display: {
              md: "none",
              sm: "block"
            }
          }}
        >
          <Hamburger
            toggled={open}
            toggle={setOpen}
            size={18}
            label="PlaceHires Mobile Navigation Drawer Hamburger"
          />
        </Box>
      </Container>
    </Toolbar>
  );

  return (
    <>
      <GlobalStyles styles={`body { overflow-y: ${open ? "hidden" : "initial"}; }`} />
      <AppBar
        color="transparent"
        position={props.sticky ? "sticky" : "static"}
        className={classNames(classes.appBar, {
          [classes.hideXl]: props.hideXl
        })}
        elevation={HEADER_ELEVATION}
      >
        {mainHeaderSection}
      </AppBar>
      <Collapse in={open} className={classes.drawerWrapper}>
        <div className={classes.drawer}>
          <List className={classes.list} disablePadding>
            {DRAWER_OPTIONS.map((option) => (
              <Link
                component={GLink}
                underline="none"
                color="text.tertiary"
                to={option.to}
                key={option.to}
              >
                <ListItem disableGutters className={classes.listItem}>
                  <ListItemText
                    primary={option.name}
                    primaryTypographyProps={{ variant: "h5", component: "h4" }}
                  />
                  <ListItemIcon className={classes.listIcon}>
                    <ArrowForwardIos />
                  </ListItemIcon>
                </ListItem>
              </Link>
            ))}
          </List>
          <div className={classes.mobileSignUpSignIn}>{signInSignUpButtons}</div>
        </div>
      </Collapse>
    </>
  );
};

const useStyles = makeStyles()((theme) => ({
  appBar: {
    backgroundColor: HEADER_COLOR
  },
  drawerWrapper: {
    position: "fixed",
    top: TOOLBAR_HEIGHT,
    width: "100%",
    zIndex: 1200
  },
  drawer: {
    color: "inherit",
    height: `calc(100vh - ${TOOLBAR_HEIGHT}px)`,
    position: "relative",
    backgroundColor: HEADER_COLOR
  },
  mobileSignUpSignIn: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  list: {
    width: "100%"
  },
  listItem: {
    padding: theme.spacing(2, 0),
    margin: theme.spacing(0, LAYOUT_PADDING),
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    width: "initial"
  },
  listIcon: {
    minWidth: "unset",
    color: "inherit"
  },
  signUp: {
    margin: theme.spacing(0, 2)
  },
  hideXl: {
    [theme.breakpoints.up(theme.breakpoints.values.lg + 1)]: {
      display: "none"
    }
  }
}));

export default PublicHeader;
