import * as Sentry from "@sentry/gatsby";
import { User } from "@sentry/gatsby";
import { Primitive } from "utility-types";

export enum Severity {
  Fatal = "fatal",
  Error = "error",
  Warning = "warning",
  Log = "log",
  Info = "info",
  Debug = "debug"
}

export const setUserErrorContext = (user: User | null) => Sentry.setUser(user);

/** Reports err to Sentry. Context is used to attach User information to the error. */
export const reportError = (
  err: unknown,
  level: Severity = Severity.Error,
  tags?: Record<string, Primitive>
) => {
  const consoleMethodMap = {
    [Severity.Debug]: "debug",
    [Severity.Log]: "log",
    [Severity.Info]: "info",
    [Severity.Warning]: "warn",
    [Severity.Error]: "error",
    [Severity.Fatal]: "error"
  } as const;
  console.log("Reporting");
  console[consoleMethodMap[level]](err);
  Sentry.captureException(err, {
    level,
    tags,
    extra: { error: JSON.stringify(err, null, 2) }
  });
};
