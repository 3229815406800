import {
  AccountBox,
  CreditCard,
  ExitToApp,
  Note,
  Notifications,
  NotificationsPaused,
  SvgIconComponent,
  Work
} from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  Badge,
  Container,
  IconButton,
  Link as MuiLink,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography
} from "@mui/material";
import { AvatarTypeMap } from "@mui/material/Avatar/Avatar";
import { Box } from "@mui/system";
import { makeStyles } from "@placehires/react-component-library";
import { useLocation } from "@reach/router";
import { differenceInMonths, formatDistanceToNow } from "date-fns";
import { Link, navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import LoadingComponent from "../components/LoadingComponent";
import GiveFeedbackModal from "../components/profile/GiveFeedbackModal";
import ReportIssueModal from "../components/profile/ReportIssueModal";
import { APPLICANT_ROLE } from "../constants/general";
import { HEADER_COLOR, HEADER_ELEVATION } from "../constants/styles";
import {
  useFeedbackIntervalStringQuery,
  useLastFeedbackQuery,
  useNotificationsQuery,
  useReadNotificationsMutation,
  useSubscribeNotificationsSubscription
} from "../generated/graphqlHooks";
import { NotificationsQuery } from "../generated/graphqlTypes";
import { useUser } from "../hooks/authHooks";
import { useBillingPortalRedirect } from "../hooks/stripeHooks";
import { logout } from "../services/authService";
import { getPathname } from "../utils/browserUtils";
import { addNotification } from "../utils/popupUtils";
import SiteTitle from "./SiteTitle";

const computeBadgeCount = (data: NotificationsQuery) => {
  let count = 0;
  if (data?.notifications) {
    data.notifications.forEach((notification) => {
      if (!notification.read) count++;
    });
  }
  return count;
};

type MenuOptions = "profile" | "notifications";

const PrivateHeader: React.FC = () => {
  const [openMenu, setOpenMenu] = useState<MenuOptions>("profile");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openIssue, setOpenIssue] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const user = useUser();
  const { pathname } = useLocation();

  const { data, loading } = useNotificationsQuery({
    variables: { receiver: user?.uid },
    skip: !user
  });

  const { data: feedbackIntervalStringData } = useFeedbackIntervalStringQuery();
  const { data: lastFeedbackData } = useLastFeedbackQuery();
  const cantSubmitFeedback =
    differenceInMonths(new Date(), new Date(lastFeedbackData?.lastFeedback?.createdAt)) < 4;

  const [readNotification] = useReadNotificationsMutation();

  const notificationBadgeCount = computeBadgeCount(data);
  const { classes } = useStyles();

  // Mark unread notification that matches current pathname as read (if exists)
  useEffect(() => {
    if (data) {
      const { notifications } = data;
      const notificationToRead = notifications.find(
        (notification) => !notification.read && pathname === getPathname(notification.redirectLink)
      );
      if (notificationToRead) {
        readNotification({ variables: { id: notificationToRead._id } });
      }
    }
  }, [data, pathname, readNotification]);

  useSubscribeNotificationsSubscription({
    variables: { receiver: user?.uid },
    skip: !user,
    onSubscriptionData: ({ subscriptionData }) =>
      addNotification(subscriptionData.data.notificationAdded)
  });

  const handleAnchorClose = () => setAnchorEl(null);

  const handleOpenOptions = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    option: MenuOptions
  ) => {
    setOpenMenu(option);
    setAnchorEl(e.currentTarget);
  };

  const [handleBillingPortalClick, loadingBilling] = useBillingPortalRedirect();

  const renderNotifications = () => {
    if (loading)
      return (
        <MenuItem>
          <LoadingComponent />
        </MenuItem>
      );
    return !data?.notifications?.length ? (
      <MenuItem className={classes.notificationItem}>
        <ListItemIcon>
          <NotificationsPaused fontSize="large" />
        </ListItemIcon>
        You have no notifications
      </MenuItem>
    ) : (
      data.notifications.map(
        ({ _id, createdAt, read, message, redirectLink, avatarURL, avatarVariant }) => (
          <MenuItem
            key={_id}
            className={classes.notificationItem}
            onClick={() => {
              redirectLink && navigate(redirectLink);
              readNotification({ variables: { id: _id } }).then(handleAnchorClose);
            }}
          >
            <Avatar
              className={classes.menuImage}
              src={avatarURL}
              variant={avatarVariant as AvatarTypeMap["props"]["variant"]}
            >
              <Notifications />
            </Avatar>
            <div className={classes.notificationContent}>
              <Typography className={classes.notificationText} variant="body2">
                {message}
              </Typography>
              <Typography
                variant="body2"
                color={read ? "inherit" : "primary"}
                className={read ? classes.readNotification : ""}
              >
                {formatDistanceToNow(new Date(createdAt), {
                  addSuffix: true
                })}
              </Typography>
            </div>
          </MenuItem>
        )
      )
    );
  };

  return (
    <AppBar position="static" className={classes.appBar} elevation={HEADER_ELEVATION}>
      <Toolbar>
        <Container
          maxWidth="lg"
          sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <SiteTitle signedIn />
          {user && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <HeaderItem href={`/app/${user.uid}`}>Home</HeaderItem>
                {user.role === APPLICANT_ROLE && (
                  <HeaderItem href="/app/applications" icon={Note}>
                    Applications
                  </HeaderItem>
                )}
                <HeaderItem href="/app/jobs" icon={Work}>
                  Jobs
                </HeaderItem>
                <IconButton onClick={(e) => handleOpenOptions(e, "notifications")} sx={{ mx: 1 }}>
                  <Badge
                    badgeContent={notificationBadgeCount}
                    color="secondary"
                    className={classes.badge}
                  >
                    <Notifications sx={{ color: "text.tertiary" }} />
                  </Badge>
                </IconButton>
              </Box>
              <IconButton
                color="inherit"
                onClick={(e) => handleOpenOptions(e, "profile")}
                size="large"
              >
                <Avatar src={user.photoURL} sx={{ height: 32, width: 32 }} />
              </IconButton>
              <Menu
                id="appbar-menu"
                anchorEl={anchorEl}
                PaperProps={{ style: { width: 300, maxHeight: "max(50%, 500px)" } }}
                open={Boolean(anchorEl)}
                onClose={handleAnchorClose}
              >
                {openMenu == "profile"
                  ? [
                      <MenuItem key="profile" onClick={handleAnchorClose}>
                        <Link to={`/app/${user.uid}`} className={classes.menuLink}>
                          <ListItemIcon>
                            <AccountBox />
                          </ListItemIcon>

                          {user.displayName}
                        </Link>
                      </MenuItem>,
                      user.stripeId && (
                        <MenuItem
                          disabled={loadingBilling}
                          key="billing"
                          onClick={() => handleBillingPortalClick()}
                          onAuxClick={(e) => e.button === 1 && handleBillingPortalClick(true)}
                        >
                          <ListItemIcon>
                            <CreditCard />
                          </ListItemIcon>
                          Billing Portal
                        </MenuItem>
                      ),
                      <MenuItem key="logout" onClick={() => logout()}>
                        <ListItemIcon>
                          <ExitToApp />
                        </ListItemIcon>
                        Log Out
                      </MenuItem>,
                      <Tooltip
                        key="feedbackTooltip"
                        title={`You can only submit feedback once every ${feedbackIntervalStringData?.feedbackIntervalString}`}
                        placement="top"
                        arrow
                      >
                        <span>
                          <MenuItem
                            key="feedback"
                            disabled={cantSubmitFeedback}
                            onClick={() => {
                              handleAnchorClose();
                              setOpenFeedback(true);
                            }}
                          >
                            Give us feedback
                          </MenuItem>
                        </span>
                      </Tooltip>,

                      <MenuItem
                        key="issues"
                        onClick={() => {
                          handleAnchorClose();
                          setOpenIssue(true);
                        }}
                      >
                        Report an issue
                      </MenuItem>
                    ]
                  : renderNotifications()}
              </Menu>
              <GiveFeedbackModal
                key="feedbackModal"
                open={openFeedback}
                setOpen={setOpenFeedback}
              />
              <ReportIssueModal key="issueModal" open={openIssue} setOpen={setOpenIssue} />
            </Box>
          )}
        </Container>
      </Toolbar>
    </AppBar>
  );
};

type HeaderProps = {
  children: React.ReactNode;
  href: string;
  icon?: SvgIconComponent;
};

const HeaderItem: React.FC<HeaderProps> = ({ href, children, icon: Icon }) => {
  return (
    <>
      <MuiLink
        component={Link}
        to={href}
        sx={{
          color: "inherit",
          px: {
            xs: 1,
            md: 2
          }
        }}
        underline="none"
      >
        <Typography
          sx={{ display: { xs: "none", md: "block" } }}
          variant="h6"
          color="text.tertiary"
        >
          {children}
        </Typography>
        {Icon && (
          <IconButton
            sx={{
              color: "inherit",
              display: { xs: "flex", md: "none" },
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Icon sx={{ color: "text.tertiary" }} />
          </IconButton>
        )}
      </MuiLink>
    </>
  );
};

const useStyles = makeStyles()(() => ({
  appBar: {
    backgroundColor: HEADER_COLOR
  },
  badge: {
    wordBreak: "keep-all"
  },
  menuImage: {
    width: 45,
    height: 45,
    marginRight: 6
  },
  menuLink: {
    width: "100%",
    textDecoration: "none",
    color: "inherit",
    display: "flex",
    alignItems: "center"
  },
  notificationItem: {
    minHeight: 62,
    maxHeight: 82,
    whiteSpace: "normal",
    wordBreak: "break-word"
  },
  notificationContent: {
    maxHeight: 76
  },
  notificationText: {
    maxHeight: 57,
    overflow: "hidden",
    marginBottom: 2
  },
  readNotification: {
    opacity: 0.85
  }
}));

export default PrivateHeader;
