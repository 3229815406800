import { Typography } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const IMAGE_QUERY = graphql`
  query {
    file(relativePath: { eq: "notFound.png" }) {
      ...fluidImage
    }
  }
`;

const Fallback = () => {
  const { classes } = useStyles();
  const fluidImage = useStaticQuery(IMAGE_QUERY).file.childImageSharp.gatsbyImageData;

  return (
    <>
      <Typography variant="h1" className={classes.title} align="center" mb={2}>
        Oops! Something went wrong
      </Typography>
      <div className={classes.img}>
        <GatsbyImage image={fluidImage} loading="eager" alt="Not Found" />
      </div>
    </>
  );
};

const useStyles = makeStyles()(() => ({
  title: {
    fontWeight: 700
  },
  img: {
    width: 500
  }
}));

export default Fallback;
