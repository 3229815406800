import axios from "axios";
import client, { logoutApollo } from "../apollo/client";
import { getProfileImageURL } from "../constants/general";
import { CreateApplicantProfileDocument } from "../generated/graphqlTypes";
import { isMobile } from "../utils/browserUtils";
import firebase, { analytics, auth, storageRef } from "./firebaseService";

const EventName = firebase.analytics?.EventName;

const AUTH_URL = `${process.env.GATSBY_BACKEND_URL}auth/`;

export const logSignUp = () => {
  analytics.logEvent(EventName.SIGN_UP, { method: "email/password" });
};

/** Login to app */
export const login = (email: string, password: string) => {
  return auth.signInWithEmailAndPassword(email.trim(), password).then((userCred) => {
    analytics.logEvent(EventName.LOGIN, { method: "email/password" });
    return userCred;
  });
};

/** Logout, closing websocket connection and clearing cache */
export const logout = () => {
  auth.signOut().then(() => {
    logoutApollo();
  });
};

/** Get the token of current user as a promise */
export const getUserToken = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe();
      if (user) resolve(user.getIdToken());
      else resolve(null);
    }, reject);
  });
};

/** Updates profile image (user must be signed in) */
export const updateProfileImage = (profileImage: File): Promise<void> => {
  return new Promise((resolve, reject) => {
    const user = auth.currentUser;
    storageRef
      .child(getProfileImageURL(user.uid))
      .put(profileImage)
      .then((snapshot) => snapshot.ref.getDownloadURL())
      .then((url) => auth.currentUser.updateProfile({ photoURL: url }))
      .then(() => resolve(auth.updateCurrentUser(user)))
      .catch(reject);
  });
};

/** Send email verification */
export const sendEmailVerification = async () => {
  const token = await getUserToken();
  return axios.post(
    `${AUTH_URL}sendEmailVerification`,
    {},
    {
      headers: {
        authorization: token ? `Bearer ${token}` : ""
      }
    }
  );
};

/** Send password reset email */
export const sendPasswordResetLink = (email: string) => {
  return axios.post(`${AUTH_URL}sendResetPassword`, { email });
};

export const loginWithGoogle = () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope("email");
  return loginWithProvider(provider);
};

export const loginWithFacebook = () => {
  const provider = new firebase.auth.FacebookAuthProvider();
  provider.addScope("email");
  return loginWithProvider(provider);
};

const loginWithProvider = async (provider: firebase.auth.AuthProvider) => {
  if (isMobile()) return auth.signInWithRedirect(provider);
  try {
    const userCredential = await auth.signInWithPopup(provider);
    return handleSignInResult(userCredential);
  } catch (e) {
    if (e.code !== "auth/popup-closed-by-user") {
      throw e;
    }
  }
};

export const handleSignInResult = async ({
  user,
  additionalUserInfo
}: firebase.auth.UserCredential) => {
  if (!user) return;
  await createApplicantProfile(user);
  await user.getIdToken(true); // Refresh token
  if (additionalUserInfo?.isNewUser) {
    analytics.logEvent(EventName.SIGN_UP, { method: additionalUserInfo.providerId });
  } else {
    analytics.logEvent(EventName.LOGIN, { method: additionalUserInfo.providerId });
  }
};

/** Create applicant profile (used with third-party signup/login) only if it hasn't been created before */
export const createApplicantProfile = async (user: firebase.User) => {
  const { claims } = await user.getIdTokenResult();
  if (claims.role) return;
  await client.mutate({
    mutation: CreateApplicantProfileDocument,
    variables: {
      uid: user.uid
    }
  });
};
