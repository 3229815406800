import { useTheme } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import { useLocation } from "@reach/router";
import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { EMAIL_BAR_HEIGHT, LAYOUT_PADDING } from "../constants/styles";
import { TOOLBAR_HEIGHT } from "../gatsby-theme-material-ui-top-layout/theme";
import { useUser } from "../hooks/authHooks";

type LoadingComponentProps = {
  size?: number;
  css?: string;
  color?: string;
  fixed?: boolean;
};

const LoadingComponent: React.FC<LoadingComponentProps> = ({ css = "", color, fixed }) => {
  const theme = useTheme();
  const user = useUser();
  const { pathname } = useLocation();
  const showingEmailBar = user && !user.emailVerified && pathname.startsWith("/app");
  const fixedHeightOffset = showingEmailBar ? TOOLBAR_HEIGHT + EMAIL_BAR_HEIGHT : TOOLBAR_HEIGHT;
  const { classes } = useStyles({ fixed, fixedHeightOffset });
  if (!color) color = theme.palette.primary.main;

  return (
    <div className={classes.loaderWrapper}>
      <ScaleLoader css={css} color={color} />
    </div>
  );
};

const useStyles = makeStyles<Partial<LoadingComponentProps & { fixedHeightOffset: number }>>()(
  (theme, props) => ({
    loaderWrapper: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      ...(props.fixed && {
        position: "fixed",
        top: `calc(${props.fixedHeightOffset}px + ${theme.spacing(LAYOUT_PADDING)})`,
        left: 0,
        right: 0
      })
    }
  })
);

export default LoadingComponent;
