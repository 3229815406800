import { alertSuccess, FormikDialog, FormikTextField } from "@placehires/react-component-library";
import React from "react";
import { useCreateIssueMutation } from "../../generated/graphqlHooks";

interface ReportIssueModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const ReportIssueModal: React.FC<ReportIssueModalProps> = ({ open, setOpen }) => {
  const [createIssue] = useCreateIssueMutation();

  const handleClose = () => setOpen(false);

  return (
    <FormikDialog
      open={open}
      onClose={handleClose}
      title="Report Issue"
      saveText="Submit"
      formikConfig={{
        initialValues: {
          message: ""
        },
        onSaveSubmit: async ({ message }) => {
          await createIssue({
            variables: {
              message
            }
          });
          alertSuccess("Issue submitted");
        }
      }}
    >
      <FormikTextField
        required
        fullWidth
        multiline
        rows={4}
        maxRows={6}
        label="Details"
        name="message"
      />
    </FormikDialog>
  );
};

export default ReportIssueModal;
