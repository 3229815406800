import { useContext, useEffect, useRef } from "react";
import AuthUserContext from "../contexts/AuthUserContext";
import { auth } from "../services/firebaseService";
import { redirectOnLogin } from "../utils/browserUtils";

/** Gets the current user from context */
export const useUser = () => useContext(AuthUserContext);

/**
 * Gets access info of user.
 * `accessLevel` is null user was never subscribed or if user subscription expired.
 * `expired` will be set to true if user subscription expired.
 */
export const useAccessInfo = () => {
  const user = useUser();
  return {
    accessLevel: user?.currentPeriodEnd > new Date() && user.accessLevel,
    expired: user?.currentPeriodEnd && user.currentPeriodEnd < new Date()
  };
};

/** Returns true if given uid is same is current user's uid */
export const useIsCurrentUser = (uid: string) => {
  return useUser()?.uid === uid;
};

/** Returns true if current user has verified their email */
export const useIsUserEmailVerified = () => {
  const user = useUser();

  return user && user.emailVerified;
};

/** Redirects user to redirectedPath (or private home path) if user is logged in */
export const useAuthRedirect = () => {
  useEffect(() => {
    return auth.onIdTokenChanged(redirectOnLogin);
  }, []);
};

/**
 * Hook for email password autofill bug fix (when autofill doesn't fire onChange event). Email and
 * password fields must have input names: email, password
 *
 * @param setEmail Function to set controlled email value
 * @param setPassword Function to set controlled password value
 * @returns FormRef Ref to form with email and password
 */
export const useEmailPasswordAutofillRef = (
  setEmail: (email: string) => void,
  setPassword: (password: string) => void
) => {
  const formRef = useRef(null);

  useEffect(() => {
    const updateForm = () => {
      if (formRef.current) {
        const email = formRef?.current.elements.namedItem("email")?.value;
        const password = formRef?.current.elements.namedItem("password")?.value;
        if (email) setEmail(email);
        if (password) setPassword(password);
      }
    };
    updateForm();
    setTimeout(() => updateForm(), 300);
  }, []); // eslint-disable-line

  return formRef;
};
