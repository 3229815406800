import { AppBar, Button, Typography } from "@mui/material";
import { alertError, alertSuccess, makeStyles } from "@placehires/react-component-library";
import React, { useState } from "react";
import { StatusCode } from "../constants/general";
import { EMAIL_BAR_HEIGHT } from "../constants/styles";
import withAuthentication, { AuthInjectedProps } from "../hocs/withAuthentication";
import { sendEmailVerification } from "../services/authService";

const EmailVerificationBar: React.FC<AuthInjectedProps> = ({ user }) => {
  const { classes } = useStyles();
  const [recentlySent, setRecentlySent] = useState(false);

  const handleResend = () => {
    if (recentlySent) return alertSuccess("Email was recently sent. Please check your mail");
    sendEmailVerification()
      .then(() => {
        alertSuccess("Verification email sent!");
        setRecentlySent(true);
        setTimeout(() => setRecentlySent(false), 10000);
      })
      .catch((e) => {
        if (e.response?.status === StatusCode.TOO_MANY_REQUESTS) {
          alertSuccess("Email was recently sent. Please check your mail");
        } else {
          alertError();
        }
      });
  };

  return user.emailVerified ? (
    <div />
  ) : (
    <AppBar position="static" className={classes.emailBar}>
      <Typography variant="subtitle1">Please verify your email address</Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.resendButton}
        onClick={handleResend}
      >
        Resend Email
      </Button>
    </AppBar>
  );
};

const useStyles = makeStyles()((theme) => ({
  emailBar: {
    display: "flex",
    backgroundColor: theme.palette.text.tertiary,
    flexFlow: "row nowrap",
    justifyContent: "center",
    alignItems: "center",
    height: EMAIL_BAR_HEIGHT
  },
  resendButton: {
    margin: theme.spacing(0, 1),
    fontSize: "0.625rem"
  }
}));

export default withAuthentication(EmailVerificationBar);
