import { alertSuccess, inBrowser } from "@placehires/react-component-library";
import firebase from "firebase";
import { navigate } from "gatsby";
import { APPLICANT_ROLE, PRIVATE_HOME, RECRUITER_ROLE } from "../constants/general";

export const isMobile = () => {
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#avoiding_user_agent_detection
  let hasTouchScreen: boolean;
  if ("maxTouchPoints" in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else {
    const mQ = matchMedia?.("(pointer:coarse)");
    if (mQ?.media === "(pointer:coarse)") {
      hasTouchScreen = !!mQ.matches;
    } else if ("orientation" in window) {
      hasTouchScreen = true; // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      const UA = navigator.userAgent;
      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    }
  }
  return hasTouchScreen;
};

export const getQueryParams = () => new URLSearchParams(window.location.search);

export const getPathname = (url: string) => {
  return url.split(/[?#]/)[0];
};

export const toPathWithOldQueryParams = (pathname: string) => {
  return `${pathname}${(inBrowser() && window?.location?.search) || ""}`;
};

export const redirectOnLogin = (user: firebase.User) => {
  if (!user) return;
  user.getIdTokenResult().then(({ claims }) => {
    if (!claims.role) return;
    const customRedirect = getQueryParams().get("redirect");
    navigate(customRedirect || PRIVATE_HOME, {
      replace: true
    });
    if (claims.role !== APPLICANT_ROLE && claims.role !== RECRUITER_ROLE && !customRedirect) {
      alertSuccess(
        `You logged with ${claims.role} account, which does not have a home page on this website`
      );
    }
  });
};
