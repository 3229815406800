import { globalHistory } from "@reach/router";
import { ErrorBoundary } from "@sentry/gatsby";
import type { ErrorBoundaryProps, FallbackRender } from "@sentry/react/types/errorboundary";
import React, { useEffect } from "react";

/**
 * Error boundary where navigation to a different page works as expected by resetting
 * the error state
 */
const ErrorBoundaryWithNavigation: React.FC<ErrorBoundaryProps> = ({ fallback, ...rest }) => {
  return (
    <ErrorBoundary
      fallback={(renderProps) => <FallbackWrapper fallback={fallback} {...renderProps} />}
      {...rest}
    />
  );
};

type FallbackWrapperProps = Parameters<FallbackRender>[0] & Pick<ErrorBoundaryProps, "fallback">;

const FallbackWrapper: React.FC<FallbackWrapperProps> = ({ fallback, ...renderProps }) => {
  // Reset error every time history changes (event unsubscribed on dismount)
  useEffect(() => {
    return globalHistory.listen(() => renderProps.resetError());
  }, []); // eslint-disable-line

  return typeof fallback === "function" ? fallback(renderProps) : fallback;
};

export default ErrorBoundaryWithNavigation;
