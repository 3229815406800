import { Rating, Typography } from "@mui/material";
import {
  alertSuccess,
  FormikDialog,
  FormikTextField,
  makeStyles
} from "@placehires/react-component-library";
import React, { useState } from "react";
import { useCreateFeedbackMutation } from "../../generated/graphqlHooks";

interface GiveFeedbackModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const GiveFeedbackModal: React.FC<GiveFeedbackModalProps> = ({ open, setOpen }) => {
  const { classes } = useStyles();

  const [rating, setRating] = useState(0);
  const [createFeedback] = useCreateFeedbackMutation();

  const handleClose = () => {
    setOpen(false);
    setRating(0);
  };

  return (
    <FormikDialog
      open={open}
      onClose={handleClose}
      title="Leave Feedback"
      saveText="Submit"
      formikConfig={{
        initialValues: {
          message: ""
        },
        onSaveSubmit: async ({ message }) => {
          await createFeedback({
            variables: {
              message,
              rating
            }
          });
          alertSuccess("Feedback submitted");
        }
      }}
    >
      <Typography align="center"> Please give us your rating!</Typography>
      <div className={classes.ratingContainer}>
        <Rating
          name="feedback-rating"
          value={rating}
          onChange={(_, value) => setRating(value)}
          size="large"
        />
      </div>
      <FormikTextField
        name="message"
        required
        multiline
        rows={4}
        maxRows={6}
        label="Details"
        fullWidth
      />
    </FormikDialog>
  );
};

const useStyles = makeStyles()((theme) => ({
  ratingContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2)
  }
}));

export default GiveFeedbackModal;
