import { Container } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import React from "react";
import { LAYOUT_PADDING } from "../../../constants/styles";
import PrivateLayout from "../../../layout/PrivateLayout";
import PublicLayout from "../../../layout/PublicLayout";
import Fallback from "./Fallback";

type FullLayoutFallbackProps = {
  authenticated?: boolean;
};

const FullLayoutFallback: React.FC<FullLayoutFallbackProps> = ({ authenticated }) => {
  const { classes } = useStyles();
  const Layout = authenticated ? PrivateLayout : PublicLayout;

  return (
    <Layout seoTitle="Error" fullHeight>
      <Container className={classes.wrapper}>
        <Fallback />
      </Container>
    </Layout>
  );
};

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(LAYOUT_PADDING),
    marginBottom: theme.spacing(LAYOUT_PADDING)
  }
}));

export default FullLayoutFallback;
