import { Notifications } from "@mui/icons-material";
import { Avatar, Paper, Typography } from "@mui/material";
import { AvatarTypeMap } from "@mui/material/Avatar/Avatar";
import { Theme } from "@mui/material/styles";
import { StyleRulesCallback } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import React from "react";

type NotificationPopupProps = {
  message: string;
  avatarURL?: string;
  avatarVariant?: string;
  onClick?: () => void;
  classes?: any;
};

const NotificationPopup: React.FC<NotificationPopupProps> = ({
  classes,
  message,
  avatarURL,
  avatarVariant,
  onClick
}) => {
  return (
    <Paper variant="outlined" onClick={onClick} className={classes.paperWrapper}>
      <Typography variant="subtitle2">New Notification</Typography>
      <div className={classes.closeButton}>&times;</div>
      <div className={classes.body}>
        <Avatar
          src={avatarURL}
          className={classes.image}
          variant={avatarVariant as AvatarTypeMap["props"]["variant"]}
        >
          <Notifications />
        </Avatar>
        <div className={classes.contentWrapper}>
          <Typography variant="body2" className={classes.mainText}>
            {message}
          </Typography>
          <Typography variant="body2" color="primary">
            a few seconds ago
          </Typography>
        </div>
      </div>
    </Paper>
  );
};

const styles: StyleRulesCallback<Theme, any> = (theme: Theme) => ({
  paperWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0.5, 1),
    width: "100%"
  },
  closeButton: {
    position: "absolute",
    fontSize: 12,
    width: 18,
    height: 18,
    top: 10,
    right: 5
  },
  body: {
    display: "flex",
    margin: "5px 0",
    alignItems: "center"
  },
  image: {
    width: 45,
    height: 45,
    marginRight: 6
  },
  contentWrapper: {
    maxHeight: 76
  },
  mainText: {
    maxHeight: 57,
    overflow: "hidden",
    marginBottom: 2
  }
});

export default withStyles(styles)(NotificationPopup);
