import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import LinkWithHref from "./components/LinkWithHref";

export const TOOLBAR_HEIGHT = 64;

export const CARD_BORDER_RADIUS = "7px";

const flexFullHeight = {
  display: "flex",
  flexDirection: "column",
  flex: 1
};

const headerFontFamily = {
  fontFamily: "Comfortaa"
};

const h5FontWeight = 600;

const defaultTheme = createTheme();

let theme = createTheme({
  palette: {
    primary: {
      main: "#FB8500",
      contrastText: "#fff"
    },
    secondary: {
      main: "#ffc535"
    },
    error: {
      main: "#d32f2f"
    },
    success: {
      main: "#36B37E"
    },
    background: {
      default: "#fff"
    },
    darkBackground: {
      default: "#ECE8E4"
    },
    text: {
      tertiary: "#053046"
    }
  },
  typography: {
    h1: {
      ...headerFontFamily,
      fontSize: "3.75rem",
      fontWeight: 600
    },
    h2: {
      ...headerFontFamily,
      fontSize: "3rem",
      fontWeight: 700
    },
    h3: {
      ...headerFontFamily,
      fontSize: "2.125rem",
      fontWeight: 800
    },
    h4: {
      ...headerFontFamily,
      // h4 responsiveness is manually handled below
      // if this value is changed, changed below too
      fontSize: "1.5rem",
      fontWeight: 700
    },
    h5: {
      ...headerFontFamily,
      fontSize: "1.25rem",
      fontWeight: h5FontWeight
    },
    h6: {
      ...headerFontFamily,
      fontSize: "1rem",
      fontWeight: 600
    },
    subtitle1: {
      ...headerFontFamily
    },
    subtitle2: {
      ...headerFontFamily,
      fontWeight: 600
    }
  },
  spacing: (factor: number) => `${0.5 * factor}rem`,
  components: {
    // Global css
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: "100%"
        },
        body: {
          display: "flex",
          flexDirection: "column",
          minHeight: "100%",
          overflowWrap: "break-word",
          overflowX: "hidden",
          fontSize: "1rem",
          whiteSpace: "pre-line",
          // work around for because height:100% on body is not allowed
          "& #___gatsby": flexFullHeight,
          "& #gatsby-focus-wrapper": {
            ...flexFullHeight,
            "& > div": flexFullHeight
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          ".Mui-disabled": {
            cursor: "not-allowed"
          }
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          [defaultTheme.breakpoints.up("md")]: {
            maxWidth: 1128
          }
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: TOOLBAR_HEIGHT,
          paddingTop: 0,
          paddingBottom: 0
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkWithHref
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          borderRadius: CARD_BORDER_RADIUS,
          textTransform: "none",
          "&.Mui-disabled": {
            cursor: "not-allowed"
            // TODO: figure out color https://github.com/mui/material-ui/issues/29644#issuecomment-1029358719
            // opacity: 0.5,
          }
        },
        sizeSmall: {
          padding: "6px 12px"
        },
        sizeMedium: {
          padding: "8px 18px"
        },
        sizeLarge: {
          padding: "10px 24px"
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: CARD_BORDER_RADIUS
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        rounded: {
          borderRadius: CARD_BORDER_RADIUS
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          overflowX: "hidden"
        }
      }
    }
  }
});

theme = responsiveFontSizes(theme);

// Manually handle h4 responsiveness
theme.typography.h4 = {
  ...theme.typography.h4,

  [theme.breakpoints.up("sm")]: {
    fontSize: "1.3118rem"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.5rem"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.5rem"
  }
};

export const blogTheme = createTheme(theme, {
  typography: {
    h5: {
      ...headerFontFamily,
      fontSize: "1.375rem",
      fontWeight: h5FontWeight
    },
    h6: {
      ...headerFontFamily,
      [theme.breakpoints.up("sm")]: {
        fontSize: "1.125rem"
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "1.25rem"
      },
      fontWeight: 600
    },
    body1: {
      [theme.breakpoints.up("sm")]: {
        fontSize: "1.125rem",
        fontWeight: 300
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "1.25rem"
      }
    }
  }
});
export default theme;
