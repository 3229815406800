import { ApolloProvider } from "@apollo/client";
import { MaterialNotification } from "@placehires/react-component-library";
import { domAnimation, LazyMotion } from "framer-motion";
import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout";
import React from "react";
import client from "../../apollo/client";
import ErrorBoundaryWithNavigation from "../../components/errorBoundaries/ErrorBoundaryWithNavigation";
import FullLayoutFallback from "../../components/errorBoundaries/fallbacks/FullLayoutFallback";
import MDXProviderWithComponents from "../../components/MDXProviderWithComponents";

const TopLayout = ({ children, theme }) => {
  return (
    <ThemeTopLayout theme={theme}>
      <ErrorBoundaryWithNavigation fallback={<FullLayoutFallback />}>
        <ApolloProvider client={client}>
          <LazyMotion features={domAnimation}>
            <MaterialNotification theme={theme} />
            <MDXProviderWithComponents>{children}</MDXProviderWithComponents>
          </LazyMotion>
        </ApolloProvider>
      </ErrorBoundaryWithNavigation>
    </ThemeTopLayout>
  );
};

export default TopLayout;
