// https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
import * as yup from "yup";

export const isValidEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

// https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
export const isValidURL = (url: string) => {
  const re = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return re.test(url);
};

export const isValidPassword = (password: string) => {
  return password.length >= 6;
};

// Returns the link if it is a valid url, else return false
export const validatedLink = (link: string) => {
  return isValidURL(link) ? link : undefined;
};

export const isFile = (value: any) =>
  (typeof File !== "undefined" && value instanceof File) ||
  (typeof Blob !== "undefined" && value instanceof Blob);

// Yup schema objects below:
export const yupPassword = yup.string().min(6, "Please use at least 6 characters");
