import { createNotification } from "@placehires/react-component-library";
import { navigate } from "gatsby";
import React from "react";
import client from "../apollo/client";
import NotificationPopup from "../components/NotificationPopup";
import { Notification, ReadNotificationsDocument } from "../generated/graphqlTypes";

/**
 * Creates an on screen notification when notification comes from subscription. Also resets the
 * cache, to make sure new content is displayed.
 */
export const addNotification = async (notification: Notification) => {
  const { _id, message, redirectLink, avatarURL, avatarVariant } = notification;
  // Reset instead of client.resetStore() as a workaround of the
  // "Store reset while query was in flight" issue if somehow multiple notifications come at
  // exact same time
  client.cache.reset();
  await client.reFetchObservableQueries();
  createNotification({
    message,
    content: React.createElement(NotificationPopup, {
      message,
      avatarURL,
      avatarVariant,
      onClick: () => {
        redirectLink && navigate(redirectLink);
        client.mutate({ mutation: ReadNotificationsDocument, variables: { id: _id } });
      }
    })
  });
};
