exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-discover-restaurant-tsx": () => import("./../../../src/pages/discover-restaurant.tsx" /* webpackChunkName: "component---src-pages-discover-restaurant-tsx" */),
  "component---src-pages-find-restaurants-tsx": () => import("./../../../src/pages/find-restaurants.tsx" /* webpackChunkName: "component---src-pages-find-restaurants-tsx" */),
  "component---src-pages-login-applicant-tsx": () => import("./../../../src/pages/login/applicant.tsx" /* webpackChunkName: "component---src-pages-login-applicant-tsx" */),
  "component---src-pages-login-business-tsx": () => import("./../../../src/pages/login/business.tsx" /* webpackChunkName: "component---src-pages-login-business-tsx" */),
  "component---src-pages-login-forgot-password-tsx": () => import("./../../../src/pages/login/forgot-password.tsx" /* webpackChunkName: "component---src-pages-login-forgot-password-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-signup-applicant-tsx": () => import("./../../../src/pages/signup/applicant.tsx" /* webpackChunkName: "component---src-pages-signup-applicant-tsx" */),
  "component---src-pages-signup-business-tsx": () => import("./../../../src/pages/signup/business.tsx" /* webpackChunkName: "component---src-pages-signup-business-tsx" */),
  "component---src-pages-signup-index-tsx": () => import("./../../../src/pages/signup/index.tsx" /* webpackChunkName: "component---src-pages-signup-index-tsx" */),
  "component---src-pages-social-tsx": () => import("./../../../src/pages/social.tsx" /* webpackChunkName: "component---src-pages-social-tsx" */),
  "component---src-pages-typography-tsx": () => import("./../../../src/pages/typography.tsx" /* webpackChunkName: "component---src-pages-typography-tsx" */),
  "component---src-templates-applicants-tsx": () => import("./../../../src/templates/applicants.tsx" /* webpackChunkName: "component---src-templates-applicants-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-our-story-tsx": () => import("./../../../src/templates/our-story.tsx" /* webpackChunkName: "component---src-templates-our-story-tsx" */),
  "component---src-templates-privacy-policy-tsx": () => import("./../../../src/templates/privacy-policy.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-tsx" */),
  "component---src-templates-restaurants-tsx": () => import("./../../../src/templates/restaurants.tsx" /* webpackChunkName: "component---src-templates-restaurants-tsx" */),
  "component---src-templates-terms-of-service-tsx": () => import("./../../../src/templates/terms-of-service.tsx" /* webpackChunkName: "component---src-templates-terms-of-service-tsx" */)
}

