import { inBrowser } from "@placehires/react-component-library";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";

// Initializing firebase config here instead of using firebase plugin in gatsby-config
// so that firebase.auth() doesn't fail at build time

const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID
};

let firebaseAuth = null;
let firebaseStorageRef = null;
let firebaseAnalytics = null;
let firebasePerformance = null;

if (inBrowser()) {
  require("firebase/analytics");
  require("firebase/performance");
  firebase.initializeApp(firebaseConfig);
  firebaseAuth = firebase.auth();
  firebaseStorageRef = firebase.storage().ref();
  firebaseAnalytics = firebase.analytics();
  firebasePerformance = firebase.performance();
}

export const auth = firebaseAuth;

export const storageRef = firebaseStorageRef;

export const analytics = firebaseAnalytics;

export const performance = firebasePerformance;

export default firebase;
