import { useState } from "react";
import { flushSync } from "react-dom";

/**
 * Utility to have loading state around functions (loading is true while function is executing)
 */
export const useLoadingWrapper = () => {
  const [loading, setLoading] = useState(false);

  async function loadWrap<T>(fn: () => T | Promise<T>) {
    flushSync(() => setLoading(true));
    try {
      return await fn();
    } finally {
      setLoading(false);
    }
  }

  return [loadWrap, loading] as const;
};

export const useForceRerender = () => {
  const [, setValue] = useState(0);
  return flushSync(() => setValue((value) => value + 1));
};
