import { Instagram, LinkedIn } from "@mui/icons-material";
import { Container, Grid, Link as MLink, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@placehires/react-component-library";
import { Link } from "gatsby";
import React from "react";
import { PUBLIC_LINKS } from "../constants/general";
import SiteTitle from "./SiteTitle";

const Footer: React.FC = () => {
  const { classes } = useStyles();

  return (
    <footer className={classes.wrapper}>
      <Container>
        <Grid container spacing={4}>
          <Grid item md={6} xs={12} sx={{ textAlign: { xs: "center", md: "unset" } }}>
            <div className={classes.siteLogo}>
              <SiteTitle />
            </div>
            <Typography paragraph>
              © {new Date().getFullYear()} PlaceHires. All Rights Reserved <br />{" "}
              support@placehires.com
            </Typography>
            <MLink
              component={Link}
              to="/privacy-policy"
              variant="body1"
              underline="none"
              color="inherit"
              sx={{ mr: 2 }}
            >
              Privacy Policy
            </MLink>
            <MLink
              component={Link}
              to="/terms-of-service"
              variant="body1"
              underline="none"
              color="inherit"
            >
              Terms of Service
            </MLink>
          </Grid>
          <Grid item md={3} xs={6}>
            <Typography variant="h4" component="h2" color="primary" mb={1.5}>
              Quick Links
            </Typography>
            {PUBLIC_LINKS.map(({ name, to }) => (
              <Typography
                key={to}
                variant="h6"
                component={Link}
                to={to}
                sx={{
                  textDecoration: "none",
                  color: "text.tertiary",
                  display: "block",
                  py: 0.5
                }}
              >
                {name}
              </Typography>
            ))}
          </Grid>
          <Grid item md={3} xs={6}>
            <Typography variant="h4" component="h2" color="primary" textAlign="center" mb={4}>
              Social
            </Typography>
            <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap">
              {/*<SocialMediaLogo*/}
              {/*  href="https://www.facebook.com/matchii.io/"*/}
              {/*  Icon={FacebookOutlined}*/}
              {/*  titleAccess="PlaceHires Facebook Link"*/}
              {/*/>*/}
              <SocialMediaLogo
                href="https://www.instagram.com/placehires/"
                Icon={Instagram}
                titleAccess="PlaceHires Instagram Link"
              />
              {/*<SocialMediaLogo*/}
              {/*  href="https://www.youtube.com/channel/UCG0PhpJTY7x6ostqQcZ5c2g/"*/}
              {/*  Icon={YouTube}*/}
              {/*  titleAccess="PlaceHires Youtube Link"*/}
              {/*/>*/}
              <SocialMediaLogo
                href="https://www.linkedin.com/company/placehires/"
                Icon={LinkedIn}
                titleAccess="PlaceHires LinkedIn Link"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

const SocialMediaLogo = ({ href, titleAccess, Icon }: any) => {
  return (
    <MLink
      href={href}
      color="inherit"
      target="_blank"
      rel="noreferrer"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 44,
        width: 44,
        borderRadius: 44,
        border: "1px solid rgba(255, 255, 255, 0.1)",
        m: 1
      }}
    >
      <Icon titleAccess={titleAccess} />
    </MLink>
  );
};

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    color: theme.palette.text.tertiary,
    backgroundColor: theme.palette.darkBackground.default,
    padding: theme.spacing(6)
  },
  siteLogo: {
    display: "flex",
    marginTop: -5,
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      marginTop: 0
    }
  }
}));

export default Footer;
