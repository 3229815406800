import { MDXProvider, MDXProviderProps } from "@mdx-js/react";
import { Typography } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import classNames from "classnames";
import React, { useMemo } from "react";

const MDXProviderWithComponents: React.FC<Omit<MDXProviderProps, "components">> = ({
  children
}) => {
  const { classes } = useStyles();

  const headerComponents = useMemo(() => {
    const result = {} as any;
    Array(6)
      .fill(0)
      .forEach((_, i) => {
        const curHeader = `h${i + 1}`;
        result[curHeader] = (props: any) => (
          <Typography
            component={curHeader}
            variant={curHeader}
            className={classNames(classes.typography, classes.header)}
            color="primary"
            {...props}
          />
        );
      });
    return result;
  }, [classes.typography, classes.header]);
  return (
    <MDXProvider
      components={{
        ...headerComponents,
        p: (props) => <Typography {...props} className={classes.typography} />
      }}
    >
      {children}
    </MDXProvider>
  );
};

const useStyles = makeStyles()({
  typography: {
    margin: "revert"
  },
  header: {
    marginBottom: 0
  }
});

export default MDXProviderWithComponents;
