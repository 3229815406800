import { Link as MuiLink } from "@mui/material";
import { GatsbyLinkProps, Link } from "gatsby";
import React from "react";
import { isValidURL } from "../../utils/validate";

type LinkWithHrefProps = Omit<GatsbyLinkProps<unknown>, "ref"> & {
  href: string;
};

/**
 * Gatsby Link component to use for LinkComponent prop in material ui components
 */
const LinkWithHref = React.forwardRef<any, LinkWithHrefProps>((props, ref) => {
  return isValidURL(props.href) ? (
    <MuiLink ref={ref} href={props.href} {...props} />
  ) : (
    <div ref={ref}>
      <Link to={props.href} {...props} />
    </div>
  );
});

export default LinkWithHref;
