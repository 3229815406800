import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

type SEOProps = {
  title?: string;
  description?: string;
  lang?: string;
  meta?: [];
  withoutSiteTitle?: boolean;
};

const SEO: React.FC<SEOProps> = ({ description, lang, meta, title, withoutSiteTitle }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            lang
          }
        }
      }
    `
  );

  const metaTitle = title || site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description;
  lang = lang || site.siteMetadata.lang;
  const titleTemplate = withoutSiteTitle ? "%s" : `%s | ${site.siteMetadata.title}`;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={metaTitle}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        }
      ].concat(meta || [])}
    />
  );
};

export default SEO;
