/* This file was generated based on "codegen.yml". Do not edit manually. */
/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: Date;
  DateTime: Date;
  EmailAddress: string;
  IntRange: any;
  Language: any;
  NonNegativeInt: any;
  ObjectID: string;
  PositiveInt: number;
  URL: string;
  Upload: File;
};

/** Admin user of the platform */
export type Admin = {
  __typename?: 'Admin';
  _id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
};

/** Sign up fields to create an admin user */
export type AdminSignUpInput = {
  email: Scalars['EmailAddress'];
  firstName: Scalars['String'];
  fullName?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  password: Scalars['String'];
};

/** Questionnaire answer for some question */
export type Answer = {
  __typename?: 'Answer';
  optionId?: Maybe<Scalars['ObjectID']>;
  question: Question;
  questionId: Scalars['ObjectID'];
};

/** An applicant user on the platform */
export type Applicant = {
  __typename?: 'Applicant';
  _id: Scalars['ID'];
  avatarURL?: Maybe<Scalars['URL']>;
  backgroundURL?: Maybe<Scalars['URL']>;
  certifications?: Maybe<Array<ApplicantCertification>>;
  education?: Maybe<Array<Education>>;
  email?: Maybe<Scalars['String']>;
  experience?: Maybe<Array<Experience>>;
  firstName: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  industryId?: Maybe<Scalars['ObjectID']>;
  jobPreferences?: Maybe<JobPreferences>;
  lastName: Scalars['String'];
  projects?: Maybe<Array<Project>>;
  resumeURL?: Maybe<Scalars['URL']>;
  skills?: Maybe<Array<Scalars['ObjectID']>>;
  summary?: Maybe<Scalars['String']>;
};

/** Applicant Certification details */
export type ApplicantCertification = {
  __typename?: 'ApplicantCertification';
  /** This _id must match _id of one of existing Certifications */
  _id: Scalars['ObjectID'];
  credentialID?: Maybe<Scalars['String']>;
  credentialURL?: Maybe<Scalars['URL']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  issueDate?: Maybe<Scalars['DateTime']>;
  issuingOrganization: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

/** Applicant's certification details input */
export type ApplicantCertificationInput = {
  credentialID?: InputMaybe<Scalars['String']>;
  credentialURL?: InputMaybe<Scalars['URL']>;
  expirationDate?: InputMaybe<Scalars['DateTime']>;
  issueDate?: InputMaybe<Scalars['DateTime']>;
  issuingOrganization: Scalars['String'];
};

/** Intro fields of applicant and recruiters */
export type ApplicantRecruiterIntroInput = {
  backgroundFile?: InputMaybe<Scalars['Upload']>;
  firstName?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  industryId?: InputMaybe<Scalars['ObjectID']>;
  lastName?: InputMaybe<Scalars['String']>;
  summary?: InputMaybe<Scalars['String']>;
};

/** Sign up fields to create applicant or recruiter user */
export type ApplicantRecruiterSignUpInput = {
  email: Scalars['EmailAddress'];
  firstName: Scalars['String'];
  fullName?: InputMaybe<Scalars['String']>;
  industry: Scalars['ObjectID'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};

/** A job application sent on the platform */
export type Application = {
  __typename?: 'Application';
  _id: Scalars['ObjectID'];
  applicant?: Maybe<Applicant>;
  applicantId?: Maybe<Scalars['ID']>;
  job?: Maybe<Job>;
  jobId?: Maybe<Scalars['ObjectID']>;
  matchDetails?: Maybe<MatchDetails>;
  status?: Maybe<ApplicationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Status of an application */
export enum ApplicationStatus {
  CONSIDERED_MATCH = 'CONSIDERED_MATCH',
  CREATED = 'CREATED',
  DISCARDED = 'DISCARDED',
  HIRED = 'HIRED',
  INTERESTED = 'INTERESTED',
  INTERVIEWED = 'INTERVIEWED',
  MATCHED = 'MATCHED',
  NO_LONGER_UNDER_CONSIDERATION = 'NO_LONGER_UNDER_CONSIDERATION',
  SELECTED_FOR_INTERVIEW = 'SELECTED_FOR_INTERVIEW',
  UNDER_REVIEW = 'UNDER_REVIEW'
}

/** Certifications for job template */
export type Certification = {
  __typename?: 'Certification';
  _id: Scalars['ObjectID'];
  name?: Maybe<Scalars['String']>;
};

/** A company on the platform */
export type Company = {
  __typename?: 'Company';
  _id: Scalars['ObjectID'];
  avatarURL?: Maybe<Scalars['URL']>;
  backgroundURL?: Maybe<Scalars['URL']>;
  companySize?: Maybe<Scalars['Int']>;
  headquarters?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Scalars['URL']>>;
  industryId?: Maybe<Scalars['ObjectID']>;
  industryName?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['URL']>;
};

export type CompanyBasicInfoInput = {
  companySize?: InputMaybe<Scalars['Int']>;
  headquarters?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<Scalars['URL']>>;
  industryId?: InputMaybe<Scalars['ObjectID']>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  overview?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['URL']>;
};

/** Input to create a Company (when signing up a recruiter) */
export type CompanySignUpInput = {
  name: Scalars['String'];
  size: Scalars['NonNegativeInt'];
};

/** Education details */
export type Education = {
  __typename?: 'Education';
  _id: Scalars['ObjectID'];
  degree: EducationDegree;
  description?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  field?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  institution: Scalars['String'];
  startDate: Scalars['DateTime'];
};

/** Education degree */
export enum EducationDegree {
  HIGH_SCHOOL = 'HIGH_SCHOOL',
  POST_SECONDARY = 'POST_SECONDARY'
}

/** User's education details input */
export type EducationInput = {
  degree: EducationDegree;
  description?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  field?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Scalars['String']>;
  institution: Scalars['String'];
  startDate: Scalars['DateTime'];
};

/** Employment type */
export enum EmploymentType {
  CONTRACTOR = 'CONTRACTOR',
  FULL_TIME = 'FULL_TIME',
  INTERN = 'INTERN',
  PART_TIME = 'PART_TIME'
}

/** Error codes for Apollo Errors */
export enum ErrorCode {
  BAD_USER_INPUT = 'BAD_USER_INPUT',
  BATCH_INSERT_ERROR = 'BATCH_INSERT_ERROR',
  CONFLICT = 'CONFLICT',
  FORBIDDEN = 'FORBIDDEN',
  GRAPHQL_VALIDATION_FAILED = 'GRAPHQL_VALIDATION_FAILED',
  NOT_FOUND = 'NOT_FOUND',
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
  UNAUTHENTICATED = 'UNAUTHENTICATED'
}

/** Work experience details */
export type Experience = {
  __typename?: 'Experience';
  _id: Scalars['ObjectID'];
  company: Scalars['String'];
  companyAvatarURL?: Maybe<Scalars['URL']>;
  companyId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  employmentType?: Maybe<EmploymentType>;
  endDate?: Maybe<Scalars['DateTime']>;
  position: Scalars['String'];
  startDate: Scalars['DateTime'];
};

/** User's work experience details input */
export type ExperienceInput = {
  company: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  employmentType?: InputMaybe<EmploymentType>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  position: Scalars['String'];
  startDate: Scalars['DateTime'];
};

/** User feedback */
export type Feedback = {
  __typename?: 'Feedback';
  _id: Scalars['ObjectID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  message?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['NonNegativeInt']>;
  sender?: Maybe<Scalars['String']>;
};

/** Industry that the app supports */
export type Industry = {
  __typename?: 'Industry';
  _id: Scalars['ObjectID'];
  active?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

/** Interview */
export type Interview = {
  __typename?: 'Interview';
  _id: Scalars['ObjectID'];
  applicantId?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['DateTime']>;
  jobId: Scalars['ObjectID'];
  start?: Maybe<Scalars['DateTime']>;
};

/** Interview details */
export type InterviewDetails = {
  __typename?: 'InterviewDetails';
  description?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  minimumNotice?: Maybe<Scalars['Int']>;
};

export type InterviewDetailsFieldsInput = {
  description?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  minimumNotice?: InputMaybe<Scalars['Int']>;
};

export type InterviewFieldsInput = {
  end?: InputMaybe<Scalars['DateTime']>;
  jobId: Scalars['ObjectID'];
  start?: InputMaybe<Scalars['DateTime']>;
};

/** Issues reported by users */
export type Issue = {
  __typename?: 'Issue';
  _id: Scalars['ObjectID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  message?: Maybe<Scalars['String']>;
  resolved?: Maybe<Scalars['Boolean']>;
  sender?: Maybe<Scalars['String']>;
};

/** A job posting on the platform */
export type Job = {
  __typename?: 'Job';
  _id: Scalars['ObjectID'];
  certifications?: Maybe<Array<Maybe<Scalars['ObjectID']>>>;
  company?: Maybe<Company>;
  companyId: Scalars['ObjectID'];
  description: Scalars['String'];
  employmentType: EmploymentType;
  filterDeadline: Scalars['DateTime'];
  interviewDetails?: Maybe<InterviewDetails>;
  language?: Maybe<Scalars['Language']>;
  languageLevel?: Maybe<LanguageProficiencyType>;
  location: Scalars['String'];
  maxAppliedApplicants: Scalars['NonNegativeInt'];
  maxFilteredApplicants: Scalars['NonNegativeInt'];
  position?: Maybe<Position>;
  positionId: Scalars['ObjectID'];
  recruiterId: Scalars['ID'];
  salary?: Maybe<Scalars['IntRange']>;
  skills?: Maybe<Array<Maybe<Scalars['ObjectID']>>>;
  status?: Maybe<JobStatus>;
  yearsOfExperience?: Maybe<Scalars['IntRange']>;
};

export type JobFieldsInput = {
  certifications?: InputMaybe<Array<InputMaybe<Scalars['ObjectID']>>>;
  description: Scalars['String'];
  employmentType: EmploymentType;
  filterDeadline: Scalars['DateTime'];
  language?: InputMaybe<Scalars['Language']>;
  languageLevel?: InputMaybe<LanguageProficiencyType>;
  location: Scalars['String'];
  maxAppliedApplicants: Scalars['NonNegativeInt'];
  maxFilteredApplicants: Scalars['NonNegativeInt'];
  positionId: Scalars['ObjectID'];
  salary?: InputMaybe<Scalars['IntRange']>;
  skills?: InputMaybe<Array<InputMaybe<Scalars['ObjectID']>>>;
  status?: InputMaybe<JobStatus>;
  yearsOfExperience?: InputMaybe<Scalars['IntRange']>;
};

/** Applicant job preferences */
export type JobPreferences = {
  __typename?: 'JobPreferences';
  jobTypes?: Maybe<Array<Maybe<EmploymentType>>>;
  positionsIds?: Maybe<Array<Maybe<Scalars['ObjectID']>>>;
  seeking?: Maybe<Scalars['Boolean']>;
};

/** Applicant's job preferences details input */
export type JobPreferencesInput = {
  jobTypes?: InputMaybe<Array<InputMaybe<EmploymentType>>>;
  positionsIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectID']>>>;
  seeking?: InputMaybe<Scalars['Boolean']>;
};

/** Status of a job posting */
export enum JobStatus {
  APPROVED = 'APPROVED',
  DISAPPROVED = 'DISAPPROVED',
  FILTERED = 'FILTERED',
  HIRED = 'HIRED',
  MATCHED = 'MATCHED',
  OPEN = 'OPEN',
  PAUSED = 'PAUSED'
}

/** A job template on the platform */
export type JobTemplate = {
  __typename?: 'JobTemplate';
  _id: Scalars['ObjectID'];
  certifications?: Maybe<Array<Maybe<Scalars['ObjectID']>>>;
  description: Scalars['String'];
  position?: Maybe<Position>;
  positionId: Scalars['ObjectID'];
  skills?: Maybe<Array<Maybe<Scalars['ObjectID']>>>;
};

export type JobTemplateFieldsInput = {
  certifications?: InputMaybe<Array<InputMaybe<Scalars['ObjectID']>>>;
  description: Scalars['String'];
  positionId: Scalars['ObjectID'];
  skills?: InputMaybe<Array<InputMaybe<Scalars['ObjectID']>>>;
};

/** Language proficiency type */
export enum LanguageProficiencyType {
  ADVANCED = 'ADVANCED',
  BEGINNER = 'BEGINNER',
  ELEMENTARY = 'ELEMENTARY',
  INTERMEDIATE = 'INTERMEDIATE',
  PROFICIENT = 'PROFICIENT'
}

/** Match details of current application */
export type MatchDetails = {
  __typename?: 'MatchDetails';
  numSkillsMatched: Scalars['NonNegativeInt'];
  questionnaireMatchScore: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Add a new certification record to applicant */
  addApplicantCertification?: Maybe<ApplicantCertification>;
  /** Add a new education record to applicant */
  addApplicantEducation?: Maybe<Education>;
  /** Add a new experience record to applicant */
  addApplicantExperience?: Maybe<Experience>;
  /** Approve the job and make it available to applicants */
  approveJob?: Maybe<Job>;
  /** Create applicant profile for existing firebase user (used with third party sign up) */
  createApplicantProfile?: Maybe<Applicant>;
  /** Create a billing portal session for a customer. Returns url of customer's billing portal. Only users with stripe id allowed */
  createBillingPortalSession: Scalars['String'];
  /** Create a new certification */
  createCertification?: Maybe<Certification>;
  /** Create a new feedback. Gives a TOO_MANY_REQUESTS error when last feedback was less than 4 months ago */
  createFeedback?: Maybe<Feedback>;
  /** Create a new industry */
  createIndustry?: Maybe<Industry>;
  /** Create a new interview slot */
  createInterview?: Maybe<Interview>;
  /** Create (report) a new issue */
  createIssue: Issue;
  /** Create a new job */
  createJob?: Maybe<Job>;
  /** Create a new job template */
  createJobTemplate?: Maybe<JobTemplate>;
  /** Create a new option group */
  createOptionGroup?: Maybe<OptionGroup>;
  /** Create a new position */
  createPosition?: Maybe<Position>;
  /** Create a new question */
  createQuestion?: Maybe<Question>;
  /** Create a new questionnaire */
  createQuestionnaire?: Maybe<Questionnaire>;
  /** Create a new questionnaire variable */
  createQuestionnaireVariable?: Maybe<QuestionnaireVariable>;
  /** Create new questions */
  createQuestions?: Maybe<Array<Maybe<Question>>>;
  /** Create a new skill */
  createSkill?: Maybe<Skill>;
  /** Create new skills */
  createSkills?: Maybe<Array<Maybe<Skill>>>;
  /**
   * Create Stripe subscription checkout session. Returns a Stripe session id.
   *         Throws ConflictError if user already has a subscription.
   *
   */
  createSubscriptionCheckoutSession: Scalars['ID'];
  /** Delete an existing education record from an applicant */
  deleteApplicantCertification?: Maybe<ApplicantCertification>;
  /** Delete an existing education record from an applicant */
  deleteApplicantEducation?: Maybe<Education>;
  /** Delete an existing experience record from an applicant */
  deleteApplicantExperience?: Maybe<Experience>;
  /** Delete a specific certification */
  deleteCertification?: Maybe<Certification>;
  /** Delete a an industry by id */
  deleteIndustry?: Maybe<Industry>;
  /** Delete an interview slot */
  deleteInterview?: Maybe<Interview>;
  /** Delete a specific job template */
  deleteJobTemplate?: Maybe<JobTemplate>;
  /** Delete a option group. This is only allowed if there aren't any questions that use this group */
  deleteOptionGroup?: Maybe<OptionGroup>;
  /** Delete an existing position if unused */
  deletePosition?: Maybe<Position>;
  /** Delete a question. This is only if question is not used by questionnaire */
  deleteQuestion?: Maybe<Question>;
  /** Delete a questionnaire variable. This is only allowed if there aren't any questions that use this variable */
  deleteQuestionnaireVariable?: Maybe<QuestionnaireVariable>;
  /** Delete a specific skill */
  deleteSkill?: Maybe<Skill>;
  /** Disapprove the job. The job stays hidden from applicants */
  disapproveJob?: Maybe<Job>;
  /** Update application status to MATCHED and ask applicants to confirm interest */
  filterApplications: Array<Maybe<Application>>;
  /**
   * Paused for manual override. Applications will not be automatically matched.
   *       This should be done when we have insufficient number of applicants for the job and we want to delay matching
   */
  pauseJob?: Maybe<Job>;
  /**
   * Post-process successful checkout . Returns the customer stripe id of the session.
   *         Note: this may update user's custom claims, so refresh firebase token!
   *
   */
  processSuccessfulCheckoutSession: Scalars['ID'];
  /** Update the read status of a notification to true */
  readNotification: Notification;
  /**
   * Remove application, used when applicant decides they are no longer interested in
   *        the job (this will set application status to 'discarded', so it can still be searched if needed
   */
  removeApplication?: Maybe<Application>;
  /** Mark issue as resolved */
  resolveIssue: Issue;
  /** Assign an applicantId to an interview */
  selectInterview?: Maybe<Interview>;
  /** Sign up an Admin user. Only another admin can do this. */
  signUpAdmin?: Maybe<Admin>;
  /** Sign up an Applicant user */
  signUpApplicant?: Maybe<Applicant>;
  /** Sign up a Recruiter user */
  signUpRecruiter?: Maybe<Recruiter>;
  /** Unpause to continue the matching process */
  unpauseJob?: Maybe<Job>;
  /** Mark issue as unresolved */
  unresolveIssue: Issue;
  /** (Partially) Update a specific applicant user profile details. Only provided fields will be updated */
  updateApplicant?: Maybe<Applicant>;
  /** Update an existing certification record from an applicant */
  updateApplicantCertification?: Maybe<ApplicantCertification>;
  /** Update an existing education record from an applicant */
  updateApplicantEducation?: Maybe<Education>;
  /** Update an existing experience record from an applicant */
  updateApplicantExperience?: Maybe<Experience>;
  /** Update a specific applicant user job details */
  updateApplicantJobPreferences?: Maybe<Applicant>;
  /** Update application status accordingly */
  updateApplicationStatus?: Maybe<Application>;
  /** Update a company's profile details */
  updateCompany?: Maybe<Company>;
  /** Update industry information */
  updateIndustry?: Maybe<Industry>;
  /** Update the details of a specific job posting's interview */
  updateInterviewDetails?: Maybe<Job>;
  /** Update the details of a specific job posting */
  updateJob?: Maybe<Job>;
  /** Update the details of a specific job template */
  updateJobTemplate?: Maybe<JobTemplate>;
  /** Update questionnaire answers */
  updateQuestionnaireAnswers?: Maybe<QuestionnaireResult>;
  /** Update the profile details of a specific recruiter user */
  updateRecruiter?: Maybe<Recruiter>;
  /** Update verified status, only allowed by admins */
  verifyRecruiter?: Maybe<Recruiter>;
};


export type MutationAddApplicantCertificationArgs = {
  _id: Scalars['ObjectID'];
  certificationFields: ApplicantCertificationInput;
};


export type MutationAddApplicantEducationArgs = {
  educationFields: EducationInput;
};


export type MutationAddApplicantExperienceArgs = {
  experienceFields: ExperienceInput;
};


export type MutationApproveJobArgs = {
  id: Scalars['ID'];
};


export type MutationCreateApplicantProfileArgs = {
  uid: Scalars['String'];
};


export type MutationCreateBillingPortalSessionArgs = {
  returnUrl: Scalars['URL'];
};


export type MutationCreateCertificationArgs = {
  name: Scalars['String'];
};


export type MutationCreateFeedbackArgs = {
  message: Scalars['String'];
  rating?: InputMaybe<Scalars['NonNegativeInt']>;
};


export type MutationCreateIndustryArgs = {
  name: Scalars['String'];
};


export type MutationCreateInterviewArgs = {
  interviewFields: InterviewFieldsInput;
};


export type MutationCreateIssueArgs = {
  message: Scalars['String'];
};


export type MutationCreateJobArgs = {
  jobFields: JobFieldsInput;
};


export type MutationCreateJobTemplateArgs = {
  jobTemplateFields: JobTemplateFieldsInput;
};


export type MutationCreateOptionGroupArgs = {
  optionGroupFields: OptionGroupInput;
};


export type MutationCreatePositionArgs = {
  industries?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name: Scalars['String'];
};


export type MutationCreateQuestionArgs = {
  questionFields: QuestionInput;
};


export type MutationCreateQuestionnaireArgs = {
  questionnaireFields: QuestionnaireInput;
};


export type MutationCreateQuestionnaireVariableArgs = {
  name?: InputMaybe<Scalars['String']>;
};


export type MutationCreateQuestionsArgs = {
  questionsFields: Array<QuestionInput>;
};


export type MutationCreateSkillArgs = {
  skillFields: SkillFieldsInput;
};


export type MutationCreateSkillsArgs = {
  skillsFields: Array<SkillFieldsInput>;
};


export type MutationCreateSubscriptionCheckoutSessionArgs = {
  cancelUrl: Scalars['URL'];
  plan: SubscriptionPlan;
  successUrl: Scalars['URL'];
};


export type MutationDeleteApplicantCertificationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteApplicantEducationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteApplicantExperienceArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCertificationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteIndustryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteInterviewArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteJobTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOptionGroupArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeletePositionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteQuestionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteQuestionnaireVariableArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteSkillArgs = {
  id: Scalars['ID'];
};


export type MutationDisapproveJobArgs = {
  id: Scalars['ID'];
};


export type MutationFilterApplicationsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationPauseJobArgs = {
  id: Scalars['ID'];
};


export type MutationProcessSuccessfulCheckoutSessionArgs = {
  sessionId: Scalars['ID'];
};


export type MutationReadNotificationArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveApplicationArgs = {
  id: Scalars['ID'];
};


export type MutationResolveIssueArgs = {
  id: Scalars['ID'];
};


export type MutationSelectInterviewArgs = {
  id: Scalars['ID'];
};


export type MutationSignUpAdminArgs = {
  userInput: AdminSignUpInput;
};


export type MutationSignUpApplicantArgs = {
  signUpInput: ApplicantRecruiterSignUpInput;
};


export type MutationSignUpRecruiterArgs = {
  companyInput: CompanySignUpInput;
  userInput: ApplicantRecruiterSignUpInput;
};


export type MutationUnpauseJobArgs = {
  id: Scalars['ID'];
};


export type MutationUnresolveIssueArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateApplicantArgs = {
  applicantRecruiterIntroInput?: InputMaybe<ApplicantRecruiterIntroInput>;
  certifications?: InputMaybe<Array<Scalars['ObjectID']>>;
  resumeFile?: InputMaybe<Scalars['Upload']>;
  skills?: InputMaybe<Array<Scalars['ObjectID']>>;
};


export type MutationUpdateApplicantCertificationArgs = {
  certificationFields: ApplicantCertificationInput;
  id: Scalars['ID'];
};


export type MutationUpdateApplicantEducationArgs = {
  educationFields: EducationInput;
  id: Scalars['ID'];
};


export type MutationUpdateApplicantExperienceArgs = {
  experienceFields: ExperienceInput;
  id: Scalars['ID'];
};


export type MutationUpdateApplicantJobPreferencesArgs = {
  jobPreferences: JobPreferencesInput;
};


export type MutationUpdateApplicationStatusArgs = {
  id: Scalars['ID'];
  status: ApplicationStatus;
};


export type MutationUpdateCompanyArgs = {
  avatarFile?: InputMaybe<Scalars['Upload']>;
  backgroundFile?: InputMaybe<Scalars['Upload']>;
  basicInfo?: InputMaybe<CompanyBasicInfoInput>;
  id: Scalars['ID'];
};


export type MutationUpdateIndustryArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateInterviewDetailsArgs = {
  id: Scalars['ID'];
  interviewDetailsFields: InterviewDetailsFieldsInput;
};


export type MutationUpdateJobArgs = {
  id: Scalars['ID'];
  jobFields: JobFieldsInput;
};


export type MutationUpdateJobTemplateArgs = {
  id: Scalars['ID'];
  updateFields: JobTemplateFieldsInput;
};


export type MutationUpdateQuestionnaireAnswersArgs = {
  answers: Array<InputMaybe<QuestionnaireAnswerInput>>;
  id: Scalars['ID'];
};


export type MutationUpdateRecruiterArgs = {
  applicantRecruiterIntroInput: ApplicantRecruiterIntroInput;
};


export type MutationVerifyRecruiterArgs = {
  id: Scalars['ID'];
};

/** A notification sent on the platform */
export type Notification = {
  __typename?: 'Notification';
  _id: Scalars['ObjectID'];
  avatarURL?: Maybe<Scalars['URL']>;
  avatarVariant?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  message?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  receiver?: Maybe<Scalars['String']>;
  redirectLink?: Maybe<Scalars['String']>;
  sender?: Maybe<Scalars['String']>;
};

/** A group of reusable options */
export type OptionGroup = {
  __typename?: 'OptionGroup';
  _id: Scalars['ObjectID'];
  name: Scalars['String'];
  options: Array<Maybe<QuestionOption>>;
  withScores?: Maybe<Scalars['Boolean']>;
};

export type OptionGroupInput = {
  name: Scalars['String'];
  options: Array<QuestionOptionInput>;
};

/** A position on job template */
export type Position = {
  __typename?: 'Position';
  _id: Scalars['ObjectID'];
  industries?: Maybe<Array<Maybe<Scalars['ObjectID']>>>;
  jobTemplate?: Maybe<JobTemplate>;
  name?: Maybe<Scalars['String']>;
};

/** Project details */
export type Project = {
  __typename?: 'Project';
  _id: Scalars['ObjectID'];
  description: Scalars['String'];
  link?: Maybe<Scalars['URL']>;
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /** Get all admin users of the platform */
  admins?: Maybe<Array<Maybe<Admin>>>;
  /** Get a specific applicant user of the platform */
  applicant?: Maybe<Applicant>;
  /** Get applicant's job application */
  applicantApplication?: Maybe<Application>;
  /** Get applicant's applications with CREATED status (applications with CREATED status are applications to which applicants need to show interest in before the matching begins) */
  applicantCreatedApplications?: Maybe<Array<Application>>;
  /** Get applicant's applications that he/she can view */
  applicantViewableApplications?: Maybe<Array<Application>>;
  /** Get a specific job application */
  application?: Maybe<Application>;
  /** Get a specific certification */
  certification?: Maybe<Certification>;
  /** Get all certifications */
  certifications?: Maybe<Array<Certification>>;
  /** Get all companies on the platform */
  companies?: Maybe<Array<Maybe<Company>>>;
  /** Get a specific company on the platform */
  company?: Maybe<Company>;
  /** Count number of applications filtered for the job */
  countJobMatchedApplications: Scalars['Int'];
  /** Get a specific company on the platform */
  currentRecruiterCompany?: Maybe<Company>;
  /** Get user feedback (paginated) */
  feedback?: Maybe<Array<Feedback>>;
  /** Get string representing how long until next feedback can be submitted after previous feedback */
  feedbackIntervalString?: Maybe<Scalars['String']>;
  /** Get all industries */
  industries?: Maybe<Array<Industry>>;
  /** Get a specific industry */
  industry?: Maybe<Industry>;
  /** Get a specific interview */
  interview?: Maybe<Interview>;
  /** Get all interviews for an applicant */
  interviewsForApplicant?: Maybe<Array<Interview>>;
  /** Get all interviews for a job */
  interviewsForJob?: Maybe<Array<Interview>>;
  /** Get reported issues (paginated) */
  issues?: Maybe<Array<Issue>>;
  /** Get a specific job posting */
  job?: Maybe<Job>;
  /** Get applications of applicants with CONSIDERED_MATCH status - applicants we're considering to send to recruiter */
  jobConsideredMatchApplications?: Maybe<Array<Application>>;
  /** Get applications of applicants with INTERESTED status. Used to show applicants that need filtering */
  jobInterestedApplications?: Maybe<Array<Application>>;
  /** Get a specific job template */
  jobTemplate?: Maybe<JobTemplate>;
  /** Get job template of a position. */
  jobTemplateByPosition?: Maybe<JobTemplate>;
  /** Get all job templates. Only admin users can do this. */
  jobTemplates?: Maybe<Array<JobTemplate>>;
  /** Get last user feedback */
  lastFeedback?: Maybe<Feedback>;
  /** Get all matched jobs - jobs that required applicant filtering. Only admin users can view them */
  matchedJobs?: Maybe<Array<Job>>;
  /** Get all matched and paused jobs. Only admin users can view them */
  matchedOrPausedJobs?: Maybe<Array<Job>>;
  /** Get all notifications of the current user (limited at 20) */
  notifications?: Maybe<Array<Notification>>;
  /** Get all recently posted jobs - jobs that required review to be visible to applicants */
  openJobs?: Maybe<Array<Job>>;
  /** Get all option groups */
  optionGroups?: Maybe<Array<OptionGroup>>;
  /** Get a specific position */
  position?: Maybe<Position>;
  /** Get all position. Only admin users can do this. */
  positions?: Maybe<Array<Position>>;
  /** Get user questionnaire result */
  questionnaireResult: QuestionnaireResult;
  /** Get user questionnaire result progress (% questions answered) */
  questionnaireResultProgress: Scalars['NonNegativeInt'];
  /** Get a specific variable */
  questionnaireVariable?: Maybe<QuestionnaireVariable>;
  /** Get all variables */
  questionnaireVariables?: Maybe<Array<QuestionnaireVariable>>;
  /** Get all questionnaires */
  questionnaires?: Maybe<Array<Questionnaire>>;
  /** Get all questions with variables */
  questionsWithVariables?: Maybe<Array<Question>>;
  /** Get a specific recruiter user on the platform */
  recruiter?: Maybe<Recruiter>;
  /** Get all job applications of all jobs of the given status posted by this current recruiter user */
  recruiterApplicationsByStatus?: Maybe<Array<Application>>;
  /** Get current recruiter's jobs */
  recruiterJobs?: Maybe<Array<Job>>;
  /** Get job's applications that recruiter can view */
  recruiterViewableJobApplications?: Maybe<Array<Application>>;
  /** Get resolved issues on the platform. Admin users allowed only */
  resolvedIssues?: Maybe<Array<Issue>>;
  /** Get a specific skill */
  skill?: Maybe<Skill>;
  /** Get all skills */
  skills?: Maybe<Array<Skill>>;
  /** Get unresolved issues on the platform. Admin users allowed only */
  unresolvedIssues?: Maybe<Array<Issue>>;
  /** Get unverified recruiters on the platform. Admin users allowed only. */
  unverifiedRecruiters?: Maybe<Array<Recruiter>>;
};


export type QueryApplicantArgs = {
  id: Scalars['ID'];
};


export type QueryApplicantApplicationArgs = {
  jobId: Scalars['ID'];
};


export type QueryApplicationArgs = {
  id: Scalars['ID'];
};


export type QueryCertificationArgs = {
  id: Scalars['ID'];
};


export type QueryCertificationsArgs = {
  sortNameBy?: InputMaybe<SortOrder>;
};


export type QueryCompaniesArgs = {
  sortNameBy?: InputMaybe<SortOrder>;
};


export type QueryCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryCountJobMatchedApplicationsArgs = {
  jobId: Scalars['ID'];
};


export type QueryFeedbackArgs = {
  itemsPerPage: Scalars['PositiveInt'];
  page: Scalars['PositiveInt'];
};


export type QueryIndustriesArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
};


export type QueryIndustryArgs = {
  id: Scalars['ID'];
};


export type QueryInterviewArgs = {
  id: Scalars['ID'];
};


export type QueryInterviewsForJobArgs = {
  jobId: Scalars['ObjectID'];
};


export type QueryIssuesArgs = {
  itemsPerPage: Scalars['PositiveInt'];
  page: Scalars['PositiveInt'];
  resolved?: InputMaybe<Scalars['Boolean']>;
};


export type QueryJobArgs = {
  id: Scalars['ID'];
};


export type QueryJobConsideredMatchApplicationsArgs = {
  jobId: Scalars['ID'];
};


export type QueryJobInterestedApplicationsArgs = {
  jobId: Scalars['ID'];
};


export type QueryJobTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryJobTemplateByPositionArgs = {
  id: Scalars['ID'];
};


export type QueryNotificationsArgs = {
  receiver: Scalars['ID'];
};


export type QueryPositionArgs = {
  id: Scalars['ID'];
};


export type QueryPositionsArgs = {
  industry?: InputMaybe<Scalars['ObjectID']>;
  sortNameBy?: InputMaybe<SortOrder>;
};


export type QueryQuestionnaireVariableArgs = {
  id: Scalars['ID'];
};


export type QueryRecruiterArgs = {
  id: Scalars['ID'];
};


export type QueryRecruiterApplicationsByStatusArgs = {
  status: ApplicationStatus;
};


export type QueryRecruiterViewableJobApplicationsArgs = {
  jobId: Scalars['ID'];
};


export type QuerySkillArgs = {
  id: Scalars['ID'];
};


export type QuerySkillsArgs = {
  sortNameBy?: InputMaybe<SortOrder>;
};

/** Question used in questionnaires */
export type Question = {
  __typename?: 'Question';
  _id: Scalars['ObjectID'];
  optionGroupId?: Maybe<Scalars['ObjectID']>;
  options: Array<QuestionOption>;
  singleVariableDetails?: Maybe<SingleVariableDetails>;
  title: Scalars['String'];
  twoVariablesDetails?: Maybe<TwoVariablesDetails>;
  type: QuestionType;
  userTarget: Role;
};

export type QuestionInput = {
  optionGroupId?: InputMaybe<Scalars['ObjectID']>;
  options?: InputMaybe<Array<QuestionOptionInput>>;
  singleVariableDetails?: InputMaybe<SingleVariableDetailsInput>;
  title: Scalars['String'];
  twoVariablesDetails?: InputMaybe<TwoVariablesDetailsInput>;
  type: QuestionType;
  userTarget: Role;
};

/** An option in a question */
export type QuestionOption = {
  __typename?: 'QuestionOption';
  _id: Scalars['ObjectID'];
  score?: Maybe<Scalars['Float']>;
  text: Scalars['String'];
};

export type QuestionOptionInput = {
  score?: InputMaybe<Scalars['Float']>;
  text: Scalars['String'];
};

/** Type of questionnaire question */
export enum QuestionType {
  CHECKBOX = 'CHECKBOX',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  SINGLE_VARIABLE = 'SINGLE_VARIABLE',
  TEXT_LONG = 'TEXT_LONG',
  TEXT_SHORT = 'TEXT_SHORT',
  TWO_VARIABLES = 'TWO_VARIABLES'
}

/** Questionnaire used to help the matching */
export type Questionnaire = {
  __typename?: 'Questionnaire';
  _id: Scalars['ObjectID'];
  industryId: Scalars['ObjectID'];
  industryName: Scalars['String'];
  nonVarQuestionsIds?: Maybe<Array<Maybe<Scalars['ObjectID']>>>;
  singleVarConfigs?: Maybe<Array<Maybe<SingleVarConfig>>>;
  twoVarIds?: Maybe<Array<Maybe<Scalars['ObjectID']>>>;
  twoVariables?: Maybe<Array<Maybe<QuestionnaireVariable>>>;
  userTarget: Role;
};

export type QuestionnaireAnswerInput = {
  optionId?: InputMaybe<Scalars['ObjectID']>;
  questionId: Scalars['ObjectID'];
};

export type QuestionnaireInput = {
  industryId: Scalars['ObjectID'];
  nonVarQuestionsIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectID']>>>;
  singleVarConfigs?: InputMaybe<Array<InputMaybe<SingleVarConfigInput>>>;
  twoVarIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectID']>>>;
  userTarget: Role;
};

/** User's questionnaire result object */
export type QuestionnaireResult = {
  __typename?: 'QuestionnaireResult';
  _id: Scalars['ObjectID'];
  answers: Array<Maybe<Answer>>;
  completed: Scalars['Boolean'];
  questionnaireId: Scalars['ObjectID'];
  uid: Scalars['String'];
};

/** A variable to be measured using questionnaire */
export type QuestionnaireVariable = {
  __typename?: 'QuestionnaireVariable';
  _id: Scalars['ObjectID'];
  name: Scalars['String'];
};

/** Recruiter user of the platform */
export type Recruiter = {
  __typename?: 'Recruiter';
  _id: Scalars['ID'];
  avatarURL?: Maybe<Scalars['URL']>;
  backgroundURL?: Maybe<Scalars['URL']>;
  company?: Maybe<Company>;
  companyId: Scalars['ObjectID'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  industryId?: Maybe<Scalars['ObjectID']>;
  lastName: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

/** User role */
export enum Role {
  ADMIN = 'ADMIN',
  APPLICANT = 'APPLICANT',
  RECRUITER = 'RECRUITER'
}

/** Configuration object for single variables within questionnaire */
export type SingleVarConfig = {
  __typename?: 'SingleVarConfig';
  numQuestions?: Maybe<Scalars['PositiveInt']>;
  variable: QuestionnaireVariable;
  variableId: Scalars['ObjectID'];
};

export type SingleVarConfigInput = {
  numQuestions?: InputMaybe<Scalars['PositiveInt']>;
  variableId: Scalars['ObjectID'];
};

/** Details for `singleVariable` type questions */
export type SingleVariableDetails = {
  __typename?: 'SingleVariableDetails';
  variableId: Scalars['ObjectID'];
  variableName: Scalars['String'];
  variableResult: VariableResult;
};

export type SingleVariableDetailsInput = {
  variableId: Scalars['ObjectID'];
  variableResult: VariableResult;
};

/** Skills for job template */
export type Skill = {
  __typename?: 'Skill';
  _id: Scalars['ObjectID'];
  name: Scalars['String'];
};

export type SkillFieldsInput = {
  name: Scalars['String'];
};

/** Sort order for appropriate queries */
export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type Subscription = {
  __typename?: 'Subscription';
  /** Add a new notification for a specific user. */
  notificationAdded?: Maybe<Notification>;
};


export type SubscriptionNotificationAddedArgs = {
  receiver: Scalars['ID'];
};

/** Recruiter subscription plans */
export enum SubscriptionPlan {
  Basic = 'Basic'
}

/** Details for `twoVariables` type questions */
export type TwoVariablesDetails = {
  __typename?: 'TwoVariablesDetails';
  negativeVariableId: Scalars['ObjectID'];
  negativeVariableName: Scalars['String'];
  positiveVariableId: Scalars['ObjectID'];
  positiveVariableName: Scalars['String'];
};

export type TwoVariablesDetailsInput = {
  negativeVariableId: Scalars['ObjectID'];
  positiveVariableId: Scalars['ObjectID'];
};

/** Variable result type */
export enum VariableResult {
  NEGATIVE = 'NEGATIVE',
  PLACEBO = 'PLACEBO',
  POSITIVE = 'POSITIVE'
}

export type CompanyFieldsFragment = { __typename?: 'Company', _id: string, avatarURL?: string, backgroundURL?: string, name?: string, overview?: string, headquarters?: string, locations?: Array<string>, website?: string, companySize?: number, industryId?: string, industryName?: string };

export type RecruiterFieldsFragment = { __typename?: 'Recruiter', _id: string, avatarURL?: string, backgroundURL?: string, firstName: string, lastName: string, summary?: string, headline?: string, companyId: string, industryId?: string };

export type NotificationFieldsFragment = { __typename?: 'Notification', _id: string, message?: string, read?: boolean, sender?: string, createdAt?: Date, redirectLink?: string, avatarURL?: string, avatarVariant?: string };

export type JobBaseFieldsFragment = { __typename?: 'Job', _id: string, status?: JobStatus, positionId: string, employmentType: EmploymentType, recruiterId: string, companyId: string, description: string, language?: any, languageLevel?: LanguageProficiencyType, salary?: any, location: string, yearsOfExperience?: any, filterDeadline: Date, maxAppliedApplicants: any, maxFilteredApplicants: any, skills?: Array<string>, certifications?: Array<string> };

export type JobFieldsFragment = { __typename?: 'Job', _id: string, status?: JobStatus, positionId: string, employmentType: EmploymentType, recruiterId: string, companyId: string, description: string, language?: any, languageLevel?: LanguageProficiencyType, salary?: any, location: string, yearsOfExperience?: any, filterDeadline: Date, maxAppliedApplicants: any, maxFilteredApplicants: any, skills?: Array<string>, certifications?: Array<string>, position?: { __typename?: 'Position', _id: string, name?: string }, company?: { __typename?: 'Company', _id: string, name?: string, avatarURL?: string } };

export type JobFieldsWithInterviewFragment = { __typename?: 'Job', _id: string, status?: JobStatus, positionId: string, employmentType: EmploymentType, recruiterId: string, companyId: string, description: string, language?: any, languageLevel?: LanguageProficiencyType, salary?: any, location: string, yearsOfExperience?: any, filterDeadline: Date, maxAppliedApplicants: any, maxFilteredApplicants: any, skills?: Array<string>, certifications?: Array<string>, interviewDetails?: { __typename?: 'InterviewDetails', location?: string, minimumNotice?: number, description?: string }, position?: { __typename?: 'Position', _id: string, name?: string }, company?: { __typename?: 'Company', _id: string, name?: string, avatarURL?: string } };

export type FeedbackFieldsFragment = { __typename?: 'Feedback', _id: string, sender?: string, message?: string, rating?: any };

export type IssueFieldsFragment = { __typename?: 'Issue', _id: string, sender?: string, message?: string };

export type IndustryFieldsFragment = { __typename?: 'Industry', _id: string, name: string };

export type PositionFieldsFragment = { __typename?: 'Position', _id: string, name?: string, industries?: Array<string> };

export type SkillFieldsFragment = { __typename?: 'Skill', _id: string, name: string };

export type CertificationFieldsFragment = { __typename?: 'Certification', _id: string, name?: string };

export type JobTemplateFieldsFragment = { __typename?: 'JobTemplate', _id: string, positionId: string, description: string, certifications?: Array<string>, skills?: Array<string> };

export type InterviewBaseFieldsFragment = { __typename?: 'Interview', _id: string, jobId: string, start?: Date, end?: Date };

export type InterviewFieldsFragment = { __typename?: 'Interview', applicantId?: string, _id: string, jobId: string, start?: Date, end?: Date };

export type ApplicantIntroFieldsFragment = { __typename?: 'Applicant', _id: string, firstName: string, lastName: string, summary?: string, avatarURL?: string, headline?: string, backgroundURL?: string, industryId?: string };

export type EducationFieldsFragment = { __typename?: 'Education', _id: string, institution: string, degree: EducationDegree, startDate: Date, endDate: Date, field?: string, grade?: string, description?: string };

export type ExperienceFieldsFragment = { __typename?: 'Experience', _id: string, position: string, employmentType?: EmploymentType, company: string, companyId?: string, companyName?: string, companyAvatarURL?: string, description?: string, startDate: Date, endDate?: Date };

export type ApplicantCertificationFieldsFragment = { __typename?: 'ApplicantCertification', _id: string, name?: string, issuingOrganization: string, issueDate?: Date, expirationDate?: Date, credentialID?: string, credentialURL?: string };

export type JobPreferencesFieldsFragment = { __typename?: 'JobPreferences', seeking?: boolean, jobTypes?: Array<EmploymentType>, positionsIds?: Array<string> };

export type ApplicantFieldsFragment = { __typename?: 'Applicant', email?: string, resumeURL?: string, industryId?: string, skills?: Array<string>, _id: string, firstName: string, lastName: string, summary?: string, avatarURL?: string, headline?: string, backgroundURL?: string, jobPreferences?: { __typename?: 'JobPreferences', seeking?: boolean, jobTypes?: Array<EmploymentType>, positionsIds?: Array<string> }, experience?: Array<{ __typename?: 'Experience', _id: string, position: string, employmentType?: EmploymentType, company: string, companyId?: string, companyName?: string, companyAvatarURL?: string, description?: string, startDate: Date, endDate?: Date }>, education?: Array<{ __typename?: 'Education', _id: string, institution: string, degree: EducationDegree, startDate: Date, endDate: Date, field?: string, grade?: string, description?: string }>, certifications?: Array<{ __typename?: 'ApplicantCertification', _id: string, name?: string, issuingOrganization: string, issueDate?: Date, expirationDate?: Date, credentialID?: string, credentialURL?: string }> };

export type ApplicantPreviewFieldsFragment = { __typename?: 'Applicant', _id: string, avatarURL?: string, firstName: string, lastName: string, fullName?: string, headline?: string };

export type ApplicationWithJobFieldsFragment = { __typename?: 'Application', _id: string, applicantId?: string, status?: ApplicationStatus, updatedAt?: Date, job?: { __typename?: 'Job', _id: string, status?: JobStatus, positionId: string, employmentType: EmploymentType, recruiterId: string, companyId: string, description: string, language?: any, languageLevel?: LanguageProficiencyType, salary?: any, location: string, yearsOfExperience?: any, filterDeadline: Date, maxAppliedApplicants: any, maxFilteredApplicants: any, skills?: Array<string>, certifications?: Array<string>, position?: { __typename?: 'Position', _id: string, name?: string }, company?: { __typename?: 'Company', _id: string, name?: string, avatarURL?: string } } };

export type ApplicationWithApplicantFieldsFragment = { __typename?: 'Application', _id: string, status?: ApplicationStatus, updatedAt?: Date, applicant?: { __typename?: 'Applicant', _id: string, avatarURL?: string, firstName: string, lastName: string, fullName?: string, headline?: string } };

export type ApplicationUpdateFragmentFragment = { __typename?: 'Application', _id: string, status?: ApplicationStatus, updatedAt?: Date };

export type QuestionOptionFieldsFragment = { __typename?: 'QuestionOption', _id: string, text: string };

export type QuestionnaireBaseAnswerFieldsFragment = { __typename?: 'Answer', optionId?: string, questionId: string };

export type QuestionnaireAnswerFieldsFragment = { __typename?: 'Answer', optionId?: string, questionId: string, question: { __typename?: 'Question', _id: string, title: string, type: QuestionType, optionGroupId?: string, singleVariableDetails?: { __typename?: 'SingleVariableDetails', variableName: string }, options: Array<{ __typename?: 'QuestionOption', _id: string, text: string }> } };

export type QuestionnaireResultFieldsFragment = { __typename?: 'QuestionnaireResult', _id: string, questionnaireId: string, completed: boolean, answers: Array<{ __typename?: 'Answer', optionId?: string, questionId: string, question: { __typename?: 'Question', _id: string, title: string, type: QuestionType, optionGroupId?: string, singleVariableDetails?: { __typename?: 'SingleVariableDetails', variableName: string }, options: Array<{ __typename?: 'QuestionOption', _id: string, text: string }> } }> };

export type ApplicantQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ApplicantQuery = { __typename?: 'Query', applicant?: { __typename?: 'Applicant', email?: string, resumeURL?: string, industryId?: string, skills?: Array<string>, _id: string, firstName: string, lastName: string, summary?: string, avatarURL?: string, headline?: string, backgroundURL?: string, jobPreferences?: { __typename?: 'JobPreferences', seeking?: boolean, jobTypes?: Array<EmploymentType>, positionsIds?: Array<string> }, experience?: Array<{ __typename?: 'Experience', _id: string, position: string, employmentType?: EmploymentType, company: string, companyId?: string, companyName?: string, companyAvatarURL?: string, description?: string, startDate: Date, endDate?: Date }>, education?: Array<{ __typename?: 'Education', _id: string, institution: string, degree: EducationDegree, startDate: Date, endDate: Date, field?: string, grade?: string, description?: string }>, certifications?: Array<{ __typename?: 'ApplicantCertification', _id: string, name?: string, issuingOrganization: string, issueDate?: Date, expirationDate?: Date, credentialID?: string, credentialURL?: string }> } };

export type SignUpApplicantMutationVariables = Exact<{
  signUpInput: ApplicantRecruiterSignUpInput;
}>;


export type SignUpApplicantMutation = { __typename?: 'Mutation', signUpApplicant?: { __typename?: 'Applicant', _id: string, firstName: string, lastName: string, summary?: string, avatarURL?: string, headline?: string, backgroundURL?: string, industryId?: string } };

export type CreateApplicantProfileMutationVariables = Exact<{
  uid: Scalars['String'];
}>;


export type CreateApplicantProfileMutation = { __typename?: 'Mutation', createApplicantProfile?: { __typename?: 'Applicant', _id: string, firstName: string, lastName: string, summary?: string, avatarURL?: string, headline?: string, backgroundURL?: string, industryId?: string } };

export type UpdateApplicantIntroMutationVariables = Exact<{
  applicantRecruiterIntroInput: ApplicantRecruiterIntroInput;
}>;


export type UpdateApplicantIntroMutation = { __typename?: 'Mutation', updateApplicant?: { __typename?: 'Applicant', _id: string, firstName: string, lastName: string, summary?: string, avatarURL?: string, headline?: string, backgroundURL?: string, industryId?: string } };

export type UpdateApplicantJobPreferencesMutationVariables = Exact<{
  jobPreferences: JobPreferencesInput;
}>;


export type UpdateApplicantJobPreferencesMutation = { __typename?: 'Mutation', updateApplicantJobPreferences?: { __typename?: 'Applicant', _id: string, jobPreferences?: { __typename?: 'JobPreferences', seeking?: boolean, jobTypes?: Array<EmploymentType>, positionsIds?: Array<string> } } };

export type UpdateApplicantResumeMutationVariables = Exact<{
  resumeFile: Scalars['Upload'];
}>;


export type UpdateApplicantResumeMutation = { __typename?: 'Mutation', updateApplicant?: { __typename?: 'Applicant', _id: string, resumeURL?: string } };

export type RemoveApplicantResumeMutationVariables = Exact<{ [key: string]: never; }>;


export type RemoveApplicantResumeMutation = { __typename?: 'Mutation', updateApplicant?: { __typename?: 'Applicant', _id: string, resumeURL?: string } };

export type AddApplicantExperienceMutationVariables = Exact<{
  experienceFields: ExperienceInput;
}>;


export type AddApplicantExperienceMutation = { __typename?: 'Mutation', addApplicantExperience?: { __typename?: 'Experience', _id: string, position: string, employmentType?: EmploymentType, company: string, companyId?: string, companyName?: string, companyAvatarURL?: string, description?: string, startDate: Date, endDate?: Date } };

export type UpdateApplicantExperienceMutationVariables = Exact<{
  id: Scalars['ID'];
  experienceFields: ExperienceInput;
}>;


export type UpdateApplicantExperienceMutation = { __typename?: 'Mutation', updateApplicantExperience?: { __typename?: 'Experience', _id: string, position: string, employmentType?: EmploymentType, company: string, companyId?: string, companyName?: string, companyAvatarURL?: string, description?: string, startDate: Date, endDate?: Date } };

export type DeleteApplicantExperienceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteApplicantExperienceMutation = { __typename?: 'Mutation', deleteApplicantExperience?: { __typename?: 'Experience', _id: string, position: string, employmentType?: EmploymentType, company: string, companyId?: string, companyName?: string, companyAvatarURL?: string, description?: string, startDate: Date, endDate?: Date } };

export type AddApplicantEducationMutationVariables = Exact<{
  educationFields: EducationInput;
}>;


export type AddApplicantEducationMutation = { __typename?: 'Mutation', addApplicantEducation?: { __typename?: 'Education', _id: string, institution: string, degree: EducationDegree, startDate: Date, endDate: Date, field?: string, grade?: string, description?: string } };

export type UpdateApplicantEducationMutationVariables = Exact<{
  id: Scalars['ID'];
  educationFields: EducationInput;
}>;


export type UpdateApplicantEducationMutation = { __typename?: 'Mutation', updateApplicantEducation?: { __typename?: 'Education', _id: string, institution: string, degree: EducationDegree, startDate: Date, endDate: Date, field?: string, grade?: string, description?: string } };

export type DeleteApplicantEducationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteApplicantEducationMutation = { __typename?: 'Mutation', deleteApplicantEducation?: { __typename?: 'Education', _id: string, institution: string, degree: EducationDegree, startDate: Date, endDate: Date, field?: string, grade?: string, description?: string } };

export type AddApplicantCertificationMutationVariables = Exact<{
  _id: Scalars['ObjectID'];
  certificationFields: ApplicantCertificationInput;
}>;


export type AddApplicantCertificationMutation = { __typename?: 'Mutation', addApplicantCertification?: { __typename?: 'ApplicantCertification', _id: string, name?: string, issuingOrganization: string, issueDate?: Date, expirationDate?: Date, credentialID?: string, credentialURL?: string } };

export type UpdateApplicantCertificationMutationVariables = Exact<{
  id: Scalars['ID'];
  certificationFields: ApplicantCertificationInput;
}>;


export type UpdateApplicantCertificationMutation = { __typename?: 'Mutation', updateApplicantCertification?: { __typename?: 'ApplicantCertification', _id: string, name?: string, issuingOrganization: string, issueDate?: Date, expirationDate?: Date, credentialID?: string, credentialURL?: string } };

export type DeleteApplicantCertificationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteApplicantCertificationMutation = { __typename?: 'Mutation', deleteApplicantCertification?: { __typename?: 'ApplicantCertification', _id: string, name?: string, issuingOrganization: string, issueDate?: Date, expirationDate?: Date, credentialID?: string, credentialURL?: string } };

export type UpdateApplicantSkillsMutationVariables = Exact<{
  skills?: InputMaybe<Array<Scalars['ObjectID']> | Scalars['ObjectID']>;
}>;


export type UpdateApplicantSkillsMutation = { __typename?: 'Mutation', updateApplicant?: { __typename?: 'Applicant', _id: string, skills?: Array<string> } };

export type ApplicantApplicationQueryVariables = Exact<{
  jobId: Scalars['ID'];
}>;


export type ApplicantApplicationQuery = { __typename?: 'Query', applicantApplication?: { __typename?: 'Application', _id: string, applicantId?: string, status?: ApplicationStatus, updatedAt?: Date, job?: { __typename?: 'Job', _id: string, status?: JobStatus, positionId: string, employmentType: EmploymentType, recruiterId: string, companyId: string, description: string, language?: any, languageLevel?: LanguageProficiencyType, salary?: any, location: string, yearsOfExperience?: any, filterDeadline: Date, maxAppliedApplicants: any, maxFilteredApplicants: any, skills?: Array<string>, certifications?: Array<string>, position?: { __typename?: 'Position', _id: string, name?: string }, company?: { __typename?: 'Company', _id: string, name?: string, avatarURL?: string } } } };

export type ApplicantCreatedApplicationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ApplicantCreatedApplicationsQuery = { __typename?: 'Query', applicantCreatedApplications?: Array<{ __typename?: 'Application', _id: string, applicantId?: string, status?: ApplicationStatus, updatedAt?: Date, job?: { __typename?: 'Job', _id: string, status?: JobStatus, positionId: string, employmentType: EmploymentType, recruiterId: string, companyId: string, description: string, language?: any, languageLevel?: LanguageProficiencyType, salary?: any, location: string, yearsOfExperience?: any, filterDeadline: Date, maxAppliedApplicants: any, maxFilteredApplicants: any, skills?: Array<string>, certifications?: Array<string>, position?: { __typename?: 'Position', _id: string, name?: string }, company?: { __typename?: 'Company', _id: string, name?: string, avatarURL?: string } } }> };

export type ApplicantViewableApplicationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ApplicantViewableApplicationsQuery = { __typename?: 'Query', applicantViewableApplications?: Array<{ __typename?: 'Application', _id: string, applicantId?: string, status?: ApplicationStatus, updatedAt?: Date, job?: { __typename?: 'Job', _id: string, status?: JobStatus, positionId: string, employmentType: EmploymentType, recruiterId: string, companyId: string, description: string, language?: any, languageLevel?: LanguageProficiencyType, salary?: any, location: string, yearsOfExperience?: any, filterDeadline: Date, maxAppliedApplicants: any, maxFilteredApplicants: any, skills?: Array<string>, certifications?: Array<string>, position?: { __typename?: 'Position', _id: string, name?: string }, company?: { __typename?: 'Company', _id: string, name?: string, avatarURL?: string } } }> };

export type RecruiterViewableJobApplicationsQueryVariables = Exact<{
  jobId: Scalars['ID'];
}>;


export type RecruiterViewableJobApplicationsQuery = { __typename?: 'Query', recruiterViewableJobApplications?: Array<{ __typename?: 'Application', _id: string, status?: ApplicationStatus, updatedAt?: Date, applicant?: { __typename?: 'Applicant', _id: string, avatarURL?: string, firstName: string, lastName: string, fullName?: string, headline?: string } }> };

export type RecruiterApplicationsByStatusQueryVariables = Exact<{
  status: ApplicationStatus;
}>;


export type RecruiterApplicationsByStatusQuery = { __typename?: 'Query', recruiterApplicationsByStatus?: Array<{ __typename?: 'Application', _id: string, status?: ApplicationStatus, applicant?: { __typename?: 'Applicant', _id: string, avatarURL?: string, firstName: string, lastName: string, fullName?: string, headline?: string }, job?: { __typename?: 'Job', _id: string, status?: JobStatus, positionId: string, employmentType: EmploymentType, recruiterId: string, companyId: string, description: string, language?: any, languageLevel?: LanguageProficiencyType, salary?: any, location: string, yearsOfExperience?: any, filterDeadline: Date, maxAppliedApplicants: any, maxFilteredApplicants: any, skills?: Array<string>, certifications?: Array<string> } }> };

export type RemoveApplicationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveApplicationMutation = { __typename?: 'Mutation', removeApplication?: { __typename?: 'Application', _id: string } };

export type UpdateApplicationStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  status: ApplicationStatus;
}>;


export type UpdateApplicationStatusMutation = { __typename?: 'Mutation', updateApplicationStatus?: { __typename?: 'Application', _id: string, status?: ApplicationStatus, updatedAt?: Date } };

export type ShowInterestInJobMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ShowInterestInJobMutation = { __typename?: 'Mutation', updateApplicationStatus?: { __typename?: 'Application', _id: string, status?: ApplicationStatus, updatedAt?: Date } };

export type ConfirmInterestInJobMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ConfirmInterestInJobMutation = { __typename?: 'Mutation', updateApplicationStatus?: { __typename?: 'Application', _id: string, status?: ApplicationStatus, updatedAt?: Date } };

export type SelectForInterviewMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SelectForInterviewMutation = { __typename?: 'Mutation', updateApplicationStatus?: { __typename?: 'Application', _id: string, status?: ApplicationStatus, updatedAt?: Date } };

export type HireApplicantMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type HireApplicantMutation = { __typename?: 'Mutation', updateApplicationStatus?: { __typename?: 'Application', _id: string, status?: ApplicationStatus, updatedAt?: Date } };

export type CertificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type CertificationsQuery = { __typename?: 'Query', certifications?: Array<{ __typename?: 'Certification', _id: string, name?: string }> };

export type CompanyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CompanyQuery = { __typename?: 'Query', company?: { __typename?: 'Company', _id: string, avatarURL?: string, backgroundURL?: string, name?: string, overview?: string, headquarters?: string, locations?: Array<string>, website?: string, companySize?: number, industryId?: string, industryName?: string } };

export type AutocompleteCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type AutocompleteCompaniesQuery = { __typename?: 'Query', companies?: Array<{ __typename?: 'Company', _id: string, name?: string, avatarURL?: string }> };

export type CurrentRecruiterCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentRecruiterCompanyQuery = { __typename?: 'Query', currentRecruiterCompany?: { __typename?: 'Company', _id: string, name?: string, avatarURL?: string } };

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['ID'];
  avatarFile?: InputMaybe<Scalars['Upload']>;
  backgroundFile?: InputMaybe<Scalars['Upload']>;
  basicInfo?: InputMaybe<CompanyBasicInfoInput>;
}>;


export type UpdateCompanyMutation = { __typename?: 'Mutation', updateCompany?: { __typename?: 'Company', _id: string, avatarURL?: string, backgroundURL?: string, name?: string, overview?: string, headquarters?: string, locations?: Array<string>, website?: string, companySize?: number, industryId?: string, industryName?: string } };

export type LastFeedbackQueryVariables = Exact<{ [key: string]: never; }>;


export type LastFeedbackQuery = { __typename?: 'Query', lastFeedback?: { __typename?: 'Feedback', createdAt?: Date } };

export type FeedbackIntervalStringQueryVariables = Exact<{ [key: string]: never; }>;


export type FeedbackIntervalStringQuery = { __typename?: 'Query', feedbackIntervalString?: string };

export type CreateFeedbackMutationVariables = Exact<{
  message: Scalars['String'];
  rating?: InputMaybe<Scalars['NonNegativeInt']>;
}>;


export type CreateFeedbackMutation = { __typename?: 'Mutation', createFeedback?: { __typename?: 'Feedback', _id: string, sender?: string, message?: string, rating?: any } };

export type IndustriesQueryVariables = Exact<{ [key: string]: never; }>;


export type IndustriesQuery = { __typename?: 'Query', industries?: Array<{ __typename?: 'Industry', _id: string, name: string }> };

export type InterviewQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type InterviewQuery = { __typename?: 'Query', interview?: { __typename?: 'Interview', applicantId?: string, _id: string, jobId: string, start?: Date, end?: Date } };

export type InterviewsForApplicantQueryVariables = Exact<{ [key: string]: never; }>;


export type InterviewsForApplicantQuery = { __typename?: 'Query', interviewsForApplicant?: Array<{ __typename?: 'Interview', applicantId?: string, _id: string, jobId: string, start?: Date, end?: Date }> };

export type InterviewsForJobQueryVariables = Exact<{
  jobId: Scalars['ObjectID'];
}>;


export type InterviewsForJobQuery = { __typename?: 'Query', interviewsForJob?: Array<{ __typename?: 'Interview', applicantId?: string, _id: string, jobId: string, start?: Date, end?: Date }> };

export type CreateInterviewMutationVariables = Exact<{
  interviewFields: InterviewFieldsInput;
}>;


export type CreateInterviewMutation = { __typename?: 'Mutation', createInterview?: { __typename?: 'Interview', applicantId?: string, _id: string, jobId: string, start?: Date, end?: Date } };

export type SelectInterviewMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SelectInterviewMutation = { __typename?: 'Mutation', selectInterview?: { __typename?: 'Interview', applicantId?: string, _id: string, jobId: string, start?: Date, end?: Date } };

export type DeleteInterviewMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteInterviewMutation = { __typename?: 'Mutation', deleteInterview?: { __typename?: 'Interview', applicantId?: string, _id: string, jobId: string, start?: Date, end?: Date } };

export type CreateIssueMutationVariables = Exact<{
  message: Scalars['String'];
}>;


export type CreateIssueMutation = { __typename?: 'Mutation', createIssue: { __typename?: 'Issue', _id: string, sender?: string, message?: string } };

export type JobBasicQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type JobBasicQuery = { __typename?: 'Query', job?: { __typename?: 'Job', _id: string, positionId: string, location: string } };

export type JobWithCompanyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type JobWithCompanyQuery = { __typename?: 'Query', job?: { __typename?: 'Job', _id: string, status?: JobStatus, positionId: string, employmentType: EmploymentType, recruiterId: string, companyId: string, description: string, language?: any, languageLevel?: LanguageProficiencyType, salary?: any, location: string, yearsOfExperience?: any, filterDeadline: Date, maxAppliedApplicants: any, maxFilteredApplicants: any, skills?: Array<string>, certifications?: Array<string>, position?: { __typename?: 'Position', _id: string, name?: string }, company?: { __typename?: 'Company', _id: string, name?: string, avatarURL?: string } } };

export type JobWithInterviewQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type JobWithInterviewQuery = { __typename?: 'Query', job?: { __typename?: 'Job', _id: string, status?: JobStatus, positionId: string, employmentType: EmploymentType, recruiterId: string, companyId: string, description: string, language?: any, languageLevel?: LanguageProficiencyType, salary?: any, location: string, yearsOfExperience?: any, filterDeadline: Date, maxAppliedApplicants: any, maxFilteredApplicants: any, skills?: Array<string>, certifications?: Array<string>, interviewDetails?: { __typename?: 'InterviewDetails', location?: string, minimumNotice?: number, description?: string }, position?: { __typename?: 'Position', _id: string, name?: string }, company?: { __typename?: 'Company', _id: string, name?: string, avatarURL?: string } } };

export type RecruiterJobsQueryVariables = Exact<{ [key: string]: never; }>;


export type RecruiterJobsQuery = { __typename?: 'Query', recruiterJobs?: Array<{ __typename?: 'Job', _id: string, status?: JobStatus, positionId: string, employmentType: EmploymentType, recruiterId: string, companyId: string, description: string, language?: any, languageLevel?: LanguageProficiencyType, salary?: any, location: string, yearsOfExperience?: any, filterDeadline: Date, maxAppliedApplicants: any, maxFilteredApplicants: any, skills?: Array<string>, certifications?: Array<string>, position?: { __typename?: 'Position', _id: string, name?: string }, company?: { __typename?: 'Company', _id: string, name?: string, avatarURL?: string } }> };

export type MatchedJobsQueryVariables = Exact<{ [key: string]: never; }>;


export type MatchedJobsQuery = { __typename?: 'Query', matchedJobs?: Array<{ __typename?: 'Job', _id: string, status?: JobStatus, positionId: string, employmentType: EmploymentType, recruiterId: string, companyId: string, description: string, language?: any, languageLevel?: LanguageProficiencyType, salary?: any, location: string, yearsOfExperience?: any, filterDeadline: Date, maxAppliedApplicants: any, maxFilteredApplicants: any, skills?: Array<string>, certifications?: Array<string>, position?: { __typename?: 'Position', _id: string, name?: string }, company?: { __typename?: 'Company', _id: string, name?: string, avatarURL?: string } }> };

export type CreateJobMutationVariables = Exact<{
  jobFields: JobFieldsInput;
}>;


export type CreateJobMutation = { __typename?: 'Mutation', createJob?: { __typename?: 'Job', _id: string, status?: JobStatus, positionId: string, employmentType: EmploymentType, recruiterId: string, companyId: string, description: string, language?: any, languageLevel?: LanguageProficiencyType, salary?: any, location: string, yearsOfExperience?: any, filterDeadline: Date, maxAppliedApplicants: any, maxFilteredApplicants: any, skills?: Array<string>, certifications?: Array<string>, position?: { __typename?: 'Position', _id: string, name?: string }, company?: { __typename?: 'Company', _id: string, name?: string, avatarURL?: string } } };

export type UpdateInterviewDetailsMutationVariables = Exact<{
  id: Scalars['ID'];
  interviewDetailsFields: InterviewDetailsFieldsInput;
}>;


export type UpdateInterviewDetailsMutation = { __typename?: 'Mutation', updateInterviewDetails?: { __typename?: 'Job', interviewDetails?: { __typename?: 'InterviewDetails', location?: string, minimumNotice?: number, description?: string } } };

export type JobTemplateByPositionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type JobTemplateByPositionQuery = { __typename?: 'Query', jobTemplateByPosition?: { __typename?: 'JobTemplate', _id: string, positionId: string, description: string, certifications?: Array<string>, skills?: Array<string> } };

export type NotificationsQueryVariables = Exact<{
  receiver: Scalars['ID'];
}>;


export type NotificationsQuery = { __typename?: 'Query', notifications?: Array<{ __typename?: 'Notification', _id: string, message?: string, read?: boolean, sender?: string, createdAt?: Date, redirectLink?: string, avatarURL?: string, avatarVariant?: string }> };

export type ReadNotificationsMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReadNotificationsMutation = { __typename?: 'Mutation', readNotification: { __typename?: 'Notification', _id: string, read?: boolean } };

export type SubscribeNotificationsSubscriptionVariables = Exact<{
  receiver: Scalars['ID'];
}>;


export type SubscribeNotificationsSubscription = { __typename?: 'Subscription', notificationAdded?: { __typename?: 'Notification', _id: string, message?: string, read?: boolean, sender?: string, createdAt?: Date, redirectLink?: string, avatarURL?: string, avatarVariant?: string } };

export type CreateSubscriptionCheckoutSessionMutationVariables = Exact<{
  plan: SubscriptionPlan;
  successUrl: Scalars['URL'];
  cancelUrl: Scalars['URL'];
}>;


export type CreateSubscriptionCheckoutSessionMutation = { __typename?: 'Mutation', createSubscriptionCheckoutSession: string };

export type ProcessSuccessfulCheckoutSessionMutationVariables = Exact<{
  sessionId: Scalars['ID'];
}>;


export type ProcessSuccessfulCheckoutSessionMutation = { __typename?: 'Mutation', processSuccessfulCheckoutSession: string };

export type CreateBillingPortalSessionMutationVariables = Exact<{
  returnUrl: Scalars['URL'];
}>;


export type CreateBillingPortalSessionMutation = { __typename?: 'Mutation', createBillingPortalSession: string };

export type PositionsQueryVariables = Exact<{
  industry?: InputMaybe<Scalars['ObjectID']>;
}>;


export type PositionsQuery = { __typename?: 'Query', positions?: Array<{ __typename?: 'Position', _id: string, name?: string, industries?: Array<string> }> };

export type PositionBasicQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PositionBasicQuery = { __typename?: 'Query', position?: { __typename?: 'Position', _id: string, name?: string, industries?: Array<string> } };

export type QuestionnaireResultQueryVariables = Exact<{ [key: string]: never; }>;


export type QuestionnaireResultQuery = { __typename?: 'Query', questionnaireResult: { __typename?: 'QuestionnaireResult', _id: string, questionnaireId: string, completed: boolean, answers: Array<{ __typename?: 'Answer', optionId?: string, questionId: string, question: { __typename?: 'Question', _id: string, title: string, type: QuestionType, optionGroupId?: string, singleVariableDetails?: { __typename?: 'SingleVariableDetails', variableName: string }, options: Array<{ __typename?: 'QuestionOption', _id: string, text: string }> } }> } };

export type QuestionnaireResultProgressQueryVariables = Exact<{ [key: string]: never; }>;


export type QuestionnaireResultProgressQuery = { __typename?: 'Query', questionnaireResultProgress: any };

export type UpdateQuestionnaireAnswersMutationVariables = Exact<{
  id: Scalars['ID'];
  answers: Array<InputMaybe<QuestionnaireAnswerInput>> | InputMaybe<QuestionnaireAnswerInput>;
}>;


export type UpdateQuestionnaireAnswersMutation = { __typename?: 'Mutation', updateQuestionnaireAnswers?: { __typename?: 'QuestionnaireResult', _id: string, answers: Array<{ __typename?: 'Answer', optionId?: string, questionId: string }> } };

export type RecruiterWithCompanyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RecruiterWithCompanyQuery = { __typename?: 'Query', recruiter?: { __typename?: 'Recruiter', _id: string, avatarURL?: string, backgroundURL?: string, firstName: string, lastName: string, summary?: string, headline?: string, companyId: string, industryId?: string, company?: { __typename?: 'Company', _id: string, avatarURL?: string, backgroundURL?: string, name?: string, overview?: string, headquarters?: string, locations?: Array<string>, website?: string, companySize?: number, industryId?: string, industryName?: string } } };

export type RecruiterWithoutCompanyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RecruiterWithoutCompanyQuery = { __typename?: 'Query', recruiter?: { __typename?: 'Recruiter', _id: string, avatarURL?: string, backgroundURL?: string, firstName: string, lastName: string, summary?: string, headline?: string, companyId: string, industryId?: string } };

export type SignUpRecruiterMutationVariables = Exact<{
  userInput: ApplicantRecruiterSignUpInput;
  companyInput: CompanySignUpInput;
}>;


export type SignUpRecruiterMutation = { __typename?: 'Mutation', signUpRecruiter?: { __typename?: 'Recruiter', _id: string, avatarURL?: string, backgroundURL?: string, firstName: string, lastName: string, summary?: string, headline?: string, companyId: string, industryId?: string } };

export type UpdateRecruiterMutationVariables = Exact<{
  applicantRecruiterIntroInput: ApplicantRecruiterIntroInput;
}>;


export type UpdateRecruiterMutation = { __typename?: 'Mutation', updateRecruiter?: { __typename?: 'Recruiter', _id: string, avatarURL?: string, backgroundURL?: string, firstName: string, lastName: string, summary?: string, headline?: string, companyId: string, industryId?: string } };

export type SkillsQueryVariables = Exact<{ [key: string]: never; }>;


export type SkillsQuery = { __typename?: 'Query', skills?: Array<{ __typename?: 'Skill', _id: string, name: string }> };

export const CompanyFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CompanyFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Company"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"avatarURL"}},{"kind":"Field","name":{"kind":"Name","value":"backgroundURL"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"overview"}},{"kind":"Field","name":{"kind":"Name","value":"headquarters"}},{"kind":"Field","name":{"kind":"Name","value":"locations"}},{"kind":"Field","name":{"kind":"Name","value":"website"}},{"kind":"Field","name":{"kind":"Name","value":"companySize"}},{"kind":"Field","name":{"kind":"Name","value":"industryId"}},{"kind":"Field","name":{"kind":"Name","value":"industryName"}}]}}]} as unknown as DocumentNode<CompanyFieldsFragment, unknown>;
export const RecruiterFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"RecruiterFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Recruiter"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"avatarURL"}},{"kind":"Field","name":{"kind":"Name","value":"backgroundURL"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"summary"}},{"kind":"Field","name":{"kind":"Name","value":"headline"}},{"kind":"Field","name":{"kind":"Name","value":"companyId"}},{"kind":"Field","name":{"kind":"Name","value":"industryId"}}]}}]} as unknown as DocumentNode<RecruiterFieldsFragment, unknown>;
export const NotificationFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"NotificationFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Notification"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"read"}},{"kind":"Field","name":{"kind":"Name","value":"sender"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"redirectLink"}},{"kind":"Field","name":{"kind":"Name","value":"avatarURL"}},{"kind":"Field","name":{"kind":"Name","value":"avatarVariant"}}]}}]} as unknown as DocumentNode<NotificationFieldsFragment, unknown>;
export const JobBaseFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"JobBaseFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Job"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"positionId"}},{"kind":"Field","name":{"kind":"Name","value":"employmentType"}},{"kind":"Field","name":{"kind":"Name","value":"recruiterId"}},{"kind":"Field","name":{"kind":"Name","value":"companyId"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"language"}},{"kind":"Field","name":{"kind":"Name","value":"languageLevel"}},{"kind":"Field","name":{"kind":"Name","value":"salary"}},{"kind":"Field","name":{"kind":"Name","value":"location"}},{"kind":"Field","name":{"kind":"Name","value":"yearsOfExperience"}},{"kind":"Field","name":{"kind":"Name","value":"filterDeadline"}},{"kind":"Field","name":{"kind":"Name","value":"maxAppliedApplicants"}},{"kind":"Field","name":{"kind":"Name","value":"maxFilteredApplicants"}},{"kind":"Field","name":{"kind":"Name","value":"skills"}},{"kind":"Field","name":{"kind":"Name","value":"certifications"}}]}}]} as unknown as DocumentNode<JobBaseFieldsFragment, unknown>;
export const JobFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"JobFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Job"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"JobBaseFields"}},{"kind":"Field","name":{"kind":"Name","value":"position"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"company"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"avatarURL"}}]}}]}},...JobBaseFieldsFragmentDoc.definitions]} as unknown as DocumentNode<JobFieldsFragment, unknown>;
export const JobFieldsWithInterviewFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"JobFieldsWithInterview"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Job"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"JobFields"}},{"kind":"Field","name":{"kind":"Name","value":"interviewDetails"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"location"}},{"kind":"Field","name":{"kind":"Name","value":"minimumNotice"}},{"kind":"Field","name":{"kind":"Name","value":"description"}}]}}]}},...JobFieldsFragmentDoc.definitions]} as unknown as DocumentNode<JobFieldsWithInterviewFragment, unknown>;
export const FeedbackFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FeedbackFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Feedback"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"sender"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"rating"}}]}}]} as unknown as DocumentNode<FeedbackFieldsFragment, unknown>;
export const IssueFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"IssueFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Issue"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"sender"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]} as unknown as DocumentNode<IssueFieldsFragment, unknown>;
export const IndustryFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"IndustryFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Industry"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]} as unknown as DocumentNode<IndustryFieldsFragment, unknown>;
export const PositionFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PositionFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Position"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"industries"}}]}}]} as unknown as DocumentNode<PositionFieldsFragment, unknown>;
export const SkillFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SkillFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Skill"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]} as unknown as DocumentNode<SkillFieldsFragment, unknown>;
export const CertificationFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CertificationFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Certification"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]} as unknown as DocumentNode<CertificationFieldsFragment, unknown>;
export const JobTemplateFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"JobTemplateFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"JobTemplate"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"positionId"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"certifications"}},{"kind":"Field","name":{"kind":"Name","value":"skills"}}]}}]} as unknown as DocumentNode<JobTemplateFieldsFragment, unknown>;
export const InterviewBaseFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"InterviewBaseFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Interview"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"jobId"}},{"kind":"Field","name":{"kind":"Name","value":"start"}},{"kind":"Field","name":{"kind":"Name","value":"end"}}]}}]} as unknown as DocumentNode<InterviewBaseFieldsFragment, unknown>;
export const InterviewFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"InterviewFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Interview"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"InterviewBaseFields"}},{"kind":"Field","name":{"kind":"Name","value":"applicantId"}}]}},...InterviewBaseFieldsFragmentDoc.definitions]} as unknown as DocumentNode<InterviewFieldsFragment, unknown>;
export const ApplicantIntroFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ApplicantIntroFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Applicant"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"summary"}},{"kind":"Field","name":{"kind":"Name","value":"avatarURL"}},{"kind":"Field","name":{"kind":"Name","value":"headline"}},{"kind":"Field","name":{"kind":"Name","value":"backgroundURL"}},{"kind":"Field","name":{"kind":"Name","value":"industryId"}}]}}]} as unknown as DocumentNode<ApplicantIntroFieldsFragment, unknown>;
export const JobPreferencesFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"JobPreferencesFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"JobPreferences"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"seeking"}},{"kind":"Field","name":{"kind":"Name","value":"jobTypes"}},{"kind":"Field","name":{"kind":"Name","value":"positionsIds"}}]}}]} as unknown as DocumentNode<JobPreferencesFieldsFragment, unknown>;
export const ExperienceFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ExperienceFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Experience"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"position"}},{"kind":"Field","name":{"kind":"Name","value":"employmentType"}},{"kind":"Field","name":{"kind":"Name","value":"company"}},{"kind":"Field","name":{"kind":"Name","value":"companyId"}},{"kind":"Field","name":{"kind":"Name","value":"companyName"}},{"kind":"Field","name":{"kind":"Name","value":"companyAvatarURL"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"startDate"}},{"kind":"Field","name":{"kind":"Name","value":"endDate"}}]}}]} as unknown as DocumentNode<ExperienceFieldsFragment, unknown>;
export const EducationFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"EducationFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Education"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"institution"}},{"kind":"Field","name":{"kind":"Name","value":"degree"}},{"kind":"Field","name":{"kind":"Name","value":"startDate"}},{"kind":"Field","name":{"kind":"Name","value":"endDate"}},{"kind":"Field","name":{"kind":"Name","value":"field"}},{"kind":"Field","name":{"kind":"Name","value":"grade"}},{"kind":"Field","name":{"kind":"Name","value":"description"}}]}}]} as unknown as DocumentNode<EducationFieldsFragment, unknown>;
export const ApplicantCertificationFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ApplicantCertificationFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ApplicantCertification"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"issuingOrganization"}},{"kind":"Field","name":{"kind":"Name","value":"issueDate"}},{"kind":"Field","name":{"kind":"Name","value":"expirationDate"}},{"kind":"Field","name":{"kind":"Name","value":"credentialID"}},{"kind":"Field","name":{"kind":"Name","value":"credentialURL"}}]}}]} as unknown as DocumentNode<ApplicantCertificationFieldsFragment, unknown>;
export const ApplicantFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ApplicantFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Applicant"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ApplicantIntroFields"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"resumeURL"}},{"kind":"Field","name":{"kind":"Name","value":"industryId"}},{"kind":"Field","name":{"kind":"Name","value":"jobPreferences"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"JobPreferencesFields"}}]}},{"kind":"Field","name":{"kind":"Name","value":"experience"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ExperienceFields"}}]}},{"kind":"Field","name":{"kind":"Name","value":"education"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"EducationFields"}}]}},{"kind":"Field","name":{"kind":"Name","value":"certifications"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ApplicantCertificationFields"}}]}},{"kind":"Field","name":{"kind":"Name","value":"skills"}}]}},...ApplicantIntroFieldsFragmentDoc.definitions,...JobPreferencesFieldsFragmentDoc.definitions,...ExperienceFieldsFragmentDoc.definitions,...EducationFieldsFragmentDoc.definitions,...ApplicantCertificationFieldsFragmentDoc.definitions]} as unknown as DocumentNode<ApplicantFieldsFragment, unknown>;
export const ApplicationWithJobFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ApplicationWithJobFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Application"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"applicantId"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"job"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"JobFields"}}]}}]}},...JobFieldsFragmentDoc.definitions]} as unknown as DocumentNode<ApplicationWithJobFieldsFragment, unknown>;
export const ApplicantPreviewFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ApplicantPreviewFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Applicant"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"avatarURL"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"fullName"}},{"kind":"Field","name":{"kind":"Name","value":"headline"}}]}}]} as unknown as DocumentNode<ApplicantPreviewFieldsFragment, unknown>;
export const ApplicationWithApplicantFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ApplicationWithApplicantFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Application"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"applicant"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ApplicantPreviewFields"}}]}}]}},...ApplicantPreviewFieldsFragmentDoc.definitions]} as unknown as DocumentNode<ApplicationWithApplicantFieldsFragment, unknown>;
export const ApplicationUpdateFragmentFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ApplicationUpdateFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Application"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}}]}}]} as unknown as DocumentNode<ApplicationUpdateFragmentFragment, unknown>;
export const QuestionnaireBaseAnswerFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"QuestionnaireBaseAnswerFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Answer"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"optionId"}},{"kind":"Field","name":{"kind":"Name","value":"questionId"}}]}}]} as unknown as DocumentNode<QuestionnaireBaseAnswerFieldsFragment, unknown>;
export const QuestionOptionFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"QuestionOptionFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"QuestionOption"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"text"}}]}}]} as unknown as DocumentNode<QuestionOptionFieldsFragment, unknown>;
export const QuestionnaireAnswerFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"QuestionnaireAnswerFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Answer"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"QuestionnaireBaseAnswerFields"}},{"kind":"Field","name":{"kind":"Name","value":"question"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"optionGroupId"}},{"kind":"Field","name":{"kind":"Name","value":"singleVariableDetails"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"variableName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"options"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"QuestionOptionFields"}}]}}]}}]}},...QuestionnaireBaseAnswerFieldsFragmentDoc.definitions,...QuestionOptionFieldsFragmentDoc.definitions]} as unknown as DocumentNode<QuestionnaireAnswerFieldsFragment, unknown>;
export const QuestionnaireResultFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"QuestionnaireResultFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"QuestionnaireResult"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"questionnaireId"}},{"kind":"Field","name":{"kind":"Name","value":"completed"}},{"kind":"Field","name":{"kind":"Name","value":"answers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"QuestionnaireAnswerFields"}}]}}]}},...QuestionnaireAnswerFieldsFragmentDoc.definitions]} as unknown as DocumentNode<QuestionnaireResultFieldsFragment, unknown>;
export const ApplicantDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Applicant"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"applicant"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ApplicantFields"}}]}}]}},...ApplicantFieldsFragmentDoc.definitions]} as unknown as DocumentNode<ApplicantQuery, ApplicantQueryVariables>;
export const SignUpApplicantDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SignUpApplicant"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"signUpInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ApplicantRecruiterSignUpInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"signUpApplicant"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"signUpInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"signUpInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ApplicantIntroFields"}}]}}]}},...ApplicantIntroFieldsFragmentDoc.definitions]} as unknown as DocumentNode<SignUpApplicantMutation, SignUpApplicantMutationVariables>;
export const CreateApplicantProfileDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateApplicantProfile"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"uid"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createApplicantProfile"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"uid"},"value":{"kind":"Variable","name":{"kind":"Name","value":"uid"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ApplicantIntroFields"}}]}}]}},...ApplicantIntroFieldsFragmentDoc.definitions]} as unknown as DocumentNode<CreateApplicantProfileMutation, CreateApplicantProfileMutationVariables>;
export const UpdateApplicantIntroDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateApplicantIntro"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"applicantRecruiterIntroInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ApplicantRecruiterIntroInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateApplicant"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"applicantRecruiterIntroInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"applicantRecruiterIntroInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ApplicantIntroFields"}}]}}]}},...ApplicantIntroFieldsFragmentDoc.definitions]} as unknown as DocumentNode<UpdateApplicantIntroMutation, UpdateApplicantIntroMutationVariables>;
export const UpdateApplicantJobPreferencesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateApplicantJobPreferences"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"jobPreferences"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"JobPreferencesInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateApplicantJobPreferences"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"jobPreferences"},"value":{"kind":"Variable","name":{"kind":"Name","value":"jobPreferences"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"jobPreferences"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"JobPreferencesFields"}}]}}]}}]}},...JobPreferencesFieldsFragmentDoc.definitions]} as unknown as DocumentNode<UpdateApplicantJobPreferencesMutation, UpdateApplicantJobPreferencesMutationVariables>;
export const UpdateApplicantResumeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateApplicantResume"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"resumeFile"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Upload"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateApplicant"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"resumeFile"},"value":{"kind":"Variable","name":{"kind":"Name","value":"resumeFile"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"resumeURL"}}]}}]}}]} as unknown as DocumentNode<UpdateApplicantResumeMutation, UpdateApplicantResumeMutationVariables>;
export const RemoveApplicantResumeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemoveApplicantResume"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateApplicant"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"resumeFile"},"value":{"kind":"NullValue"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"resumeURL"}}]}}]}}]} as unknown as DocumentNode<RemoveApplicantResumeMutation, RemoveApplicantResumeMutationVariables>;
export const AddApplicantExperienceDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddApplicantExperience"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"experienceFields"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ExperienceInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addApplicantExperience"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"experienceFields"},"value":{"kind":"Variable","name":{"kind":"Name","value":"experienceFields"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ExperienceFields"}}]}}]}},...ExperienceFieldsFragmentDoc.definitions]} as unknown as DocumentNode<AddApplicantExperienceMutation, AddApplicantExperienceMutationVariables>;
export const UpdateApplicantExperienceDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateApplicantExperience"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"experienceFields"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ExperienceInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateApplicantExperience"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"experienceFields"},"value":{"kind":"Variable","name":{"kind":"Name","value":"experienceFields"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ExperienceFields"}}]}}]}},...ExperienceFieldsFragmentDoc.definitions]} as unknown as DocumentNode<UpdateApplicantExperienceMutation, UpdateApplicantExperienceMutationVariables>;
export const DeleteApplicantExperienceDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteApplicantExperience"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteApplicantExperience"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ExperienceFields"}}]}}]}},...ExperienceFieldsFragmentDoc.definitions]} as unknown as DocumentNode<DeleteApplicantExperienceMutation, DeleteApplicantExperienceMutationVariables>;
export const AddApplicantEducationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddApplicantEducation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"educationFields"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EducationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addApplicantEducation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"educationFields"},"value":{"kind":"Variable","name":{"kind":"Name","value":"educationFields"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"EducationFields"}}]}}]}},...EducationFieldsFragmentDoc.definitions]} as unknown as DocumentNode<AddApplicantEducationMutation, AddApplicantEducationMutationVariables>;
export const UpdateApplicantEducationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateApplicantEducation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"educationFields"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EducationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateApplicantEducation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"educationFields"},"value":{"kind":"Variable","name":{"kind":"Name","value":"educationFields"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"EducationFields"}}]}}]}},...EducationFieldsFragmentDoc.definitions]} as unknown as DocumentNode<UpdateApplicantEducationMutation, UpdateApplicantEducationMutationVariables>;
export const DeleteApplicantEducationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteApplicantEducation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteApplicantEducation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"EducationFields"}}]}}]}},...EducationFieldsFragmentDoc.definitions]} as unknown as DocumentNode<DeleteApplicantEducationMutation, DeleteApplicantEducationMutationVariables>;
export const AddApplicantCertificationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddApplicantCertification"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"_id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ObjectID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"certificationFields"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ApplicantCertificationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addApplicantCertification"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"_id"}}},{"kind":"Argument","name":{"kind":"Name","value":"certificationFields"},"value":{"kind":"Variable","name":{"kind":"Name","value":"certificationFields"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ApplicantCertificationFields"}}]}}]}},...ApplicantCertificationFieldsFragmentDoc.definitions]} as unknown as DocumentNode<AddApplicantCertificationMutation, AddApplicantCertificationMutationVariables>;
export const UpdateApplicantCertificationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateApplicantCertification"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"certificationFields"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ApplicantCertificationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateApplicantCertification"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"certificationFields"},"value":{"kind":"Variable","name":{"kind":"Name","value":"certificationFields"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ApplicantCertificationFields"}}]}}]}},...ApplicantCertificationFieldsFragmentDoc.definitions]} as unknown as DocumentNode<UpdateApplicantCertificationMutation, UpdateApplicantCertificationMutationVariables>;
export const DeleteApplicantCertificationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteApplicantCertification"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteApplicantCertification"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ApplicantCertificationFields"}}]}}]}},...ApplicantCertificationFieldsFragmentDoc.definitions]} as unknown as DocumentNode<DeleteApplicantCertificationMutation, DeleteApplicantCertificationMutationVariables>;
export const UpdateApplicantSkillsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateApplicantSkills"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"skills"}},"type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ObjectID"}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateApplicant"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"skills"},"value":{"kind":"Variable","name":{"kind":"Name","value":"skills"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"skills"}}]}}]}}]} as unknown as DocumentNode<UpdateApplicantSkillsMutation, UpdateApplicantSkillsMutationVariables>;
export const ApplicantApplicationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ApplicantApplication"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"jobId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"applicantApplication"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"jobId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"jobId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ApplicationWithJobFields"}}]}}]}},...ApplicationWithJobFieldsFragmentDoc.definitions]} as unknown as DocumentNode<ApplicantApplicationQuery, ApplicantApplicationQueryVariables>;
export const ApplicantCreatedApplicationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ApplicantCreatedApplications"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"applicantCreatedApplications"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ApplicationWithJobFields"}}]}}]}},...ApplicationWithJobFieldsFragmentDoc.definitions]} as unknown as DocumentNode<ApplicantCreatedApplicationsQuery, ApplicantCreatedApplicationsQueryVariables>;
export const ApplicantViewableApplicationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ApplicantViewableApplications"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"applicantViewableApplications"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ApplicationWithJobFields"}}]}}]}},...ApplicationWithJobFieldsFragmentDoc.definitions]} as unknown as DocumentNode<ApplicantViewableApplicationsQuery, ApplicantViewableApplicationsQueryVariables>;
export const RecruiterViewableJobApplicationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"RecruiterViewableJobApplications"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"jobId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"recruiterViewableJobApplications"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"jobId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"jobId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ApplicationWithApplicantFields"}}]}}]}},...ApplicationWithApplicantFieldsFragmentDoc.definitions]} as unknown as DocumentNode<RecruiterViewableJobApplicationsQuery, RecruiterViewableJobApplicationsQueryVariables>;
export const RecruiterApplicationsByStatusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"RecruiterApplicationsByStatus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"status"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ApplicationStatus"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"recruiterApplicationsByStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"status"},"value":{"kind":"Variable","name":{"kind":"Name","value":"status"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"applicant"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ApplicantPreviewFields"}}]}},{"kind":"Field","name":{"kind":"Name","value":"job"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"JobBaseFields"}}]}}]}}]}},...ApplicantPreviewFieldsFragmentDoc.definitions,...JobBaseFieldsFragmentDoc.definitions]} as unknown as DocumentNode<RecruiterApplicationsByStatusQuery, RecruiterApplicationsByStatusQueryVariables>;
export const RemoveApplicationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemoveApplication"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removeApplication"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}}]}}]}}]} as unknown as DocumentNode<RemoveApplicationMutation, RemoveApplicationMutationVariables>;
export const UpdateApplicationStatusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateApplicationStatus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"status"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ApplicationStatus"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateApplicationStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"status"},"value":{"kind":"Variable","name":{"kind":"Name","value":"status"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ApplicationUpdateFragment"}}]}}]}},...ApplicationUpdateFragmentFragmentDoc.definitions]} as unknown as DocumentNode<UpdateApplicationStatusMutation, UpdateApplicationStatusMutationVariables>;
export const ShowInterestInJobDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ShowInterestInJob"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateApplicationStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"status"},"value":{"kind":"EnumValue","value":"INTERESTED"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ApplicationUpdateFragment"}}]}}]}},...ApplicationUpdateFragmentFragmentDoc.definitions]} as unknown as DocumentNode<ShowInterestInJobMutation, ShowInterestInJobMutationVariables>;
export const ConfirmInterestInJobDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ConfirmInterestInJob"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateApplicationStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"status"},"value":{"kind":"EnumValue","value":"UNDER_REVIEW"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ApplicationUpdateFragment"}}]}}]}},...ApplicationUpdateFragmentFragmentDoc.definitions]} as unknown as DocumentNode<ConfirmInterestInJobMutation, ConfirmInterestInJobMutationVariables>;
export const SelectForInterviewDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SelectForInterview"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateApplicationStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"status"},"value":{"kind":"EnumValue","value":"SELECTED_FOR_INTERVIEW"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ApplicationUpdateFragment"}}]}}]}},...ApplicationUpdateFragmentFragmentDoc.definitions]} as unknown as DocumentNode<SelectForInterviewMutation, SelectForInterviewMutationVariables>;
export const HireApplicantDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"HireApplicant"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateApplicationStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"status"},"value":{"kind":"EnumValue","value":"HIRED"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ApplicationUpdateFragment"}}]}}]}},...ApplicationUpdateFragmentFragmentDoc.definitions]} as unknown as DocumentNode<HireApplicantMutation, HireApplicantMutationVariables>;
export const CertificationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Certifications"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"certifications"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"sortNameBy"},"value":{"kind":"EnumValue","value":"ASC"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<CertificationsQuery, CertificationsQueryVariables>;
export const CompanyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Company"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"company"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CompanyFields"}}]}}]}},...CompanyFieldsFragmentDoc.definitions]} as unknown as DocumentNode<CompanyQuery, CompanyQueryVariables>;
export const AutocompleteCompaniesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AutocompleteCompanies"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companies"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"sortNameBy"},"value":{"kind":"EnumValue","value":"ASC"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"avatarURL"}}]}}]}}]} as unknown as DocumentNode<AutocompleteCompaniesQuery, AutocompleteCompaniesQueryVariables>;
export const CurrentRecruiterCompanyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CurrentRecruiterCompany"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"currentRecruiterCompany"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"avatarURL"}}]}}]}}]} as unknown as DocumentNode<CurrentRecruiterCompanyQuery, CurrentRecruiterCompanyQueryVariables>;
export const UpdateCompanyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateCompany"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"avatarFile"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Upload"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"backgroundFile"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Upload"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"basicInfo"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"CompanyBasicInfoInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateCompany"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"avatarFile"},"value":{"kind":"Variable","name":{"kind":"Name","value":"avatarFile"}}},{"kind":"Argument","name":{"kind":"Name","value":"backgroundFile"},"value":{"kind":"Variable","name":{"kind":"Name","value":"backgroundFile"}}},{"kind":"Argument","name":{"kind":"Name","value":"basicInfo"},"value":{"kind":"Variable","name":{"kind":"Name","value":"basicInfo"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CompanyFields"}}]}}]}},...CompanyFieldsFragmentDoc.definitions]} as unknown as DocumentNode<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const LastFeedbackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"LastFeedback"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"lastFeedback"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}}]}}]} as unknown as DocumentNode<LastFeedbackQuery, LastFeedbackQueryVariables>;
export const FeedbackIntervalStringDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"FeedbackIntervalString"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"feedbackIntervalString"}}]}}]} as unknown as DocumentNode<FeedbackIntervalStringQuery, FeedbackIntervalStringQueryVariables>;
export const CreateFeedbackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateFeedback"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"message"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"rating"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"NonNegativeInt"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createFeedback"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"message"},"value":{"kind":"Variable","name":{"kind":"Name","value":"message"}}},{"kind":"Argument","name":{"kind":"Name","value":"rating"},"value":{"kind":"Variable","name":{"kind":"Name","value":"rating"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FeedbackFields"}}]}}]}},...FeedbackFieldsFragmentDoc.definitions]} as unknown as DocumentNode<CreateFeedbackMutation, CreateFeedbackMutationVariables>;
export const IndustriesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Industries"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"industries"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"active"},"value":{"kind":"BooleanValue","value":true}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"IndustryFields"}}]}}]}},...IndustryFieldsFragmentDoc.definitions]} as unknown as DocumentNode<IndustriesQuery, IndustriesQueryVariables>;
export const InterviewDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Interview"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"interview"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"InterviewFields"}}]}}]}},...InterviewFieldsFragmentDoc.definitions]} as unknown as DocumentNode<InterviewQuery, InterviewQueryVariables>;
export const InterviewsForApplicantDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"InterviewsForApplicant"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"interviewsForApplicant"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"InterviewFields"}}]}}]}},...InterviewFieldsFragmentDoc.definitions]} as unknown as DocumentNode<InterviewsForApplicantQuery, InterviewsForApplicantQueryVariables>;
export const InterviewsForJobDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"InterviewsForJob"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"jobId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ObjectID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"interviewsForJob"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"jobId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"jobId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"InterviewFields"}}]}}]}},...InterviewFieldsFragmentDoc.definitions]} as unknown as DocumentNode<InterviewsForJobQuery, InterviewsForJobQueryVariables>;
export const CreateInterviewDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateInterview"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"interviewFields"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"InterviewFieldsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createInterview"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"interviewFields"},"value":{"kind":"Variable","name":{"kind":"Name","value":"interviewFields"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"InterviewFields"}}]}}]}},...InterviewFieldsFragmentDoc.definitions]} as unknown as DocumentNode<CreateInterviewMutation, CreateInterviewMutationVariables>;
export const SelectInterviewDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SelectInterview"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"selectInterview"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"InterviewFields"}}]}}]}},...InterviewFieldsFragmentDoc.definitions]} as unknown as DocumentNode<SelectInterviewMutation, SelectInterviewMutationVariables>;
export const DeleteInterviewDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteInterview"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteInterview"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"InterviewFields"}}]}}]}},...InterviewFieldsFragmentDoc.definitions]} as unknown as DocumentNode<DeleteInterviewMutation, DeleteInterviewMutationVariables>;
export const CreateIssueDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateIssue"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"message"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createIssue"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"message"},"value":{"kind":"Variable","name":{"kind":"Name","value":"message"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"IssueFields"}}]}}]}},...IssueFieldsFragmentDoc.definitions]} as unknown as DocumentNode<CreateIssueMutation, CreateIssueMutationVariables>;
export const JobBasicDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"JobBasic"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"job"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"positionId"}},{"kind":"Field","name":{"kind":"Name","value":"location"}}]}}]}}]} as unknown as DocumentNode<JobBasicQuery, JobBasicQueryVariables>;
export const JobWithCompanyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"JobWithCompany"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"job"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"JobFields"}}]}}]}},...JobFieldsFragmentDoc.definitions]} as unknown as DocumentNode<JobWithCompanyQuery, JobWithCompanyQueryVariables>;
export const JobWithInterviewDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"JobWithInterview"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"job"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"JobFields"}},{"kind":"Field","name":{"kind":"Name","value":"interviewDetails"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"location"}},{"kind":"Field","name":{"kind":"Name","value":"minimumNotice"}},{"kind":"Field","name":{"kind":"Name","value":"description"}}]}}]}}]}},...JobFieldsFragmentDoc.definitions]} as unknown as DocumentNode<JobWithInterviewQuery, JobWithInterviewQueryVariables>;
export const RecruiterJobsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"RecruiterJobs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"recruiterJobs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"JobFields"}}]}}]}},...JobFieldsFragmentDoc.definitions]} as unknown as DocumentNode<RecruiterJobsQuery, RecruiterJobsQueryVariables>;
export const MatchedJobsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MatchedJobs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"matchedJobs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"JobFields"}}]}}]}},...JobFieldsFragmentDoc.definitions]} as unknown as DocumentNode<MatchedJobsQuery, MatchedJobsQueryVariables>;
export const CreateJobDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateJob"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"jobFields"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"JobFieldsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createJob"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"jobFields"},"value":{"kind":"Variable","name":{"kind":"Name","value":"jobFields"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"JobFields"}}]}}]}},...JobFieldsFragmentDoc.definitions]} as unknown as DocumentNode<CreateJobMutation, CreateJobMutationVariables>;
export const UpdateInterviewDetailsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateInterviewDetails"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"interviewDetailsFields"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"InterviewDetailsFieldsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateInterviewDetails"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"interviewDetailsFields"},"value":{"kind":"Variable","name":{"kind":"Name","value":"interviewDetailsFields"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"interviewDetails"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"location"}},{"kind":"Field","name":{"kind":"Name","value":"minimumNotice"}},{"kind":"Field","name":{"kind":"Name","value":"description"}}]}}]}}]}}]} as unknown as DocumentNode<UpdateInterviewDetailsMutation, UpdateInterviewDetailsMutationVariables>;
export const JobTemplateByPositionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"JobTemplateByPosition"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"jobTemplateByPosition"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"JobTemplateFields"}}]}}]}},...JobTemplateFieldsFragmentDoc.definitions]} as unknown as DocumentNode<JobTemplateByPositionQuery, JobTemplateByPositionQueryVariables>;
export const NotificationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Notifications"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"receiver"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"notifications"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"receiver"},"value":{"kind":"Variable","name":{"kind":"Name","value":"receiver"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"NotificationFields"}}]}}]}},...NotificationFieldsFragmentDoc.definitions]} as unknown as DocumentNode<NotificationsQuery, NotificationsQueryVariables>;
export const ReadNotificationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ReadNotifications"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"readNotification"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"read"}}]}}]}}]} as unknown as DocumentNode<ReadNotificationsMutation, ReadNotificationsMutationVariables>;
export const SubscribeNotificationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"SubscribeNotifications"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"receiver"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"notificationAdded"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"receiver"},"value":{"kind":"Variable","name":{"kind":"Name","value":"receiver"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"NotificationFields"}}]}}]}},...NotificationFieldsFragmentDoc.definitions]} as unknown as DocumentNode<SubscribeNotificationsSubscription, SubscribeNotificationsSubscriptionVariables>;
export const CreateSubscriptionCheckoutSessionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateSubscriptionCheckoutSession"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"plan"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SubscriptionPlan"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"successUrl"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"URL"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cancelUrl"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"URL"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createSubscriptionCheckoutSession"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"plan"},"value":{"kind":"Variable","name":{"kind":"Name","value":"plan"}}},{"kind":"Argument","name":{"kind":"Name","value":"successUrl"},"value":{"kind":"Variable","name":{"kind":"Name","value":"successUrl"}}},{"kind":"Argument","name":{"kind":"Name","value":"cancelUrl"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cancelUrl"}}}]}]}}]} as unknown as DocumentNode<CreateSubscriptionCheckoutSessionMutation, CreateSubscriptionCheckoutSessionMutationVariables>;
export const ProcessSuccessfulCheckoutSessionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ProcessSuccessfulCheckoutSession"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"sessionId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"processSuccessfulCheckoutSession"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"sessionId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"sessionId"}}}]}]}}]} as unknown as DocumentNode<ProcessSuccessfulCheckoutSessionMutation, ProcessSuccessfulCheckoutSessionMutationVariables>;
export const CreateBillingPortalSessionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateBillingPortalSession"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"returnUrl"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"URL"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createBillingPortalSession"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"returnUrl"},"value":{"kind":"Variable","name":{"kind":"Name","value":"returnUrl"}}}]}]}}]} as unknown as DocumentNode<CreateBillingPortalSessionMutation, CreateBillingPortalSessionMutationVariables>;
export const PositionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Positions"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"industry"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ObjectID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"positions"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"industry"},"value":{"kind":"Variable","name":{"kind":"Name","value":"industry"}}},{"kind":"Argument","name":{"kind":"Name","value":"sortNameBy"},"value":{"kind":"EnumValue","value":"ASC"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"industries"}}]}}]}}]} as unknown as DocumentNode<PositionsQuery, PositionsQueryVariables>;
export const PositionBasicDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"PositionBasic"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"position"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"industries"}}]}}]}}]} as unknown as DocumentNode<PositionBasicQuery, PositionBasicQueryVariables>;
export const QuestionnaireResultDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"QuestionnaireResult"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"questionnaireResult"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"QuestionnaireResultFields"}}]}}]}},...QuestionnaireResultFieldsFragmentDoc.definitions]} as unknown as DocumentNode<QuestionnaireResultQuery, QuestionnaireResultQueryVariables>;
export const QuestionnaireResultProgressDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"QuestionnaireResultProgress"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"questionnaireResultProgress"}}]}}]} as unknown as DocumentNode<QuestionnaireResultProgressQuery, QuestionnaireResultProgressQueryVariables>;
export const UpdateQuestionnaireAnswersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateQuestionnaireAnswers"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"answers"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NamedType","name":{"kind":"Name","value":"QuestionnaireAnswerInput"}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateQuestionnaireAnswers"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"answers"},"value":{"kind":"Variable","name":{"kind":"Name","value":"answers"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"answers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"QuestionnaireBaseAnswerFields"}}]}}]}}]}},...QuestionnaireBaseAnswerFieldsFragmentDoc.definitions]} as unknown as DocumentNode<UpdateQuestionnaireAnswersMutation, UpdateQuestionnaireAnswersMutationVariables>;
export const RecruiterWithCompanyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"RecruiterWithCompany"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"recruiter"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"RecruiterFields"}},{"kind":"Field","name":{"kind":"Name","value":"company"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CompanyFields"}}]}}]}}]}},...RecruiterFieldsFragmentDoc.definitions,...CompanyFieldsFragmentDoc.definitions]} as unknown as DocumentNode<RecruiterWithCompanyQuery, RecruiterWithCompanyQueryVariables>;
export const RecruiterWithoutCompanyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"RecruiterWithoutCompany"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"recruiter"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"RecruiterFields"}}]}}]}},...RecruiterFieldsFragmentDoc.definitions]} as unknown as DocumentNode<RecruiterWithoutCompanyQuery, RecruiterWithoutCompanyQueryVariables>;
export const SignUpRecruiterDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SignUpRecruiter"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ApplicantRecruiterSignUpInput"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CompanySignUpInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"signUpRecruiter"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userInput"}}},{"kind":"Argument","name":{"kind":"Name","value":"companyInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"RecruiterFields"}}]}}]}},...RecruiterFieldsFragmentDoc.definitions]} as unknown as DocumentNode<SignUpRecruiterMutation, SignUpRecruiterMutationVariables>;
export const UpdateRecruiterDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateRecruiter"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"applicantRecruiterIntroInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ApplicantRecruiterIntroInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateRecruiter"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"applicantRecruiterIntroInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"applicantRecruiterIntroInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"RecruiterFields"}}]}}]}},...RecruiterFieldsFragmentDoc.definitions]} as unknown as DocumentNode<UpdateRecruiterMutation, UpdateRecruiterMutationVariables>;
export const SkillsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Skills"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"skills"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"sortNameBy"},"value":{"kind":"EnumValue","value":"ASC"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<SkillsQuery, SkillsQueryVariables>;