import React, { ComponentType } from "react";
import { Subtract } from "utility-types";
import AuthUserContext, { AuthUser } from "../contexts/AuthUserContext";

export interface AuthInjectedProps {
  user: AuthUser;
}

/**
 * HOC that injects user into wrapped component.
 * Use this instead of {@link useUser} hook when you only want wrapped component to render
 * when user is not null
 */
const withAuthentication = <P extends AuthInjectedProps>(WrappedComponent: ComponentType<P>) => {
  const WithAuthentication: React.FC<Subtract<P, AuthInjectedProps>> = (props) => (
    <AuthUserContext.Consumer>
      {(authUser) => {
        return authUser && <WrappedComponent {...(props as P)} user={authUser} />;
      }}
    </AuthUserContext.Consumer>
  );

  WithAuthentication.displayName = `withAuthentication(${
    WrappedComponent.displayName || WrappedComponent.name
  }`;
  return WithAuthentication;
};

export default withAuthentication;
