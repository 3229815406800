/* This file was generated based on "codegen.yml". Do not edit manually. */
/* eslint-disable */
import type * as Types from "./graphqlTypes";
import * as Operations from './graphqlTypes';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;


/**
 * __useApplicantQuery__
 *
 * To run a query within a React component, call `useApplicantQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicantQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApplicantQuery(baseOptions: Apollo.QueryHookOptions<Types.ApplicantQuery, Types.ApplicantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ApplicantQuery, Types.ApplicantQueryVariables>(Operations.ApplicantDocument, options);
      }
export function useApplicantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ApplicantQuery, Types.ApplicantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ApplicantQuery, Types.ApplicantQueryVariables>(Operations.ApplicantDocument, options);
        }
export type ApplicantQueryHookResult = ReturnType<typeof useApplicantQuery>;
export type ApplicantLazyQueryHookResult = ReturnType<typeof useApplicantLazyQuery>;
export type ApplicantQueryResult = Apollo.QueryResult<Types.ApplicantQuery, Types.ApplicantQueryVariables>;
export type SignUpApplicantMutationFn = Apollo.MutationFunction<Types.SignUpApplicantMutation, Types.SignUpApplicantMutationVariables>;

/**
 * __useSignUpApplicantMutation__
 *
 * To run a mutation, you first call `useSignUpApplicantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpApplicantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpApplicantMutation, { data, loading, error }] = useSignUpApplicantMutation({
 *   variables: {
 *      signUpInput: // value for 'signUpInput'
 *   },
 * });
 */
export function useSignUpApplicantMutation(baseOptions?: Apollo.MutationHookOptions<Types.SignUpApplicantMutation, Types.SignUpApplicantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SignUpApplicantMutation, Types.SignUpApplicantMutationVariables>(Operations.SignUpApplicantDocument, options);
      }
export type SignUpApplicantMutationHookResult = ReturnType<typeof useSignUpApplicantMutation>;
export type SignUpApplicantMutationResult = Apollo.MutationResult<Types.SignUpApplicantMutation>;
export type SignUpApplicantMutationOptions = Apollo.BaseMutationOptions<Types.SignUpApplicantMutation, Types.SignUpApplicantMutationVariables>;
export type CreateApplicantProfileMutationFn = Apollo.MutationFunction<Types.CreateApplicantProfileMutation, Types.CreateApplicantProfileMutationVariables>;

/**
 * __useCreateApplicantProfileMutation__
 *
 * To run a mutation, you first call `useCreateApplicantProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicantProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicantProfileMutation, { data, loading, error }] = useCreateApplicantProfileMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useCreateApplicantProfileMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateApplicantProfileMutation, Types.CreateApplicantProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateApplicantProfileMutation, Types.CreateApplicantProfileMutationVariables>(Operations.CreateApplicantProfileDocument, options);
      }
export type CreateApplicantProfileMutationHookResult = ReturnType<typeof useCreateApplicantProfileMutation>;
export type CreateApplicantProfileMutationResult = Apollo.MutationResult<Types.CreateApplicantProfileMutation>;
export type CreateApplicantProfileMutationOptions = Apollo.BaseMutationOptions<Types.CreateApplicantProfileMutation, Types.CreateApplicantProfileMutationVariables>;
export type UpdateApplicantIntroMutationFn = Apollo.MutationFunction<Types.UpdateApplicantIntroMutation, Types.UpdateApplicantIntroMutationVariables>;

/**
 * __useUpdateApplicantIntroMutation__
 *
 * To run a mutation, you first call `useUpdateApplicantIntroMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicantIntroMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicantIntroMutation, { data, loading, error }] = useUpdateApplicantIntroMutation({
 *   variables: {
 *      applicantRecruiterIntroInput: // value for 'applicantRecruiterIntroInput'
 *   },
 * });
 */
export function useUpdateApplicantIntroMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateApplicantIntroMutation, Types.UpdateApplicantIntroMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateApplicantIntroMutation, Types.UpdateApplicantIntroMutationVariables>(Operations.UpdateApplicantIntroDocument, options);
      }
export type UpdateApplicantIntroMutationHookResult = ReturnType<typeof useUpdateApplicantIntroMutation>;
export type UpdateApplicantIntroMutationResult = Apollo.MutationResult<Types.UpdateApplicantIntroMutation>;
export type UpdateApplicantIntroMutationOptions = Apollo.BaseMutationOptions<Types.UpdateApplicantIntroMutation, Types.UpdateApplicantIntroMutationVariables>;
export type UpdateApplicantJobPreferencesMutationFn = Apollo.MutationFunction<Types.UpdateApplicantJobPreferencesMutation, Types.UpdateApplicantJobPreferencesMutationVariables>;

/**
 * __useUpdateApplicantJobPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateApplicantJobPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicantJobPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicantJobPreferencesMutation, { data, loading, error }] = useUpdateApplicantJobPreferencesMutation({
 *   variables: {
 *      jobPreferences: // value for 'jobPreferences'
 *   },
 * });
 */
export function useUpdateApplicantJobPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateApplicantJobPreferencesMutation, Types.UpdateApplicantJobPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateApplicantJobPreferencesMutation, Types.UpdateApplicantJobPreferencesMutationVariables>(Operations.UpdateApplicantJobPreferencesDocument, options);
      }
export type UpdateApplicantJobPreferencesMutationHookResult = ReturnType<typeof useUpdateApplicantJobPreferencesMutation>;
export type UpdateApplicantJobPreferencesMutationResult = Apollo.MutationResult<Types.UpdateApplicantJobPreferencesMutation>;
export type UpdateApplicantJobPreferencesMutationOptions = Apollo.BaseMutationOptions<Types.UpdateApplicantJobPreferencesMutation, Types.UpdateApplicantJobPreferencesMutationVariables>;
export type UpdateApplicantResumeMutationFn = Apollo.MutationFunction<Types.UpdateApplicantResumeMutation, Types.UpdateApplicantResumeMutationVariables>;

/**
 * __useUpdateApplicantResumeMutation__
 *
 * To run a mutation, you first call `useUpdateApplicantResumeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicantResumeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicantResumeMutation, { data, loading, error }] = useUpdateApplicantResumeMutation({
 *   variables: {
 *      resumeFile: // value for 'resumeFile'
 *   },
 * });
 */
export function useUpdateApplicantResumeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateApplicantResumeMutation, Types.UpdateApplicantResumeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateApplicantResumeMutation, Types.UpdateApplicantResumeMutationVariables>(Operations.UpdateApplicantResumeDocument, options);
      }
export type UpdateApplicantResumeMutationHookResult = ReturnType<typeof useUpdateApplicantResumeMutation>;
export type UpdateApplicantResumeMutationResult = Apollo.MutationResult<Types.UpdateApplicantResumeMutation>;
export type UpdateApplicantResumeMutationOptions = Apollo.BaseMutationOptions<Types.UpdateApplicantResumeMutation, Types.UpdateApplicantResumeMutationVariables>;
export type RemoveApplicantResumeMutationFn = Apollo.MutationFunction<Types.RemoveApplicantResumeMutation, Types.RemoveApplicantResumeMutationVariables>;

/**
 * __useRemoveApplicantResumeMutation__
 *
 * To run a mutation, you first call `useRemoveApplicantResumeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveApplicantResumeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeApplicantResumeMutation, { data, loading, error }] = useRemoveApplicantResumeMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveApplicantResumeMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveApplicantResumeMutation, Types.RemoveApplicantResumeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveApplicantResumeMutation, Types.RemoveApplicantResumeMutationVariables>(Operations.RemoveApplicantResumeDocument, options);
      }
export type RemoveApplicantResumeMutationHookResult = ReturnType<typeof useRemoveApplicantResumeMutation>;
export type RemoveApplicantResumeMutationResult = Apollo.MutationResult<Types.RemoveApplicantResumeMutation>;
export type RemoveApplicantResumeMutationOptions = Apollo.BaseMutationOptions<Types.RemoveApplicantResumeMutation, Types.RemoveApplicantResumeMutationVariables>;
export type AddApplicantExperienceMutationFn = Apollo.MutationFunction<Types.AddApplicantExperienceMutation, Types.AddApplicantExperienceMutationVariables>;

/**
 * __useAddApplicantExperienceMutation__
 *
 * To run a mutation, you first call `useAddApplicantExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddApplicantExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addApplicantExperienceMutation, { data, loading, error }] = useAddApplicantExperienceMutation({
 *   variables: {
 *      experienceFields: // value for 'experienceFields'
 *   },
 * });
 */
export function useAddApplicantExperienceMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddApplicantExperienceMutation, Types.AddApplicantExperienceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddApplicantExperienceMutation, Types.AddApplicantExperienceMutationVariables>(Operations.AddApplicantExperienceDocument, options);
      }
export type AddApplicantExperienceMutationHookResult = ReturnType<typeof useAddApplicantExperienceMutation>;
export type AddApplicantExperienceMutationResult = Apollo.MutationResult<Types.AddApplicantExperienceMutation>;
export type AddApplicantExperienceMutationOptions = Apollo.BaseMutationOptions<Types.AddApplicantExperienceMutation, Types.AddApplicantExperienceMutationVariables>;
export type UpdateApplicantExperienceMutationFn = Apollo.MutationFunction<Types.UpdateApplicantExperienceMutation, Types.UpdateApplicantExperienceMutationVariables>;

/**
 * __useUpdateApplicantExperienceMutation__
 *
 * To run a mutation, you first call `useUpdateApplicantExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicantExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicantExperienceMutation, { data, loading, error }] = useUpdateApplicantExperienceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      experienceFields: // value for 'experienceFields'
 *   },
 * });
 */
export function useUpdateApplicantExperienceMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateApplicantExperienceMutation, Types.UpdateApplicantExperienceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateApplicantExperienceMutation, Types.UpdateApplicantExperienceMutationVariables>(Operations.UpdateApplicantExperienceDocument, options);
      }
export type UpdateApplicantExperienceMutationHookResult = ReturnType<typeof useUpdateApplicantExperienceMutation>;
export type UpdateApplicantExperienceMutationResult = Apollo.MutationResult<Types.UpdateApplicantExperienceMutation>;
export type UpdateApplicantExperienceMutationOptions = Apollo.BaseMutationOptions<Types.UpdateApplicantExperienceMutation, Types.UpdateApplicantExperienceMutationVariables>;
export type DeleteApplicantExperienceMutationFn = Apollo.MutationFunction<Types.DeleteApplicantExperienceMutation, Types.DeleteApplicantExperienceMutationVariables>;

/**
 * __useDeleteApplicantExperienceMutation__
 *
 * To run a mutation, you first call `useDeleteApplicantExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApplicantExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApplicantExperienceMutation, { data, loading, error }] = useDeleteApplicantExperienceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteApplicantExperienceMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteApplicantExperienceMutation, Types.DeleteApplicantExperienceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteApplicantExperienceMutation, Types.DeleteApplicantExperienceMutationVariables>(Operations.DeleteApplicantExperienceDocument, options);
      }
export type DeleteApplicantExperienceMutationHookResult = ReturnType<typeof useDeleteApplicantExperienceMutation>;
export type DeleteApplicantExperienceMutationResult = Apollo.MutationResult<Types.DeleteApplicantExperienceMutation>;
export type DeleteApplicantExperienceMutationOptions = Apollo.BaseMutationOptions<Types.DeleteApplicantExperienceMutation, Types.DeleteApplicantExperienceMutationVariables>;
export type AddApplicantEducationMutationFn = Apollo.MutationFunction<Types.AddApplicantEducationMutation, Types.AddApplicantEducationMutationVariables>;

/**
 * __useAddApplicantEducationMutation__
 *
 * To run a mutation, you first call `useAddApplicantEducationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddApplicantEducationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addApplicantEducationMutation, { data, loading, error }] = useAddApplicantEducationMutation({
 *   variables: {
 *      educationFields: // value for 'educationFields'
 *   },
 * });
 */
export function useAddApplicantEducationMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddApplicantEducationMutation, Types.AddApplicantEducationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddApplicantEducationMutation, Types.AddApplicantEducationMutationVariables>(Operations.AddApplicantEducationDocument, options);
      }
export type AddApplicantEducationMutationHookResult = ReturnType<typeof useAddApplicantEducationMutation>;
export type AddApplicantEducationMutationResult = Apollo.MutationResult<Types.AddApplicantEducationMutation>;
export type AddApplicantEducationMutationOptions = Apollo.BaseMutationOptions<Types.AddApplicantEducationMutation, Types.AddApplicantEducationMutationVariables>;
export type UpdateApplicantEducationMutationFn = Apollo.MutationFunction<Types.UpdateApplicantEducationMutation, Types.UpdateApplicantEducationMutationVariables>;

/**
 * __useUpdateApplicantEducationMutation__
 *
 * To run a mutation, you first call `useUpdateApplicantEducationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicantEducationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicantEducationMutation, { data, loading, error }] = useUpdateApplicantEducationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      educationFields: // value for 'educationFields'
 *   },
 * });
 */
export function useUpdateApplicantEducationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateApplicantEducationMutation, Types.UpdateApplicantEducationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateApplicantEducationMutation, Types.UpdateApplicantEducationMutationVariables>(Operations.UpdateApplicantEducationDocument, options);
      }
export type UpdateApplicantEducationMutationHookResult = ReturnType<typeof useUpdateApplicantEducationMutation>;
export type UpdateApplicantEducationMutationResult = Apollo.MutationResult<Types.UpdateApplicantEducationMutation>;
export type UpdateApplicantEducationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateApplicantEducationMutation, Types.UpdateApplicantEducationMutationVariables>;
export type DeleteApplicantEducationMutationFn = Apollo.MutationFunction<Types.DeleteApplicantEducationMutation, Types.DeleteApplicantEducationMutationVariables>;

/**
 * __useDeleteApplicantEducationMutation__
 *
 * To run a mutation, you first call `useDeleteApplicantEducationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApplicantEducationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApplicantEducationMutation, { data, loading, error }] = useDeleteApplicantEducationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteApplicantEducationMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteApplicantEducationMutation, Types.DeleteApplicantEducationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteApplicantEducationMutation, Types.DeleteApplicantEducationMutationVariables>(Operations.DeleteApplicantEducationDocument, options);
      }
export type DeleteApplicantEducationMutationHookResult = ReturnType<typeof useDeleteApplicantEducationMutation>;
export type DeleteApplicantEducationMutationResult = Apollo.MutationResult<Types.DeleteApplicantEducationMutation>;
export type DeleteApplicantEducationMutationOptions = Apollo.BaseMutationOptions<Types.DeleteApplicantEducationMutation, Types.DeleteApplicantEducationMutationVariables>;
export type AddApplicantCertificationMutationFn = Apollo.MutationFunction<Types.AddApplicantCertificationMutation, Types.AddApplicantCertificationMutationVariables>;

/**
 * __useAddApplicantCertificationMutation__
 *
 * To run a mutation, you first call `useAddApplicantCertificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddApplicantCertificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addApplicantCertificationMutation, { data, loading, error }] = useAddApplicantCertificationMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      certificationFields: // value for 'certificationFields'
 *   },
 * });
 */
export function useAddApplicantCertificationMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddApplicantCertificationMutation, Types.AddApplicantCertificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddApplicantCertificationMutation, Types.AddApplicantCertificationMutationVariables>(Operations.AddApplicantCertificationDocument, options);
      }
export type AddApplicantCertificationMutationHookResult = ReturnType<typeof useAddApplicantCertificationMutation>;
export type AddApplicantCertificationMutationResult = Apollo.MutationResult<Types.AddApplicantCertificationMutation>;
export type AddApplicantCertificationMutationOptions = Apollo.BaseMutationOptions<Types.AddApplicantCertificationMutation, Types.AddApplicantCertificationMutationVariables>;
export type UpdateApplicantCertificationMutationFn = Apollo.MutationFunction<Types.UpdateApplicantCertificationMutation, Types.UpdateApplicantCertificationMutationVariables>;

/**
 * __useUpdateApplicantCertificationMutation__
 *
 * To run a mutation, you first call `useUpdateApplicantCertificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicantCertificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicantCertificationMutation, { data, loading, error }] = useUpdateApplicantCertificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      certificationFields: // value for 'certificationFields'
 *   },
 * });
 */
export function useUpdateApplicantCertificationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateApplicantCertificationMutation, Types.UpdateApplicantCertificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateApplicantCertificationMutation, Types.UpdateApplicantCertificationMutationVariables>(Operations.UpdateApplicantCertificationDocument, options);
      }
export type UpdateApplicantCertificationMutationHookResult = ReturnType<typeof useUpdateApplicantCertificationMutation>;
export type UpdateApplicantCertificationMutationResult = Apollo.MutationResult<Types.UpdateApplicantCertificationMutation>;
export type UpdateApplicantCertificationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateApplicantCertificationMutation, Types.UpdateApplicantCertificationMutationVariables>;
export type DeleteApplicantCertificationMutationFn = Apollo.MutationFunction<Types.DeleteApplicantCertificationMutation, Types.DeleteApplicantCertificationMutationVariables>;

/**
 * __useDeleteApplicantCertificationMutation__
 *
 * To run a mutation, you first call `useDeleteApplicantCertificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApplicantCertificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApplicantCertificationMutation, { data, loading, error }] = useDeleteApplicantCertificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteApplicantCertificationMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteApplicantCertificationMutation, Types.DeleteApplicantCertificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteApplicantCertificationMutation, Types.DeleteApplicantCertificationMutationVariables>(Operations.DeleteApplicantCertificationDocument, options);
      }
export type DeleteApplicantCertificationMutationHookResult = ReturnType<typeof useDeleteApplicantCertificationMutation>;
export type DeleteApplicantCertificationMutationResult = Apollo.MutationResult<Types.DeleteApplicantCertificationMutation>;
export type DeleteApplicantCertificationMutationOptions = Apollo.BaseMutationOptions<Types.DeleteApplicantCertificationMutation, Types.DeleteApplicantCertificationMutationVariables>;
export type UpdateApplicantSkillsMutationFn = Apollo.MutationFunction<Types.UpdateApplicantSkillsMutation, Types.UpdateApplicantSkillsMutationVariables>;

/**
 * __useUpdateApplicantSkillsMutation__
 *
 * To run a mutation, you first call `useUpdateApplicantSkillsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicantSkillsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicantSkillsMutation, { data, loading, error }] = useUpdateApplicantSkillsMutation({
 *   variables: {
 *      skills: // value for 'skills'
 *   },
 * });
 */
export function useUpdateApplicantSkillsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateApplicantSkillsMutation, Types.UpdateApplicantSkillsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateApplicantSkillsMutation, Types.UpdateApplicantSkillsMutationVariables>(Operations.UpdateApplicantSkillsDocument, options);
      }
export type UpdateApplicantSkillsMutationHookResult = ReturnType<typeof useUpdateApplicantSkillsMutation>;
export type UpdateApplicantSkillsMutationResult = Apollo.MutationResult<Types.UpdateApplicantSkillsMutation>;
export type UpdateApplicantSkillsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateApplicantSkillsMutation, Types.UpdateApplicantSkillsMutationVariables>;

/**
 * __useApplicantApplicationQuery__
 *
 * To run a query within a React component, call `useApplicantApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicantApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicantApplicationQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useApplicantApplicationQuery(baseOptions: Apollo.QueryHookOptions<Types.ApplicantApplicationQuery, Types.ApplicantApplicationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ApplicantApplicationQuery, Types.ApplicantApplicationQueryVariables>(Operations.ApplicantApplicationDocument, options);
      }
export function useApplicantApplicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ApplicantApplicationQuery, Types.ApplicantApplicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ApplicantApplicationQuery, Types.ApplicantApplicationQueryVariables>(Operations.ApplicantApplicationDocument, options);
        }
export type ApplicantApplicationQueryHookResult = ReturnType<typeof useApplicantApplicationQuery>;
export type ApplicantApplicationLazyQueryHookResult = ReturnType<typeof useApplicantApplicationLazyQuery>;
export type ApplicantApplicationQueryResult = Apollo.QueryResult<Types.ApplicantApplicationQuery, Types.ApplicantApplicationQueryVariables>;

/**
 * __useApplicantCreatedApplicationsQuery__
 *
 * To run a query within a React component, call `useApplicantCreatedApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicantCreatedApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicantCreatedApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useApplicantCreatedApplicationsQuery(baseOptions?: Apollo.QueryHookOptions<Types.ApplicantCreatedApplicationsQuery, Types.ApplicantCreatedApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ApplicantCreatedApplicationsQuery, Types.ApplicantCreatedApplicationsQueryVariables>(Operations.ApplicantCreatedApplicationsDocument, options);
      }
export function useApplicantCreatedApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ApplicantCreatedApplicationsQuery, Types.ApplicantCreatedApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ApplicantCreatedApplicationsQuery, Types.ApplicantCreatedApplicationsQueryVariables>(Operations.ApplicantCreatedApplicationsDocument, options);
        }
export type ApplicantCreatedApplicationsQueryHookResult = ReturnType<typeof useApplicantCreatedApplicationsQuery>;
export type ApplicantCreatedApplicationsLazyQueryHookResult = ReturnType<typeof useApplicantCreatedApplicationsLazyQuery>;
export type ApplicantCreatedApplicationsQueryResult = Apollo.QueryResult<Types.ApplicantCreatedApplicationsQuery, Types.ApplicantCreatedApplicationsQueryVariables>;

/**
 * __useApplicantViewableApplicationsQuery__
 *
 * To run a query within a React component, call `useApplicantViewableApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicantViewableApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicantViewableApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useApplicantViewableApplicationsQuery(baseOptions?: Apollo.QueryHookOptions<Types.ApplicantViewableApplicationsQuery, Types.ApplicantViewableApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ApplicantViewableApplicationsQuery, Types.ApplicantViewableApplicationsQueryVariables>(Operations.ApplicantViewableApplicationsDocument, options);
      }
export function useApplicantViewableApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ApplicantViewableApplicationsQuery, Types.ApplicantViewableApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ApplicantViewableApplicationsQuery, Types.ApplicantViewableApplicationsQueryVariables>(Operations.ApplicantViewableApplicationsDocument, options);
        }
export type ApplicantViewableApplicationsQueryHookResult = ReturnType<typeof useApplicantViewableApplicationsQuery>;
export type ApplicantViewableApplicationsLazyQueryHookResult = ReturnType<typeof useApplicantViewableApplicationsLazyQuery>;
export type ApplicantViewableApplicationsQueryResult = Apollo.QueryResult<Types.ApplicantViewableApplicationsQuery, Types.ApplicantViewableApplicationsQueryVariables>;

/**
 * __useRecruiterViewableJobApplicationsQuery__
 *
 * To run a query within a React component, call `useRecruiterViewableJobApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruiterViewableJobApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruiterViewableJobApplicationsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useRecruiterViewableJobApplicationsQuery(baseOptions: Apollo.QueryHookOptions<Types.RecruiterViewableJobApplicationsQuery, Types.RecruiterViewableJobApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RecruiterViewableJobApplicationsQuery, Types.RecruiterViewableJobApplicationsQueryVariables>(Operations.RecruiterViewableJobApplicationsDocument, options);
      }
export function useRecruiterViewableJobApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RecruiterViewableJobApplicationsQuery, Types.RecruiterViewableJobApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RecruiterViewableJobApplicationsQuery, Types.RecruiterViewableJobApplicationsQueryVariables>(Operations.RecruiterViewableJobApplicationsDocument, options);
        }
export type RecruiterViewableJobApplicationsQueryHookResult = ReturnType<typeof useRecruiterViewableJobApplicationsQuery>;
export type RecruiterViewableJobApplicationsLazyQueryHookResult = ReturnType<typeof useRecruiterViewableJobApplicationsLazyQuery>;
export type RecruiterViewableJobApplicationsQueryResult = Apollo.QueryResult<Types.RecruiterViewableJobApplicationsQuery, Types.RecruiterViewableJobApplicationsQueryVariables>;

/**
 * __useRecruiterApplicationsByStatusQuery__
 *
 * To run a query within a React component, call `useRecruiterApplicationsByStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruiterApplicationsByStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruiterApplicationsByStatusQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useRecruiterApplicationsByStatusQuery(baseOptions: Apollo.QueryHookOptions<Types.RecruiterApplicationsByStatusQuery, Types.RecruiterApplicationsByStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RecruiterApplicationsByStatusQuery, Types.RecruiterApplicationsByStatusQueryVariables>(Operations.RecruiterApplicationsByStatusDocument, options);
      }
export function useRecruiterApplicationsByStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RecruiterApplicationsByStatusQuery, Types.RecruiterApplicationsByStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RecruiterApplicationsByStatusQuery, Types.RecruiterApplicationsByStatusQueryVariables>(Operations.RecruiterApplicationsByStatusDocument, options);
        }
export type RecruiterApplicationsByStatusQueryHookResult = ReturnType<typeof useRecruiterApplicationsByStatusQuery>;
export type RecruiterApplicationsByStatusLazyQueryHookResult = ReturnType<typeof useRecruiterApplicationsByStatusLazyQuery>;
export type RecruiterApplicationsByStatusQueryResult = Apollo.QueryResult<Types.RecruiterApplicationsByStatusQuery, Types.RecruiterApplicationsByStatusQueryVariables>;
export type RemoveApplicationMutationFn = Apollo.MutationFunction<Types.RemoveApplicationMutation, Types.RemoveApplicationMutationVariables>;

/**
 * __useRemoveApplicationMutation__
 *
 * To run a mutation, you first call `useRemoveApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeApplicationMutation, { data, loading, error }] = useRemoveApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveApplicationMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveApplicationMutation, Types.RemoveApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveApplicationMutation, Types.RemoveApplicationMutationVariables>(Operations.RemoveApplicationDocument, options);
      }
export type RemoveApplicationMutationHookResult = ReturnType<typeof useRemoveApplicationMutation>;
export type RemoveApplicationMutationResult = Apollo.MutationResult<Types.RemoveApplicationMutation>;
export type RemoveApplicationMutationOptions = Apollo.BaseMutationOptions<Types.RemoveApplicationMutation, Types.RemoveApplicationMutationVariables>;
export type UpdateApplicationStatusMutationFn = Apollo.MutationFunction<Types.UpdateApplicationStatusMutation, Types.UpdateApplicationStatusMutationVariables>;

/**
 * __useUpdateApplicationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationStatusMutation, { data, loading, error }] = useUpdateApplicationStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateApplicationStatusMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateApplicationStatusMutation, Types.UpdateApplicationStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateApplicationStatusMutation, Types.UpdateApplicationStatusMutationVariables>(Operations.UpdateApplicationStatusDocument, options);
      }
export type UpdateApplicationStatusMutationHookResult = ReturnType<typeof useUpdateApplicationStatusMutation>;
export type UpdateApplicationStatusMutationResult = Apollo.MutationResult<Types.UpdateApplicationStatusMutation>;
export type UpdateApplicationStatusMutationOptions = Apollo.BaseMutationOptions<Types.UpdateApplicationStatusMutation, Types.UpdateApplicationStatusMutationVariables>;
export type ShowInterestInJobMutationFn = Apollo.MutationFunction<Types.ShowInterestInJobMutation, Types.ShowInterestInJobMutationVariables>;

/**
 * __useShowInterestInJobMutation__
 *
 * To run a mutation, you first call `useShowInterestInJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShowInterestInJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [showInterestInJobMutation, { data, loading, error }] = useShowInterestInJobMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShowInterestInJobMutation(baseOptions?: Apollo.MutationHookOptions<Types.ShowInterestInJobMutation, Types.ShowInterestInJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ShowInterestInJobMutation, Types.ShowInterestInJobMutationVariables>(Operations.ShowInterestInJobDocument, options);
      }
export type ShowInterestInJobMutationHookResult = ReturnType<typeof useShowInterestInJobMutation>;
export type ShowInterestInJobMutationResult = Apollo.MutationResult<Types.ShowInterestInJobMutation>;
export type ShowInterestInJobMutationOptions = Apollo.BaseMutationOptions<Types.ShowInterestInJobMutation, Types.ShowInterestInJobMutationVariables>;
export type ConfirmInterestInJobMutationFn = Apollo.MutationFunction<Types.ConfirmInterestInJobMutation, Types.ConfirmInterestInJobMutationVariables>;

/**
 * __useConfirmInterestInJobMutation__
 *
 * To run a mutation, you first call `useConfirmInterestInJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmInterestInJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmInterestInJobMutation, { data, loading, error }] = useConfirmInterestInJobMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConfirmInterestInJobMutation(baseOptions?: Apollo.MutationHookOptions<Types.ConfirmInterestInJobMutation, Types.ConfirmInterestInJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ConfirmInterestInJobMutation, Types.ConfirmInterestInJobMutationVariables>(Operations.ConfirmInterestInJobDocument, options);
      }
export type ConfirmInterestInJobMutationHookResult = ReturnType<typeof useConfirmInterestInJobMutation>;
export type ConfirmInterestInJobMutationResult = Apollo.MutationResult<Types.ConfirmInterestInJobMutation>;
export type ConfirmInterestInJobMutationOptions = Apollo.BaseMutationOptions<Types.ConfirmInterestInJobMutation, Types.ConfirmInterestInJobMutationVariables>;
export type SelectForInterviewMutationFn = Apollo.MutationFunction<Types.SelectForInterviewMutation, Types.SelectForInterviewMutationVariables>;

/**
 * __useSelectForInterviewMutation__
 *
 * To run a mutation, you first call `useSelectForInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectForInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectForInterviewMutation, { data, loading, error }] = useSelectForInterviewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSelectForInterviewMutation(baseOptions?: Apollo.MutationHookOptions<Types.SelectForInterviewMutation, Types.SelectForInterviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SelectForInterviewMutation, Types.SelectForInterviewMutationVariables>(Operations.SelectForInterviewDocument, options);
      }
export type SelectForInterviewMutationHookResult = ReturnType<typeof useSelectForInterviewMutation>;
export type SelectForInterviewMutationResult = Apollo.MutationResult<Types.SelectForInterviewMutation>;
export type SelectForInterviewMutationOptions = Apollo.BaseMutationOptions<Types.SelectForInterviewMutation, Types.SelectForInterviewMutationVariables>;
export type HireApplicantMutationFn = Apollo.MutationFunction<Types.HireApplicantMutation, Types.HireApplicantMutationVariables>;

/**
 * __useHireApplicantMutation__
 *
 * To run a mutation, you first call `useHireApplicantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHireApplicantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hireApplicantMutation, { data, loading, error }] = useHireApplicantMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHireApplicantMutation(baseOptions?: Apollo.MutationHookOptions<Types.HireApplicantMutation, Types.HireApplicantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.HireApplicantMutation, Types.HireApplicantMutationVariables>(Operations.HireApplicantDocument, options);
      }
export type HireApplicantMutationHookResult = ReturnType<typeof useHireApplicantMutation>;
export type HireApplicantMutationResult = Apollo.MutationResult<Types.HireApplicantMutation>;
export type HireApplicantMutationOptions = Apollo.BaseMutationOptions<Types.HireApplicantMutation, Types.HireApplicantMutationVariables>;

/**
 * __useCertificationsQuery__
 *
 * To run a query within a React component, call `useCertificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCertificationsQuery(baseOptions?: Apollo.QueryHookOptions<Types.CertificationsQuery, Types.CertificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CertificationsQuery, Types.CertificationsQueryVariables>(Operations.CertificationsDocument, options);
      }
export function useCertificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CertificationsQuery, Types.CertificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CertificationsQuery, Types.CertificationsQueryVariables>(Operations.CertificationsDocument, options);
        }
export type CertificationsQueryHookResult = ReturnType<typeof useCertificationsQuery>;
export type CertificationsLazyQueryHookResult = ReturnType<typeof useCertificationsLazyQuery>;
export type CertificationsQueryResult = Apollo.QueryResult<Types.CertificationsQuery, Types.CertificationsQueryVariables>;

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyQuery, Types.CompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyQuery, Types.CompanyQueryVariables>(Operations.CompanyDocument, options);
      }
export function useCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyQuery, Types.CompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyQuery, Types.CompanyQueryVariables>(Operations.CompanyDocument, options);
        }
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>;
export type CompanyQueryResult = Apollo.QueryResult<Types.CompanyQuery, Types.CompanyQueryVariables>;

/**
 * __useAutocompleteCompaniesQuery__
 *
 * To run a query within a React component, call `useAutocompleteCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutocompleteCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutocompleteCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAutocompleteCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<Types.AutocompleteCompaniesQuery, Types.AutocompleteCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AutocompleteCompaniesQuery, Types.AutocompleteCompaniesQueryVariables>(Operations.AutocompleteCompaniesDocument, options);
      }
export function useAutocompleteCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AutocompleteCompaniesQuery, Types.AutocompleteCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AutocompleteCompaniesQuery, Types.AutocompleteCompaniesQueryVariables>(Operations.AutocompleteCompaniesDocument, options);
        }
export type AutocompleteCompaniesQueryHookResult = ReturnType<typeof useAutocompleteCompaniesQuery>;
export type AutocompleteCompaniesLazyQueryHookResult = ReturnType<typeof useAutocompleteCompaniesLazyQuery>;
export type AutocompleteCompaniesQueryResult = Apollo.QueryResult<Types.AutocompleteCompaniesQuery, Types.AutocompleteCompaniesQueryVariables>;

/**
 * __useCurrentRecruiterCompanyQuery__
 *
 * To run a query within a React component, call `useCurrentRecruiterCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentRecruiterCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentRecruiterCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentRecruiterCompanyQuery(baseOptions?: Apollo.QueryHookOptions<Types.CurrentRecruiterCompanyQuery, Types.CurrentRecruiterCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CurrentRecruiterCompanyQuery, Types.CurrentRecruiterCompanyQueryVariables>(Operations.CurrentRecruiterCompanyDocument, options);
      }
export function useCurrentRecruiterCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CurrentRecruiterCompanyQuery, Types.CurrentRecruiterCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CurrentRecruiterCompanyQuery, Types.CurrentRecruiterCompanyQueryVariables>(Operations.CurrentRecruiterCompanyDocument, options);
        }
export type CurrentRecruiterCompanyQueryHookResult = ReturnType<typeof useCurrentRecruiterCompanyQuery>;
export type CurrentRecruiterCompanyLazyQueryHookResult = ReturnType<typeof useCurrentRecruiterCompanyLazyQuery>;
export type CurrentRecruiterCompanyQueryResult = Apollo.QueryResult<Types.CurrentRecruiterCompanyQuery, Types.CurrentRecruiterCompanyQueryVariables>;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<Types.UpdateCompanyMutation, Types.UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      avatarFile: // value for 'avatarFile'
 *      backgroundFile: // value for 'backgroundFile'
 *      basicInfo: // value for 'basicInfo'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyMutation, Types.UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyMutation, Types.UpdateCompanyMutationVariables>(Operations.UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<Types.UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyMutation, Types.UpdateCompanyMutationVariables>;

/**
 * __useLastFeedbackQuery__
 *
 * To run a query within a React component, call `useLastFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastFeedbackQuery({
 *   variables: {
 *   },
 * });
 */
export function useLastFeedbackQuery(baseOptions?: Apollo.QueryHookOptions<Types.LastFeedbackQuery, Types.LastFeedbackQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LastFeedbackQuery, Types.LastFeedbackQueryVariables>(Operations.LastFeedbackDocument, options);
      }
export function useLastFeedbackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LastFeedbackQuery, Types.LastFeedbackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LastFeedbackQuery, Types.LastFeedbackQueryVariables>(Operations.LastFeedbackDocument, options);
        }
export type LastFeedbackQueryHookResult = ReturnType<typeof useLastFeedbackQuery>;
export type LastFeedbackLazyQueryHookResult = ReturnType<typeof useLastFeedbackLazyQuery>;
export type LastFeedbackQueryResult = Apollo.QueryResult<Types.LastFeedbackQuery, Types.LastFeedbackQueryVariables>;

/**
 * __useFeedbackIntervalStringQuery__
 *
 * To run a query within a React component, call `useFeedbackIntervalStringQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedbackIntervalStringQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedbackIntervalStringQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeedbackIntervalStringQuery(baseOptions?: Apollo.QueryHookOptions<Types.FeedbackIntervalStringQuery, Types.FeedbackIntervalStringQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FeedbackIntervalStringQuery, Types.FeedbackIntervalStringQueryVariables>(Operations.FeedbackIntervalStringDocument, options);
      }
export function useFeedbackIntervalStringLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FeedbackIntervalStringQuery, Types.FeedbackIntervalStringQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FeedbackIntervalStringQuery, Types.FeedbackIntervalStringQueryVariables>(Operations.FeedbackIntervalStringDocument, options);
        }
export type FeedbackIntervalStringQueryHookResult = ReturnType<typeof useFeedbackIntervalStringQuery>;
export type FeedbackIntervalStringLazyQueryHookResult = ReturnType<typeof useFeedbackIntervalStringLazyQuery>;
export type FeedbackIntervalStringQueryResult = Apollo.QueryResult<Types.FeedbackIntervalStringQuery, Types.FeedbackIntervalStringQueryVariables>;
export type CreateFeedbackMutationFn = Apollo.MutationFunction<Types.CreateFeedbackMutation, Types.CreateFeedbackMutationVariables>;

/**
 * __useCreateFeedbackMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackMutation, { data, loading, error }] = useCreateFeedbackMutation({
 *   variables: {
 *      message: // value for 'message'
 *      rating: // value for 'rating'
 *   },
 * });
 */
export function useCreateFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateFeedbackMutation, Types.CreateFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateFeedbackMutation, Types.CreateFeedbackMutationVariables>(Operations.CreateFeedbackDocument, options);
      }
export type CreateFeedbackMutationHookResult = ReturnType<typeof useCreateFeedbackMutation>;
export type CreateFeedbackMutationResult = Apollo.MutationResult<Types.CreateFeedbackMutation>;
export type CreateFeedbackMutationOptions = Apollo.BaseMutationOptions<Types.CreateFeedbackMutation, Types.CreateFeedbackMutationVariables>;

/**
 * __useIndustriesQuery__
 *
 * To run a query within a React component, call `useIndustriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndustriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndustriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useIndustriesQuery(baseOptions?: Apollo.QueryHookOptions<Types.IndustriesQuery, Types.IndustriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IndustriesQuery, Types.IndustriesQueryVariables>(Operations.IndustriesDocument, options);
      }
export function useIndustriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IndustriesQuery, Types.IndustriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IndustriesQuery, Types.IndustriesQueryVariables>(Operations.IndustriesDocument, options);
        }
export type IndustriesQueryHookResult = ReturnType<typeof useIndustriesQuery>;
export type IndustriesLazyQueryHookResult = ReturnType<typeof useIndustriesLazyQuery>;
export type IndustriesQueryResult = Apollo.QueryResult<Types.IndustriesQuery, Types.IndustriesQueryVariables>;

/**
 * __useInterviewQuery__
 *
 * To run a query within a React component, call `useInterviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInterviewQuery(baseOptions: Apollo.QueryHookOptions<Types.InterviewQuery, Types.InterviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InterviewQuery, Types.InterviewQueryVariables>(Operations.InterviewDocument, options);
      }
export function useInterviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InterviewQuery, Types.InterviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InterviewQuery, Types.InterviewQueryVariables>(Operations.InterviewDocument, options);
        }
export type InterviewQueryHookResult = ReturnType<typeof useInterviewQuery>;
export type InterviewLazyQueryHookResult = ReturnType<typeof useInterviewLazyQuery>;
export type InterviewQueryResult = Apollo.QueryResult<Types.InterviewQuery, Types.InterviewQueryVariables>;

/**
 * __useInterviewsForApplicantQuery__
 *
 * To run a query within a React component, call `useInterviewsForApplicantQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewsForApplicantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewsForApplicantQuery({
 *   variables: {
 *   },
 * });
 */
export function useInterviewsForApplicantQuery(baseOptions?: Apollo.QueryHookOptions<Types.InterviewsForApplicantQuery, Types.InterviewsForApplicantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InterviewsForApplicantQuery, Types.InterviewsForApplicantQueryVariables>(Operations.InterviewsForApplicantDocument, options);
      }
export function useInterviewsForApplicantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InterviewsForApplicantQuery, Types.InterviewsForApplicantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InterviewsForApplicantQuery, Types.InterviewsForApplicantQueryVariables>(Operations.InterviewsForApplicantDocument, options);
        }
export type InterviewsForApplicantQueryHookResult = ReturnType<typeof useInterviewsForApplicantQuery>;
export type InterviewsForApplicantLazyQueryHookResult = ReturnType<typeof useInterviewsForApplicantLazyQuery>;
export type InterviewsForApplicantQueryResult = Apollo.QueryResult<Types.InterviewsForApplicantQuery, Types.InterviewsForApplicantQueryVariables>;

/**
 * __useInterviewsForJobQuery__
 *
 * To run a query within a React component, call `useInterviewsForJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewsForJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewsForJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useInterviewsForJobQuery(baseOptions: Apollo.QueryHookOptions<Types.InterviewsForJobQuery, Types.InterviewsForJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InterviewsForJobQuery, Types.InterviewsForJobQueryVariables>(Operations.InterviewsForJobDocument, options);
      }
export function useInterviewsForJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InterviewsForJobQuery, Types.InterviewsForJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InterviewsForJobQuery, Types.InterviewsForJobQueryVariables>(Operations.InterviewsForJobDocument, options);
        }
export type InterviewsForJobQueryHookResult = ReturnType<typeof useInterviewsForJobQuery>;
export type InterviewsForJobLazyQueryHookResult = ReturnType<typeof useInterviewsForJobLazyQuery>;
export type InterviewsForJobQueryResult = Apollo.QueryResult<Types.InterviewsForJobQuery, Types.InterviewsForJobQueryVariables>;
export type CreateInterviewMutationFn = Apollo.MutationFunction<Types.CreateInterviewMutation, Types.CreateInterviewMutationVariables>;

/**
 * __useCreateInterviewMutation__
 *
 * To run a mutation, you first call `useCreateInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInterviewMutation, { data, loading, error }] = useCreateInterviewMutation({
 *   variables: {
 *      interviewFields: // value for 'interviewFields'
 *   },
 * });
 */
export function useCreateInterviewMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateInterviewMutation, Types.CreateInterviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateInterviewMutation, Types.CreateInterviewMutationVariables>(Operations.CreateInterviewDocument, options);
      }
export type CreateInterviewMutationHookResult = ReturnType<typeof useCreateInterviewMutation>;
export type CreateInterviewMutationResult = Apollo.MutationResult<Types.CreateInterviewMutation>;
export type CreateInterviewMutationOptions = Apollo.BaseMutationOptions<Types.CreateInterviewMutation, Types.CreateInterviewMutationVariables>;
export type SelectInterviewMutationFn = Apollo.MutationFunction<Types.SelectInterviewMutation, Types.SelectInterviewMutationVariables>;

/**
 * __useSelectInterviewMutation__
 *
 * To run a mutation, you first call `useSelectInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectInterviewMutation, { data, loading, error }] = useSelectInterviewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSelectInterviewMutation(baseOptions?: Apollo.MutationHookOptions<Types.SelectInterviewMutation, Types.SelectInterviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SelectInterviewMutation, Types.SelectInterviewMutationVariables>(Operations.SelectInterviewDocument, options);
      }
export type SelectInterviewMutationHookResult = ReturnType<typeof useSelectInterviewMutation>;
export type SelectInterviewMutationResult = Apollo.MutationResult<Types.SelectInterviewMutation>;
export type SelectInterviewMutationOptions = Apollo.BaseMutationOptions<Types.SelectInterviewMutation, Types.SelectInterviewMutationVariables>;
export type DeleteInterviewMutationFn = Apollo.MutationFunction<Types.DeleteInterviewMutation, Types.DeleteInterviewMutationVariables>;

/**
 * __useDeleteInterviewMutation__
 *
 * To run a mutation, you first call `useDeleteInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInterviewMutation, { data, loading, error }] = useDeleteInterviewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInterviewMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteInterviewMutation, Types.DeleteInterviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteInterviewMutation, Types.DeleteInterviewMutationVariables>(Operations.DeleteInterviewDocument, options);
      }
export type DeleteInterviewMutationHookResult = ReturnType<typeof useDeleteInterviewMutation>;
export type DeleteInterviewMutationResult = Apollo.MutationResult<Types.DeleteInterviewMutation>;
export type DeleteInterviewMutationOptions = Apollo.BaseMutationOptions<Types.DeleteInterviewMutation, Types.DeleteInterviewMutationVariables>;
export type CreateIssueMutationFn = Apollo.MutationFunction<Types.CreateIssueMutation, Types.CreateIssueMutationVariables>;

/**
 * __useCreateIssueMutation__
 *
 * To run a mutation, you first call `useCreateIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIssueMutation, { data, loading, error }] = useCreateIssueMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useCreateIssueMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateIssueMutation, Types.CreateIssueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateIssueMutation, Types.CreateIssueMutationVariables>(Operations.CreateIssueDocument, options);
      }
export type CreateIssueMutationHookResult = ReturnType<typeof useCreateIssueMutation>;
export type CreateIssueMutationResult = Apollo.MutationResult<Types.CreateIssueMutation>;
export type CreateIssueMutationOptions = Apollo.BaseMutationOptions<Types.CreateIssueMutation, Types.CreateIssueMutationVariables>;

/**
 * __useJobBasicQuery__
 *
 * To run a query within a React component, call `useJobBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobBasicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobBasicQuery(baseOptions: Apollo.QueryHookOptions<Types.JobBasicQuery, Types.JobBasicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.JobBasicQuery, Types.JobBasicQueryVariables>(Operations.JobBasicDocument, options);
      }
export function useJobBasicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.JobBasicQuery, Types.JobBasicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.JobBasicQuery, Types.JobBasicQueryVariables>(Operations.JobBasicDocument, options);
        }
export type JobBasicQueryHookResult = ReturnType<typeof useJobBasicQuery>;
export type JobBasicLazyQueryHookResult = ReturnType<typeof useJobBasicLazyQuery>;
export type JobBasicQueryResult = Apollo.QueryResult<Types.JobBasicQuery, Types.JobBasicQueryVariables>;

/**
 * __useJobWithCompanyQuery__
 *
 * To run a query within a React component, call `useJobWithCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobWithCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobWithCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobWithCompanyQuery(baseOptions: Apollo.QueryHookOptions<Types.JobWithCompanyQuery, Types.JobWithCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.JobWithCompanyQuery, Types.JobWithCompanyQueryVariables>(Operations.JobWithCompanyDocument, options);
      }
export function useJobWithCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.JobWithCompanyQuery, Types.JobWithCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.JobWithCompanyQuery, Types.JobWithCompanyQueryVariables>(Operations.JobWithCompanyDocument, options);
        }
export type JobWithCompanyQueryHookResult = ReturnType<typeof useJobWithCompanyQuery>;
export type JobWithCompanyLazyQueryHookResult = ReturnType<typeof useJobWithCompanyLazyQuery>;
export type JobWithCompanyQueryResult = Apollo.QueryResult<Types.JobWithCompanyQuery, Types.JobWithCompanyQueryVariables>;

/**
 * __useJobWithInterviewQuery__
 *
 * To run a query within a React component, call `useJobWithInterviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobWithInterviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobWithInterviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobWithInterviewQuery(baseOptions: Apollo.QueryHookOptions<Types.JobWithInterviewQuery, Types.JobWithInterviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.JobWithInterviewQuery, Types.JobWithInterviewQueryVariables>(Operations.JobWithInterviewDocument, options);
      }
export function useJobWithInterviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.JobWithInterviewQuery, Types.JobWithInterviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.JobWithInterviewQuery, Types.JobWithInterviewQueryVariables>(Operations.JobWithInterviewDocument, options);
        }
export type JobWithInterviewQueryHookResult = ReturnType<typeof useJobWithInterviewQuery>;
export type JobWithInterviewLazyQueryHookResult = ReturnType<typeof useJobWithInterviewLazyQuery>;
export type JobWithInterviewQueryResult = Apollo.QueryResult<Types.JobWithInterviewQuery, Types.JobWithInterviewQueryVariables>;

/**
 * __useRecruiterJobsQuery__
 *
 * To run a query within a React component, call `useRecruiterJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruiterJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruiterJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecruiterJobsQuery(baseOptions?: Apollo.QueryHookOptions<Types.RecruiterJobsQuery, Types.RecruiterJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RecruiterJobsQuery, Types.RecruiterJobsQueryVariables>(Operations.RecruiterJobsDocument, options);
      }
export function useRecruiterJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RecruiterJobsQuery, Types.RecruiterJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RecruiterJobsQuery, Types.RecruiterJobsQueryVariables>(Operations.RecruiterJobsDocument, options);
        }
export type RecruiterJobsQueryHookResult = ReturnType<typeof useRecruiterJobsQuery>;
export type RecruiterJobsLazyQueryHookResult = ReturnType<typeof useRecruiterJobsLazyQuery>;
export type RecruiterJobsQueryResult = Apollo.QueryResult<Types.RecruiterJobsQuery, Types.RecruiterJobsQueryVariables>;

/**
 * __useMatchedJobsQuery__
 *
 * To run a query within a React component, call `useMatchedJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMatchedJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatchedJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMatchedJobsQuery(baseOptions?: Apollo.QueryHookOptions<Types.MatchedJobsQuery, Types.MatchedJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MatchedJobsQuery, Types.MatchedJobsQueryVariables>(Operations.MatchedJobsDocument, options);
      }
export function useMatchedJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MatchedJobsQuery, Types.MatchedJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MatchedJobsQuery, Types.MatchedJobsQueryVariables>(Operations.MatchedJobsDocument, options);
        }
export type MatchedJobsQueryHookResult = ReturnType<typeof useMatchedJobsQuery>;
export type MatchedJobsLazyQueryHookResult = ReturnType<typeof useMatchedJobsLazyQuery>;
export type MatchedJobsQueryResult = Apollo.QueryResult<Types.MatchedJobsQuery, Types.MatchedJobsQueryVariables>;
export type CreateJobMutationFn = Apollo.MutationFunction<Types.CreateJobMutation, Types.CreateJobMutationVariables>;

/**
 * __useCreateJobMutation__
 *
 * To run a mutation, you first call `useCreateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobMutation, { data, loading, error }] = useCreateJobMutation({
 *   variables: {
 *      jobFields: // value for 'jobFields'
 *   },
 * });
 */
export function useCreateJobMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateJobMutation, Types.CreateJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateJobMutation, Types.CreateJobMutationVariables>(Operations.CreateJobDocument, options);
      }
export type CreateJobMutationHookResult = ReturnType<typeof useCreateJobMutation>;
export type CreateJobMutationResult = Apollo.MutationResult<Types.CreateJobMutation>;
export type CreateJobMutationOptions = Apollo.BaseMutationOptions<Types.CreateJobMutation, Types.CreateJobMutationVariables>;
export type UpdateInterviewDetailsMutationFn = Apollo.MutationFunction<Types.UpdateInterviewDetailsMutation, Types.UpdateInterviewDetailsMutationVariables>;

/**
 * __useUpdateInterviewDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateInterviewDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInterviewDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInterviewDetailsMutation, { data, loading, error }] = useUpdateInterviewDetailsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      interviewDetailsFields: // value for 'interviewDetailsFields'
 *   },
 * });
 */
export function useUpdateInterviewDetailsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateInterviewDetailsMutation, Types.UpdateInterviewDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateInterviewDetailsMutation, Types.UpdateInterviewDetailsMutationVariables>(Operations.UpdateInterviewDetailsDocument, options);
      }
export type UpdateInterviewDetailsMutationHookResult = ReturnType<typeof useUpdateInterviewDetailsMutation>;
export type UpdateInterviewDetailsMutationResult = Apollo.MutationResult<Types.UpdateInterviewDetailsMutation>;
export type UpdateInterviewDetailsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateInterviewDetailsMutation, Types.UpdateInterviewDetailsMutationVariables>;

/**
 * __useJobTemplateByPositionQuery__
 *
 * To run a query within a React component, call `useJobTemplateByPositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobTemplateByPositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobTemplateByPositionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobTemplateByPositionQuery(baseOptions: Apollo.QueryHookOptions<Types.JobTemplateByPositionQuery, Types.JobTemplateByPositionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.JobTemplateByPositionQuery, Types.JobTemplateByPositionQueryVariables>(Operations.JobTemplateByPositionDocument, options);
      }
export function useJobTemplateByPositionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.JobTemplateByPositionQuery, Types.JobTemplateByPositionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.JobTemplateByPositionQuery, Types.JobTemplateByPositionQueryVariables>(Operations.JobTemplateByPositionDocument, options);
        }
export type JobTemplateByPositionQueryHookResult = ReturnType<typeof useJobTemplateByPositionQuery>;
export type JobTemplateByPositionLazyQueryHookResult = ReturnType<typeof useJobTemplateByPositionLazyQuery>;
export type JobTemplateByPositionQueryResult = Apollo.QueryResult<Types.JobTemplateByPositionQuery, Types.JobTemplateByPositionQueryVariables>;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      receiver: // value for 'receiver'
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions: Apollo.QueryHookOptions<Types.NotificationsQuery, Types.NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NotificationsQuery, Types.NotificationsQueryVariables>(Operations.NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NotificationsQuery, Types.NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NotificationsQuery, Types.NotificationsQueryVariables>(Operations.NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<Types.NotificationsQuery, Types.NotificationsQueryVariables>;
export type ReadNotificationsMutationFn = Apollo.MutationFunction<Types.ReadNotificationsMutation, Types.ReadNotificationsMutationVariables>;

/**
 * __useReadNotificationsMutation__
 *
 * To run a mutation, you first call `useReadNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readNotificationsMutation, { data, loading, error }] = useReadNotificationsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<Types.ReadNotificationsMutation, Types.ReadNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ReadNotificationsMutation, Types.ReadNotificationsMutationVariables>(Operations.ReadNotificationsDocument, options);
      }
export type ReadNotificationsMutationHookResult = ReturnType<typeof useReadNotificationsMutation>;
export type ReadNotificationsMutationResult = Apollo.MutationResult<Types.ReadNotificationsMutation>;
export type ReadNotificationsMutationOptions = Apollo.BaseMutationOptions<Types.ReadNotificationsMutation, Types.ReadNotificationsMutationVariables>;

/**
 * __useSubscribeNotificationsSubscription__
 *
 * To run a query within a React component, call `useSubscribeNotificationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeNotificationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeNotificationsSubscription({
 *   variables: {
 *      receiver: // value for 'receiver'
 *   },
 * });
 */
export function useSubscribeNotificationsSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.SubscribeNotificationsSubscription, Types.SubscribeNotificationsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.SubscribeNotificationsSubscription, Types.SubscribeNotificationsSubscriptionVariables>(Operations.SubscribeNotificationsDocument, options);
      }
export type SubscribeNotificationsSubscriptionHookResult = ReturnType<typeof useSubscribeNotificationsSubscription>;
export type SubscribeNotificationsSubscriptionResult = Apollo.SubscriptionResult<Types.SubscribeNotificationsSubscription>;
export type CreateSubscriptionCheckoutSessionMutationFn = Apollo.MutationFunction<Types.CreateSubscriptionCheckoutSessionMutation, Types.CreateSubscriptionCheckoutSessionMutationVariables>;

/**
 * __useCreateSubscriptionCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionCheckoutSessionMutation, { data, loading, error }] = useCreateSubscriptionCheckoutSessionMutation({
 *   variables: {
 *      plan: // value for 'plan'
 *      successUrl: // value for 'successUrl'
 *      cancelUrl: // value for 'cancelUrl'
 *   },
 * });
 */
export function useCreateSubscriptionCheckoutSessionMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateSubscriptionCheckoutSessionMutation, Types.CreateSubscriptionCheckoutSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateSubscriptionCheckoutSessionMutation, Types.CreateSubscriptionCheckoutSessionMutationVariables>(Operations.CreateSubscriptionCheckoutSessionDocument, options);
      }
export type CreateSubscriptionCheckoutSessionMutationHookResult = ReturnType<typeof useCreateSubscriptionCheckoutSessionMutation>;
export type CreateSubscriptionCheckoutSessionMutationResult = Apollo.MutationResult<Types.CreateSubscriptionCheckoutSessionMutation>;
export type CreateSubscriptionCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<Types.CreateSubscriptionCheckoutSessionMutation, Types.CreateSubscriptionCheckoutSessionMutationVariables>;
export type ProcessSuccessfulCheckoutSessionMutationFn = Apollo.MutationFunction<Types.ProcessSuccessfulCheckoutSessionMutation, Types.ProcessSuccessfulCheckoutSessionMutationVariables>;

/**
 * __useProcessSuccessfulCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useProcessSuccessfulCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessSuccessfulCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processSuccessfulCheckoutSessionMutation, { data, loading, error }] = useProcessSuccessfulCheckoutSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useProcessSuccessfulCheckoutSessionMutation(baseOptions?: Apollo.MutationHookOptions<Types.ProcessSuccessfulCheckoutSessionMutation, Types.ProcessSuccessfulCheckoutSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProcessSuccessfulCheckoutSessionMutation, Types.ProcessSuccessfulCheckoutSessionMutationVariables>(Operations.ProcessSuccessfulCheckoutSessionDocument, options);
      }
export type ProcessSuccessfulCheckoutSessionMutationHookResult = ReturnType<typeof useProcessSuccessfulCheckoutSessionMutation>;
export type ProcessSuccessfulCheckoutSessionMutationResult = Apollo.MutationResult<Types.ProcessSuccessfulCheckoutSessionMutation>;
export type ProcessSuccessfulCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<Types.ProcessSuccessfulCheckoutSessionMutation, Types.ProcessSuccessfulCheckoutSessionMutationVariables>;
export type CreateBillingPortalSessionMutationFn = Apollo.MutationFunction<Types.CreateBillingPortalSessionMutation, Types.CreateBillingPortalSessionMutationVariables>;

/**
 * __useCreateBillingPortalSessionMutation__
 *
 * To run a mutation, you first call `useCreateBillingPortalSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBillingPortalSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBillingPortalSessionMutation, { data, loading, error }] = useCreateBillingPortalSessionMutation({
 *   variables: {
 *      returnUrl: // value for 'returnUrl'
 *   },
 * });
 */
export function useCreateBillingPortalSessionMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateBillingPortalSessionMutation, Types.CreateBillingPortalSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateBillingPortalSessionMutation, Types.CreateBillingPortalSessionMutationVariables>(Operations.CreateBillingPortalSessionDocument, options);
      }
export type CreateBillingPortalSessionMutationHookResult = ReturnType<typeof useCreateBillingPortalSessionMutation>;
export type CreateBillingPortalSessionMutationResult = Apollo.MutationResult<Types.CreateBillingPortalSessionMutation>;
export type CreateBillingPortalSessionMutationOptions = Apollo.BaseMutationOptions<Types.CreateBillingPortalSessionMutation, Types.CreateBillingPortalSessionMutationVariables>;

/**
 * __usePositionsQuery__
 *
 * To run a query within a React component, call `usePositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositionsQuery({
 *   variables: {
 *      industry: // value for 'industry'
 *   },
 * });
 */
export function usePositionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.PositionsQuery, Types.PositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PositionsQuery, Types.PositionsQueryVariables>(Operations.PositionsDocument, options);
      }
export function usePositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PositionsQuery, Types.PositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PositionsQuery, Types.PositionsQueryVariables>(Operations.PositionsDocument, options);
        }
export type PositionsQueryHookResult = ReturnType<typeof usePositionsQuery>;
export type PositionsLazyQueryHookResult = ReturnType<typeof usePositionsLazyQuery>;
export type PositionsQueryResult = Apollo.QueryResult<Types.PositionsQuery, Types.PositionsQueryVariables>;

/**
 * __usePositionBasicQuery__
 *
 * To run a query within a React component, call `usePositionBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `usePositionBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositionBasicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePositionBasicQuery(baseOptions: Apollo.QueryHookOptions<Types.PositionBasicQuery, Types.PositionBasicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PositionBasicQuery, Types.PositionBasicQueryVariables>(Operations.PositionBasicDocument, options);
      }
export function usePositionBasicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PositionBasicQuery, Types.PositionBasicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PositionBasicQuery, Types.PositionBasicQueryVariables>(Operations.PositionBasicDocument, options);
        }
export type PositionBasicQueryHookResult = ReturnType<typeof usePositionBasicQuery>;
export type PositionBasicLazyQueryHookResult = ReturnType<typeof usePositionBasicLazyQuery>;
export type PositionBasicQueryResult = Apollo.QueryResult<Types.PositionBasicQuery, Types.PositionBasicQueryVariables>;

/**
 * __useQuestionnaireResultQuery__
 *
 * To run a query within a React component, call `useQuestionnaireResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireResultQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuestionnaireResultQuery(baseOptions?: Apollo.QueryHookOptions<Types.QuestionnaireResultQuery, Types.QuestionnaireResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.QuestionnaireResultQuery, Types.QuestionnaireResultQueryVariables>(Operations.QuestionnaireResultDocument, options);
      }
export function useQuestionnaireResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.QuestionnaireResultQuery, Types.QuestionnaireResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.QuestionnaireResultQuery, Types.QuestionnaireResultQueryVariables>(Operations.QuestionnaireResultDocument, options);
        }
export type QuestionnaireResultQueryHookResult = ReturnType<typeof useQuestionnaireResultQuery>;
export type QuestionnaireResultLazyQueryHookResult = ReturnType<typeof useQuestionnaireResultLazyQuery>;
export type QuestionnaireResultQueryResult = Apollo.QueryResult<Types.QuestionnaireResultQuery, Types.QuestionnaireResultQueryVariables>;

/**
 * __useQuestionnaireResultProgressQuery__
 *
 * To run a query within a React component, call `useQuestionnaireResultProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireResultProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireResultProgressQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuestionnaireResultProgressQuery(baseOptions?: Apollo.QueryHookOptions<Types.QuestionnaireResultProgressQuery, Types.QuestionnaireResultProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.QuestionnaireResultProgressQuery, Types.QuestionnaireResultProgressQueryVariables>(Operations.QuestionnaireResultProgressDocument, options);
      }
export function useQuestionnaireResultProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.QuestionnaireResultProgressQuery, Types.QuestionnaireResultProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.QuestionnaireResultProgressQuery, Types.QuestionnaireResultProgressQueryVariables>(Operations.QuestionnaireResultProgressDocument, options);
        }
export type QuestionnaireResultProgressQueryHookResult = ReturnType<typeof useQuestionnaireResultProgressQuery>;
export type QuestionnaireResultProgressLazyQueryHookResult = ReturnType<typeof useQuestionnaireResultProgressLazyQuery>;
export type QuestionnaireResultProgressQueryResult = Apollo.QueryResult<Types.QuestionnaireResultProgressQuery, Types.QuestionnaireResultProgressQueryVariables>;
export type UpdateQuestionnaireAnswersMutationFn = Apollo.MutationFunction<Types.UpdateQuestionnaireAnswersMutation, Types.UpdateQuestionnaireAnswersMutationVariables>;

/**
 * __useUpdateQuestionnaireAnswersMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionnaireAnswersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionnaireAnswersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionnaireAnswersMutation, { data, loading, error }] = useUpdateQuestionnaireAnswersMutation({
 *   variables: {
 *      id: // value for 'id'
 *      answers: // value for 'answers'
 *   },
 * });
 */
export function useUpdateQuestionnaireAnswersMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateQuestionnaireAnswersMutation, Types.UpdateQuestionnaireAnswersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateQuestionnaireAnswersMutation, Types.UpdateQuestionnaireAnswersMutationVariables>(Operations.UpdateQuestionnaireAnswersDocument, options);
      }
export type UpdateQuestionnaireAnswersMutationHookResult = ReturnType<typeof useUpdateQuestionnaireAnswersMutation>;
export type UpdateQuestionnaireAnswersMutationResult = Apollo.MutationResult<Types.UpdateQuestionnaireAnswersMutation>;
export type UpdateQuestionnaireAnswersMutationOptions = Apollo.BaseMutationOptions<Types.UpdateQuestionnaireAnswersMutation, Types.UpdateQuestionnaireAnswersMutationVariables>;

/**
 * __useRecruiterWithCompanyQuery__
 *
 * To run a query within a React component, call `useRecruiterWithCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruiterWithCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruiterWithCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRecruiterWithCompanyQuery(baseOptions: Apollo.QueryHookOptions<Types.RecruiterWithCompanyQuery, Types.RecruiterWithCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RecruiterWithCompanyQuery, Types.RecruiterWithCompanyQueryVariables>(Operations.RecruiterWithCompanyDocument, options);
      }
export function useRecruiterWithCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RecruiterWithCompanyQuery, Types.RecruiterWithCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RecruiterWithCompanyQuery, Types.RecruiterWithCompanyQueryVariables>(Operations.RecruiterWithCompanyDocument, options);
        }
export type RecruiterWithCompanyQueryHookResult = ReturnType<typeof useRecruiterWithCompanyQuery>;
export type RecruiterWithCompanyLazyQueryHookResult = ReturnType<typeof useRecruiterWithCompanyLazyQuery>;
export type RecruiterWithCompanyQueryResult = Apollo.QueryResult<Types.RecruiterWithCompanyQuery, Types.RecruiterWithCompanyQueryVariables>;

/**
 * __useRecruiterWithoutCompanyQuery__
 *
 * To run a query within a React component, call `useRecruiterWithoutCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruiterWithoutCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruiterWithoutCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRecruiterWithoutCompanyQuery(baseOptions: Apollo.QueryHookOptions<Types.RecruiterWithoutCompanyQuery, Types.RecruiterWithoutCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RecruiterWithoutCompanyQuery, Types.RecruiterWithoutCompanyQueryVariables>(Operations.RecruiterWithoutCompanyDocument, options);
      }
export function useRecruiterWithoutCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RecruiterWithoutCompanyQuery, Types.RecruiterWithoutCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RecruiterWithoutCompanyQuery, Types.RecruiterWithoutCompanyQueryVariables>(Operations.RecruiterWithoutCompanyDocument, options);
        }
export type RecruiterWithoutCompanyQueryHookResult = ReturnType<typeof useRecruiterWithoutCompanyQuery>;
export type RecruiterWithoutCompanyLazyQueryHookResult = ReturnType<typeof useRecruiterWithoutCompanyLazyQuery>;
export type RecruiterWithoutCompanyQueryResult = Apollo.QueryResult<Types.RecruiterWithoutCompanyQuery, Types.RecruiterWithoutCompanyQueryVariables>;
export type SignUpRecruiterMutationFn = Apollo.MutationFunction<Types.SignUpRecruiterMutation, Types.SignUpRecruiterMutationVariables>;

/**
 * __useSignUpRecruiterMutation__
 *
 * To run a mutation, you first call `useSignUpRecruiterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpRecruiterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpRecruiterMutation, { data, loading, error }] = useSignUpRecruiterMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *      companyInput: // value for 'companyInput'
 *   },
 * });
 */
export function useSignUpRecruiterMutation(baseOptions?: Apollo.MutationHookOptions<Types.SignUpRecruiterMutation, Types.SignUpRecruiterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SignUpRecruiterMutation, Types.SignUpRecruiterMutationVariables>(Operations.SignUpRecruiterDocument, options);
      }
export type SignUpRecruiterMutationHookResult = ReturnType<typeof useSignUpRecruiterMutation>;
export type SignUpRecruiterMutationResult = Apollo.MutationResult<Types.SignUpRecruiterMutation>;
export type SignUpRecruiterMutationOptions = Apollo.BaseMutationOptions<Types.SignUpRecruiterMutation, Types.SignUpRecruiterMutationVariables>;
export type UpdateRecruiterMutationFn = Apollo.MutationFunction<Types.UpdateRecruiterMutation, Types.UpdateRecruiterMutationVariables>;

/**
 * __useUpdateRecruiterMutation__
 *
 * To run a mutation, you first call `useUpdateRecruiterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecruiterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecruiterMutation, { data, loading, error }] = useUpdateRecruiterMutation({
 *   variables: {
 *      applicantRecruiterIntroInput: // value for 'applicantRecruiterIntroInput'
 *   },
 * });
 */
export function useUpdateRecruiterMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateRecruiterMutation, Types.UpdateRecruiterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateRecruiterMutation, Types.UpdateRecruiterMutationVariables>(Operations.UpdateRecruiterDocument, options);
      }
export type UpdateRecruiterMutationHookResult = ReturnType<typeof useUpdateRecruiterMutation>;
export type UpdateRecruiterMutationResult = Apollo.MutationResult<Types.UpdateRecruiterMutation>;
export type UpdateRecruiterMutationOptions = Apollo.BaseMutationOptions<Types.UpdateRecruiterMutation, Types.UpdateRecruiterMutationVariables>;

/**
 * __useSkillsQuery__
 *
 * To run a query within a React component, call `useSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkillsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSkillsQuery(baseOptions?: Apollo.QueryHookOptions<Types.SkillsQuery, Types.SkillsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SkillsQuery, Types.SkillsQueryVariables>(Operations.SkillsDocument, options);
      }
export function useSkillsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SkillsQuery, Types.SkillsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SkillsQuery, Types.SkillsQueryVariables>(Operations.SkillsDocument, options);
        }
export type SkillsQueryHookResult = ReturnType<typeof useSkillsQuery>;
export type SkillsLazyQueryHookResult = ReturnType<typeof useSkillsLazyQuery>;
export type SkillsQueryResult = Apollo.QueryResult<Types.SkillsQuery, Types.SkillsQueryVariables>;