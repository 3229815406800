import { Theme } from "@mui/material/styles";
import theme from "../../gatsby-theme-material-ui-top-layout/theme";

export const EMAIL_BAR_HEIGHT = 45.4;

export const LAYOUT_PADDING = 3; // Do not change

export const HEADER_COLOR = "#F6F6F6";

// Background for the App (/app)
export const APP_BACKGROUND = "#f3f2ef";

export const BOX_BACKGROUND_COLOR = "#fff";

export const HEADER_ELEVATION = 1;

export const OVERLAY_BACKGROUND = "rgba(0,0,0,0.37)";

export const PREVIEW_AVATAR_DIMENSION = 95;

export const PREVIEW_AVATAR_DIMENSIONS = {
  width: PREVIEW_AVATAR_DIMENSION,
  height: PREVIEW_AVATAR_DIMENSION
};

export const TEXT_CAPITALIZE = {
  textTransform: "capitalize" as const
};

export const FULL_SCREEN_WIDTH = {
  width: "100vw",
  marginLeft: "calc(-50vw + 50%)"
};

export const multiLineTrim = (lines: number) => ({
  display: "-webkit-box",
  WebkitLineClamp: `${lines}`,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis"
});

export const addExtraLines = (lines: number) =>
  ({
    "&::after": {
      content: `"${"\\a".repeat(lines + 1)}"`,
      whiteSpace: "pre"
    }
  } as const);

export const noBackgroundWrapper = (minHeight = 500, noPadding = false) => ({
  minHeight: minHeight,
  padding: noPadding ? 0 : "40px 0",
  display: "flex",
  flexDirection: "column" as const,
  justifyContent: "center"
});

export const customButtonColor = (backgroundColor: string) => {
  const { dark } = theme.palette.augmentColor({ color: { main: backgroundColor } });
  return {
    backgroundColor,
    color: theme.palette.getContrastText(backgroundColor),
    "&:hover": {
      backgroundColor: dark
    }
  };
};

export const primaryBoxBorder = (theme: Theme) => ({
  border: `0.5px solid ${theme.palette.primary.main}`
});

const defaultDividerWithContentOptions = {
  borderWidth: 1,
  borderColor: "#b7c9cc",
  contentHorizontalPadding: 3
};

export const dividerWithContent = (
  theme: Theme,
  options?: Partial<typeof defaultDividerWithContentOptions>
) => {
  options = {
    ...defaultDividerWithContentOptions,
    ...options
  };
  return {
    position: "relative",
    textAlign: "center",
    "&::before": {
      position: "absolute",
      top: "50%",
      left: 0,
      width: "100%",
      borderTop: `${options.borderWidth}px solid ${options.borderColor}`,
      content: "''"
    },
    "& :first-child": {
      position: "relative",
      zIndex: 1,
      padding: theme.spacing(0, options.contentHorizontalPadding),
      background: "#fff"
    }
  } as const;
};

export const profileItemDivider = (theme: Theme) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(3),
  backgroundColor: theme.palette.secondary.main
});
