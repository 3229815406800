import { makeStyles } from "@placehires/react-component-library";
import React from "react";
import { LAYOUT_PADDING } from "../constants/styles";
import Footer from "./Footer";
import PublicHeader from "./PublicHeader";
import SEO from "./SEO";

type StyleProps = {
  fullHeight?: boolean;
  bodyPadding?: boolean;
};

type PublicLayoutProps = StyleProps & {
  children: React.ReactNode;
  seoTitle: string;
  headerColor?: string;
  stickyHeader?: boolean;
  withoutSiteTitle?: boolean;
  noFooter?: boolean;
};

const PublicLayout: React.FC<PublicLayoutProps> = ({
  children,
  stickyHeader,
  seoTitle,
  withoutSiteTitle,
  noFooter,
  headerColor,
  ...styleProps
}) => {
  const { classes } = useStyles(styleProps);

  return (
    <div className={classes.wrapper}>
      <SEO title={seoTitle} withoutSiteTitle={withoutSiteTitle} />
      <PublicHeader color={headerColor} sticky={stickyHeader} />
      <main className={classes.body}>{children}</main>
      {!noFooter && <Footer />}
    </div>
  );
};

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  wrapper: {
    minHeight: "100%",
    ...(props.fullHeight && {
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: "flex",
      flexDirection: "column"
    })
  },
  body: {
    flex: 1,
    ...(props.bodyPadding && {
      paddingTop: theme.spacing(LAYOUT_PADDING),
      paddingBottom: theme.spacing(LAYOUT_PADDING)
    })
  }
}));

export default PublicLayout;
