import React from "react";
import { APPLICANT_ROLE, RECRUITER_ROLE } from "../constants/general";
import { SubscriptionPlan } from "../generated/graphqlTypes";

export type AuthUserClaims = {
  role: typeof APPLICANT_ROLE | typeof RECRUITER_ROLE;
  stripeId?: string;
  accessLevel?: SubscriptionPlan;
  /** Date when current (subscription) period ends */
  currentPeriodEnd?: Date;
};

export type AuthUser = AuthUserClaims & {
  email: string;
  uid: string;
  emailVerified: boolean;
  displayName: string;
  photoURL: string;
};

const AuthUserContext: React.Context<AuthUser> = React.createContext(null);

export default AuthUserContext;
