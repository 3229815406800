import { ApolloClient, ApolloLink, InMemoryCache, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { alertAction, alertError, inBrowser } from "@placehires/react-component-library";
import { withScalars } from "apollo-link-scalars";
import { createUploadLink } from "apollo-upload-client";
import { buildClientSchema, GraphQLError } from "graphql";
import { IntrospectionQuery } from "graphql/utilities/getIntrospectionQuery";
import fetch from "isomorphic-fetch";
import omitDeep from "omit-deep-lodash";
import { SubscriptionClient } from "subscriptions-transport-ws";
import backendIntrospection from "../generated/backend.schema.json";
import { ErrorCode } from "../generated/graphqlTypes";
import { getUserToken, logout } from "../services/authService";
import { reportError } from "../services/errorService";

// Creating a mutation link that removes __typename from requests
const mutationLink = new ApolloLink((operation, forward) => {
  operation.variables = omitDeep(operation.variables, "__typename");
  return forward(operation);
});

// Creating auth link that attaches token to requests
const authLink = setContext(async () => {
  const token = await getUserToken();
  return {
    headers: {
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

// Creating http link for queries and mutations, with file uploads
const httpLinkWithUpload = createUploadLink({
  uri: `${process.env.GATSBY_BACKEND_URL}graphql`,
  credentials: "include",
  fetch
});

// Creating websocket client and link for subscriptions
const wsClient =
  inBrowser() &&
  new SubscriptionClient(process.env.GATSBY_WEBSOCKET_URI, {
    lazy: true,
    reconnect: true,
    minTimeout: 3000,
    connectionParams: async () => {
      const authToken = await getUserToken();
      return { authToken };
    }
  });
const wsLink = inBrowser() && new WebSocketLink(wsClient);

// Creating a link based on operation
const operationBasedLink = inBrowser()
  ? split(
      // Split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === "OperationDefinition" && definition.operation === "subscription";
      },
      wsLink,
      httpLinkWithUpload
    )
  : httpLinkWithUpload;

const handleErrorsWithExtensions = (err: GraphQLError) => {
  const {
    message,
    extensions: { code, actionRequired },
    originalError
  } = err;
  if (code === ErrorCode.UNAUTHENTICATED || code === ErrorCode.FORBIDDEN) {
    if (code === ErrorCode.UNAUTHENTICATED) {
      logout();
    } else if (!message) {
      alertError("You are not authorized to perform that operation");
    } else if (actionRequired) {
      alertAction(message);
    } else {
      alertError(message);
    }
  } else if (code === ErrorCode.BAD_USER_INPUT) {
    reportError(originalError || err);
  } else {
    console.error(err);
  }
};

// Creating a link that handles errors
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map((err) => {
      const { message, extensions, originalError } = err;
      if (extensions) {
        handleErrorsWithExtensions(err);
      } else {
        alertError();
        console.log(`[GraphQL error]: Message: ${message}`);
        reportError(originalError || err);
      }
    });
  }
  if (networkError) {
    if (!("statusCode" in networkError && networkError?.statusCode < 500)) {
      alertError();
      console.log(`[Network error]: ${networkError}`);
    }
    reportError(networkError);
  }
});

// Creating a link that handles custom client scalar parsing and serialization
const scalarsLink = withScalars({
  schema: buildClientSchema(backendIntrospection as unknown as IntrospectionQuery),
  typesMap: {
    DateTime: {
      serialize: (value) => value,
      parseValue: (value) => new Date(value as string)
    }
  }
});

// Creating apollo client
const client = new ApolloClient({
  link: ApolloLink.from([errorLink, mutationLink, authLink, scalarsLink, operationBasedLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: { errorPolicy: "all" },
    mutate: { errorPolicy: "all" },
    query: { errorPolicy: "all" }
  }
});

/** Closes websocket connection and clears cache. Used for logout */
export const logoutApollo = () => {
  wsClient.close();
  client.clearStore();
};

export default client;
