import Container from "@mui/material/Container";
import { makeStyles } from "@placehires/react-component-library";
import React from "react";
import EmailVerificationBar from "../components/EmailVerificationBar";
import { LAYOUT_PADDING } from "../constants/styles";
import PrivateHeader from "./PrivateHeader";
import SEO from "./SEO";

type PrivateLayoutProps = {
  children: React.ReactNode;
  disableGutters?: boolean;
  fullHeight?: boolean;
  disableGuttersMobile?: boolean;
  disableHorizontalGuttersMobile?: boolean;
  className?: string; // Classname applied to "main"
};

const PrivateLayout: React.FC<PrivateLayoutProps> = ({
  children,
  disableGutters,
  disableGuttersMobile,
  disableHorizontalGuttersMobile,
  fullHeight,
  className
}) => {
  const { classes, cx } = useStyles({
    fullHeight,
    disableGutters,
    disableGuttersMobile,
    disableHorizontalGuttersMobile
  });

  return (
    <div className={fullHeight ? classes.outer : ""}>
      <SEO withoutSiteTitle />
      <EmailVerificationBar />
      <PrivateHeader />
      <Container component="main" className={cx(classes.main, className)}>
        {children}
      </Container>
    </div>
  );
};

const useStyles = makeStyles<Partial<PrivateLayoutProps>>()((theme, props) => ({
  outer: {
    display: "flex",
    flexFlow: "column",
    flex: 1
  },
  main: {
    display: "flex",
    flexDirection: "column",
    ...(props.fullHeight && {
      flex: 1
    }),
    ...(props.disableGutters
      ? {
          padding: 0
        }
      : {
          paddingTop: theme.spacing(LAYOUT_PADDING),
          paddingBottom: theme.spacing(LAYOUT_PADDING)
        }),
    [theme.breakpoints.down("sm")]: {
      ...(props.disableGuttersMobile && {
        padding: 0
      }),
      ...(props.disableHorizontalGuttersMobile && {
        paddingLeft: 0,
        paddingRight: 0
      })
    }
  }
}));

export default PrivateLayout;
