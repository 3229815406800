import {
  alertError,
  alertInfo,
  alertSuccess,
  navigateOut,
  openNewTab
} from "@placehires/react-component-library";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import { useEffect } from "react";
import {
  useCreateBillingPortalSessionMutation,
  useProcessSuccessfulCheckoutSessionMutation
} from "../generated/graphqlHooks";
import { auth } from "../services/firebaseService";
import { useLoadingWrapper } from "./loadingHooks";
import { useQueryParams } from "./routeHooks";

export const useBillingPortalRedirect = () => {
  const [createBillingPortalSessionMutation] = useCreateBillingPortalSessionMutation();
  const [loadWrap, loading] = useLoadingWrapper();

  const handleBillingPortalRedirect = (newTab?: boolean) => {
    return loadWrap(async () => {
      const { data } = await createBillingPortalSessionMutation({
        variables: {
          returnUrl: window.location.href
        }
      });
      const redirect = newTab === true ? openNewTab : navigateOut;
      redirect(data.createBillingPortalSession);
    });
  };

  return [handleBillingPortalRedirect, loading] as const;
};

export const useProcessCheckout = () => {
  const location = useLocation();
  const query = useQueryParams();
  const sessionId = query.get("session_id");
  const [processSuccessfulCheckoutSession] = useProcessSuccessfulCheckoutSessionMutation();

  useEffect(() => {
    if (sessionId) {
      alertInfo("Creating your subscription");
      processSuccessfulCheckoutSession({
        variables: {
          sessionId
        }
      })
        .then(() => {
          alertSuccess("Subscription successfully created");
          // Force-refresh to make sure stripe claims are included
          auth.currentUser.getIdToken(true);
        })
        .catch((err) => {
          console.log(err);
          alertError("Failed to create subscription. Our team has been notified");
        })
        .finally(() => {
          // Remove query param
          query.delete("session_id");
          navigate(location.pathname + query, { replace: true });
        });
    }
  }, [sessionId]); // eslint-disable-line
};
