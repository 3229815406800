import { SelectOption } from "@placehires/react-component-library";
import { startCase } from "lodash";
import companyPlaceholder from "../assets/icons/companyPlaceholder.png";
import {
  ApplicationStatus,
  EducationDegree,
  EmploymentType,
  LanguageProficiencyType
} from "../generated/graphqlTypes";

const enumToSelectOptions = <T extends string | number>(
  enumObj: Record<string, T>,
  getLabel: (value: T) => string
) => {
  const options = [
    {
      value: ""
    }
  ] as SelectOption[];
  Object.values(enumObj).forEach((value) => {
    options.push({
      value,
      label: getLabel(value)
    });
  });
  return options;
};

export enum StatusCode {
  CONFLICT = 409,
  TOO_MANY_REQUESTS = 429
}

export const PUBLIC_LINKS = [
  {
    name: "Home",
    to: "/"
  },
  {
    name: "Our Story",
    to: "/our-story"
  },
  {
    name: "For Restaurants",
    to: "/restaurants"
  },
  {
    name: "For Applicants",
    to: "/applicants"
  },
  {
    name: "Blog",
    to: "/blog"
  }
  // {
  //   name: "Contact Us",
  //   to: "/contact-us"
  // }
  // { name: "Social", to: "/social" }
];

export const APPLICANT_ROLE = "applicant";

export const RECRUITER_ROLE = "recruiter";

export const PRIVATE_HOME = "/app";

export const COMPANY_SIZE_OPTIONS = [
  {
    value: "",
    label: ""
  },
  {
    value: 0,
    label: "1-10"
  },
  {
    value: 1,
    label: "11-50"
  },
  {
    value: 2,
    label: "51-200"
  },
  {
    value: 3,
    label: "201-500"
  },
  {
    value: 4,
    label: "500+"
  }
];

export const employmentTypeToReadable = (type: EmploymentType) =>
  startCase(type.toLowerCase()).replace(" Time", "-time");

export const EMPLOYMENT_TYPE_OPTIONS = enumToSelectOptions(
  EmploymentType,
  employmentTypeToReadable
);

export const languageLevelTypeToReadable = (type: LanguageProficiencyType) =>
  startCase(type.toLowerCase());

export const LANGUAGE_LEVEL_OPTIONS = enumToSelectOptions(
  LanguageProficiencyType,
  languageLevelTypeToReadable
);

export const EDUCATION_DEGREE_OPTIONS = [
  {
    value: "",
    label: ""
  },
  {
    value: EducationDegree.HIGH_SCHOOL,
    label: "High School Diploma"
  },
  {
    value: EducationDegree.POST_SECONDARY,
    label: "Post-Secondary Degree"
  }
];

const {
  CREATED,
  UNDER_REVIEW,
  SELECTED_FOR_INTERVIEW,
  INTERVIEWED,
  HIRED,
  NO_LONGER_UNDER_CONSIDERATION
} = ApplicationStatus;

export const RECRUITER_APPLICATION_STATUS_OPTIONS = [
  {
    value: UNDER_REVIEW,
    label: "Under Review"
  },
  {
    value: SELECTED_FOR_INTERVIEW,
    label: "Selected For Interview"
  },
  {
    value: INTERVIEWED,
    label: "Interviewed"
  },
  {
    value: HIRED,
    label: "Hired"
  },
  {
    value: NO_LONGER_UNDER_CONSIDERATION,
    label: "No Longer Under Consideration"
  }
];

export const APPLICANT_APPLICATION_STATUS_OPTIONS = [
  {
    value: CREATED,
    label: "Matched"
  },
  {
    value: UNDER_REVIEW,
    label: "Under review"
  },
  {
    value: SELECTED_FOR_INTERVIEW,
    label: "Selected For Interview"
  },
  {
    value: INTERVIEWED,
    label: "Interviewed"
  },
  {
    value: HIRED,
    label: "Hired"
  },
  {
    value: "noLongerUnderConsideration",
    label: "No Longer Under Consideration"
  }
];

export const getProfileImageURL = (uid: string) => `users/${uid}/profile-image.jpg`;

export const getCompanyAvatarURL = (url?: string | null) => url || companyPlaceholder;

export const signUpFormUrl = "https://forms.gle/aNMb2yrhC9TGDsVR6";
